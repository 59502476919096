import React from "react";
import { Graph } from "react-d3-graph";
import {
  Center,
  Text,
  VStack,
  Link,
  Spacer,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom"

import config from "./config";
import data from "./data";
import { useMediaQuery } from "react-responsive";


const PageFour = () => {

  const is2016x863 = useMediaQuery({
    minWidth: 2015.1,
  });
  const is1112x767 = useMediaQuery({
    maxWidth: 2015,
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });

  function init() {
    new SmoothScroll(document, 30, 12);
  }
  
  function SmoothScroll(target, speed, smooth) {
    if (target === document)
      target =
        document.scrollingElement ||
        document.documentElement ||
        document.body.parentNode ||
        document.body; // cross browser support for document scrolling

    var moving = false;
    var pos = target.scrollTop;
    var frame =
      target === document.body && document.documentElement
        ? document.documentElement
        : target; // safari is the new IE

    target.addEventListener("mousewheel", scrolled, { passive: false });
    target.addEventListener("DOMMouseScroll", scrolled, { passive: false });



    function scrolled(e) {
      e.preventDefault(); // disable default scrolling
  
      var delta = normalizeWheelDelta(e);
  
      pos += -delta * speed;
      pos = Math.max(0, Math.min(pos, target.scrollHeight - frame.clientHeight)); // limit scrolling
  
      if (!moving) update();
    }
  
    function normalizeWheelDelta(e) {
      if (e.detail) {
        if (e.wheelDelta)
          return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1); // Opera
        else return -e.detail / 3; // Firefox
      } else return e.wheelDelta / 120; // IE,Safari,Chrome
    }
  
    function update() {
      moving = true;
  
      var delta = (pos - target.scrollTop) / smooth;
  
      target.scrollTop += delta;
  
      if (Math.abs(delta) > 0.5) requestFrame(update);
      else moving = false;
    }
  
    var requestFrame = (function() {
      // requestAnimationFrame cross browser
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(func) {
          window.setTimeout(func, 1000 / 50);
        }
      );
    })();
  }

  document.body.onload = init();
  document.body.onscroll = function() {
    document.querySelector(".dotgrid").style.top =
      (
        55 -
        window.scrollY / document.documentElement.scrollHeight * 60
      ).toString() + "vh";
    document.querySelector(".dotgridbg").style.top =
      (
        55 -
        window.scrollY / document.documentElement.scrollHeight * 47
      ).toString() + "vh";
    document.querySelector(".dgcirc").style.top =
      (
        55 -
        window.scrollY / document.documentElement.scrollHeight * 30
      ).toString() + "vh";
    document.querySelector(".moveName").style.top =
      (
        5.5 -
        window.scrollY / document.documentElement.scrollHeight * 20
      ).toString() + "vh";
    document.querySelector(".moveName2").style.top =
      (
        10.3 -
        window.scrollY / document.documentElement.scrollHeight * 30
      ).toString() + "vh";
    
    document.querySelector(".arc").style.top =
      (
        28 -
        window.scrollY / document.documentElement.scrollHeight * 45
      ).toString() + "vh";
    document.querySelector(".arc").style.transform =
      "rotate(" + 
      Math.round(
        135 +
        window.scrollY / document.documentElement.scrollHeight * 500
      ).toString() + "deg)";
    document.querySelector(".triangle").style.top =
      (
        27 -
        window.scrollY / document.documentElement.scrollHeight * 55
      ).toString() + "vh";
    document.querySelector(".linebg").style.top =
      (
        35 -
        window.scrollY / document.documentElement.scrollHeight * 70
      ).toString() + "vh";
    document.querySelector(".btn").style.top =
      (
        15 -
        window.scrollY / document.documentElement.scrollHeight * 35
      ).toString() + "vh";
    document.querySelector(".linebg").style.backgroundPosition =
      "0 " + 
      (
        0 +
        window.scrollY / document.documentElement.scrollHeight * -50
      ).toString() + "%";
  };  


  return (
    <>
    {is2016x863 && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="5rem" fontSize="5xl" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="sm" w="50%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="lg">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead >
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="5rem" fontSize="5xl" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="lg" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}

{is1112x767 && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="5rem" fontSize="4xl" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="sm" w="50%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="lg">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead >
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="5rem" fontSize="4xl" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="lg" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}

{is800x600 && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="5rem" fontSize="3xl" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="sm" w="60%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="md">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead >
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="5rem" fontSize="3xl" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="lg" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}

{isIphone8Hor && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="2rem" fontSize="2xl" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="xs" w="60%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="md">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead >
                <Tr>
                  <Th >User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="2rem" fontSize="2xl" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="lg" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}


{isIphone8SmallHor && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="2rem" fontSize="xl" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="sm" w="68%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="md">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead >
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="2rem" fontSize="xl" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="lg" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="lg" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}

{isIphoneSEHor && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="2rem" fontSize="lg" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="xs" w="70%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="sm">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="sm" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="2rem" fontSize="lg" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="sm" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="sm" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}

{isIphone8Vert && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="2rem" fontSize="md" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="xs" w="100%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="xs">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User Names</Th>
                  <Th>Department</Th>
                  <Th>System Name</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="xs" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="2rem" fontSize="md" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="xs" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="xs" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}

{isIphone8SmallVert && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="2rem" fontSize="md" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="10px" w="100%">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="xs">List of users and their corresponding accesses to systems</Text>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Department</Th>
                  <Th>System</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>User</Th>
                  <Th>Department</Th>
                  <Th>System</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="xs" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="2rem" fontSize="md" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="xs" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="xs" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}

{isIphoneSEVert && (
    <VStack fontFamily="Helvetica Neue"  w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh"  w="100%">

          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: ".0s"}} className="blueeee">Network Analysis</span> {" "}
                    <span style={{animationDelay: ".1s"}}>helps us</span> {" "}
                    <span style={{animationDelay: ".2s"}}>to understand</span> {" "}
                    <span style={{animationDelay: ".3s"}}>the</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">structure</span> {" "}
                    <span style={{animationDelay: ".5s"}} className="blueeee">of a</span> {" "}
                    <span style={{animationDelay: ".6s"}} className="blueeee">relationship</span> {" "}
                    <span style={{animationDelay: ".7s"}}>in</span> {" "}
                    <span style={{animationDelay: ".8s"}}>networks</span>
                    <span style={{animationDelay: ".9s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
          <Text pb="2rem" fontSize="sm" w="70%" textAlign="center">The following table indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <TableContainer fontSize="8px" w="100vw">
            <Text textAlign="left" pb="1rem" pl="1.5rem" fontSize="10px">List of users and their corresponding accesses to systems</Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th fontSize="10px">User</Th>
                  <Th fontSize="10px">Department</Th>
                  <Th fontSize="10px">System</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Alexander</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Brooklyn</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Christopher</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Danny</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Emma</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Fernando</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Gabriel</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Hannah</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Isabella</Td>
                  <Td>Department X</Td>
                  <Td>System A</Td>
                </Tr>
                <Tr>
                  <Td>Jacob</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System C</Td>
                </Tr>
                <Tr>
                  <Td>Kaylee</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Liam</Td>
                  <Td>Department Y</Td>
                  <Td>System B & System D</Td>
                </Tr>
                <Tr>
                  <Td>Madison</Td>
                  <Td>Department y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Noah</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Olivia</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Pete</Td>
                  <Td>Department Y</Td>
                  <Td>System B</Td>
                </Tr>
                <Tr>
                  <Td>Quinn</Td>
                  <Td>Department Y</Td>
                  <Td>System A & System C</Td>
                </Tr>
                <Tr>
                  <Td>Ryan</Td>
                  <Td>Department Y</Td>
                  <Td>System A</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th fontSize="10px">User</Th>
                  <Th fontSize="10px">Department</Th>
                  <Th fontSize="10px">System</Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>


          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="10px" w="70%">While it might be easy to spot anomalous patterns in a small sample of data, it becomes non-trivial when dealing with complex relationships between users and their accesses to systems - imagine sifting through thousands of rows of data. This becomes exceptionally complex when taking into account of the change in access requirements due to movement of users between departments and roles, resignations of users, as well as new users, on a daily basis.</Text>


          <Text pt="10rem" pb="0rem" fontSize="sm" w="70%" textAlign="center">The following network diagram indicates the relationship between each employee of the company and their accesses to various systems within the company.</Text>

          <Text textAlign="left" pt="3rem" pl="1.5rem" fontSize="10px" w="70%">By visualizing the relationships between users and their corresponding accesses to systems, it becomes much easier to observe when anomalous patterns appear.</Text>
          <Text textAlign="left" pt="1rem" pl="1.5rem" fontSize="10px" w="70%">Algorithms of graph theory can also be applied to further understand the relationship between nodes. For example, centrality measures can be used to identify "important" nodes.</Text>

          <Graph id="graph" config={config} data={data} />
        </VStack>

    </VStack>
    )}
    </>
  );
};

export default PageFour;

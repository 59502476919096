import React from "react";
import {
  Center,
  Text,
  VStack,
  Link,
  Spacer,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom"
import { useMediaQuery } from "react-responsive";

import "./style.css"


const PageTwo = () => {
  const is2016x863 = useMediaQuery({
    minWidth: 2015.1,
  });
  const is1112x767 = useMediaQuery({
    maxWidth: 2015,
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });

  function init() {
    new SmoothScroll(document, 30, 12);
  }
  
  function SmoothScroll(target, speed, smooth) {
    if (target === document)
      target =
        document.scrollingElement ||
        document.documentElement ||
        document.body.parentNode ||
        document.body; // cross browser support for document scrolling

    var moving = false;
    var pos = target.scrollTop;
    var frame =
      target === document.body && document.documentElement
        ? document.documentElement
        : target; // safari is the new IE

    target.addEventListener("mousewheel", scrolled, { passive: false });
    target.addEventListener("DOMMouseScroll", scrolled, { passive: false });



    function scrolled(e) {
      e.preventDefault(); // disable default scrolling
  
      var delta = normalizeWheelDelta(e);
  
      pos += -delta * speed;
      pos = Math.max(0, Math.min(pos, target.scrollHeight - frame.clientHeight)); // limit scrolling
  
      if (!moving) update();
    }
  
    function normalizeWheelDelta(e) {
      if (e.detail) {
        if (e.wheelDelta)
          return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1); // Opera
        else return -e.detail / 3; // Firefox
      } else return e.wheelDelta / 120; // IE,Safari,Chrome
    }
  
    function update() {
      moving = true;
  
      var delta = (pos - target.scrollTop) / smooth;
  
      target.scrollTop += delta;
  
      if (Math.abs(delta) > 0.5) requestFrame(update);
      else moving = false;
    }
  
    var requestFrame = (function() {
      // requestAnimationFrame cross browser
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(func) {
          window.setTimeout(func, 1000 / 50);
        }
      );
    })();
  }

  document.body.onload = init();
  document.body.onscroll = function() {
    document.querySelector(".dotgrid").style.top =
      (
        55 -
        window.scrollY / document.documentElement.scrollHeight * 60
      ).toString() + "vh";
    document.querySelector(".dotgridbg").style.top =
      (
        55 -
        window.scrollY / document.documentElement.scrollHeight * 47
      ).toString() + "vh";
    document.querySelector(".dgcirc").style.top =
      (
        55 -
        window.scrollY / document.documentElement.scrollHeight * 30
      ).toString() + "vh";
    
    document.querySelector(".arc").style.top =
      (
        28 -
        window.scrollY / document.documentElement.scrollHeight * 45
      ).toString() + "vh";
    document.querySelector(".arc").style.transform =
      "rotate(" + 
      Math.round(
        135 +
        window.scrollY / document.documentElement.scrollHeight * 500
      ).toString() + "deg)";
    document.querySelector(".triangle").style.top =
      (
        27 -
        window.scrollY / document.documentElement.scrollHeight * 55
      ).toString() + "vh";
    document.querySelector(".linebg").style.top =
      (
        35 -
        window.scrollY / document.documentElement.scrollHeight * 70
      ).toString() + "vh";
    document.querySelector(".btn").style.top =
      (
        15 -
        window.scrollY / document.documentElement.scrollHeight * 35
      ).toString() + "vh";
    document.querySelector(".linebg").style.backgroundPosition =
      "0 " + 
      (
        0 +
        window.scrollY / document.documentElement.scrollHeight * -50
      ).toString() + "%";
  };  


  return (
    <>
    {is2016x863 && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="300vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo">
            <Text></Text>
            <div className="typing-demo">
              Automate your audit analytical system so you can focus on what matters most - investigating anomalies and evaluating risks.
            </div>
            <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date">Automated Data Extraction and Processing</div>
                        <div className="descr">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   



        </VStack>

    </VStack>
    )}

{is1112x767 && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="300vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo">
            <Text></Text>
            <Text mb="-12rem" fontFamily="monospace" fontSize="1.2rem">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo2">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date">Automated Data Extraction and Processing</div>
                        <div className="descr">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}

{is800x600 && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="420vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo">
            <Text></Text>
            <Text mb="-12rem" fontFamily="monospace" fontSize="1.2rem">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo2">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date2">Automated Data Extraction and Processing</div>
                        <div className="descr">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date2">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date2">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date2">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}

{isIphone8Hor && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="620vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo">
            <Text></Text>
            <Text mb="-7rem" fontFamily="monospace" fontSize="1rem">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo3">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date2">Automated Data Extraction and Processing</div>
                        <div className="descr">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date2">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date2">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date2">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}

{isIphone8SmallHor && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="620vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo">
            <Text></Text>
            <Text mb="-7rem" fontFamily="monospace" fontSize="1rem">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo3">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date2">Automated Data Extraction and Processing</div>
                        <div className="descr2">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date2">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr2">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date2">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr2">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date2">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr2">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}

{isIphoneSEHor && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="620vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo">
            <Text></Text>
            <Text mb="-5rem" fontFamily="monospace" fontSize="0.8rem">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo4">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date2">Automated Data Extraction and Processing</div>
                        <div className="descr3">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date2">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr3">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date2">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr3">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date2">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr3">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}

{isIphone8Vert && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="270vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo2">
            <Text></Text>
            <Text mb="-12rem" fontFamily="monospace" fontSize="0.8rem" textAlign="center">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo4">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text mt="-2rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date3">Automated Data Extraction and Processing</div>
                        <div className="descr4">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date3">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr4">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date3">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr4">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date3">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr4">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}

{isIphone8SmallVert && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="290vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo2">
            <Text></Text>
            <Text mb="-12rem" fontFamily="monospace" fontSize="0.7rem" textAlign="center">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo5">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text mt="-2rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      <span style={{animationDelay: ".4s"}}>.</span>
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date3">Automated Data Extraction and Processing</div>
                        <div className="descr4">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date3">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr4">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date3">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr4">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date3">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr4">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}
    
    {isIphoneSEVert && (
    <VStack fontFamily="Helvetica Neue" w="100vw" border="solid 1px" h="350vh" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack position="relative" top="8vh">
          <div className="wrapperDataVizOnePageTwo2">
            <Text></Text>
            <Text mb="-9rem" fontFamily="monospace" fontSize="0.7rem" textAlign="center">Automate your audit analytical system so you can focus on what matters most - </Text>
            <div className="typing-demo5">
              investigating anomalies and evaluating risks.
            </div>
           
            <Text mt="-2rem" className="mainarrow"><i className="down"></i></Text>
            <Text></Text>
          </div>

            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>

            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead3">
                <VStack pb="6rem" h="180vh">
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "2vw",
                      fontWeight: "900",
                    }}
                  >
                      <span style={{animationDelay: "0s"}}>Build an</span> {" "}
                      <span style={{animationDelay: ".1s"}} className="blueeee">automated analytical system</span> {" "}
                      <span style={{animationDelay: ".2s"}}>to achieve</span> {" "}
                      <span style={{animationDelay: ".3s"}} className="blueeee">the desired outcomes</span>
                      {/* <span style={{animationDelay: ".4s"}}>.</span> */}
                  </h1>
                  <ul>
                    <li className="listtwo">
                        <div className="date4">Automated Data Extraction and Processing</div>
                        <div className="descr4">Implement a periodic extraction of data using data query languages such as SQL, and a pre-defined script written in R or Python for processing the extracted data into a clean format prior to conducting analysis.</div>
                    </li>
                    <li className="listthree">
                        <div className="date4">Pre-defined Scripts for Data Analyses</div>
                        <div className="descr4">Design and develop R or Python scripts based on the pre-defined test cases that can be run on a periodic basis for continuous identification of risks.</div>
                    </li>
                    <li className="listfour">
                        <div className="date4">Reporting of Analyses on Visualization Dashboard</div>
                        <div className="descr4">Data from the analyses conducted by the system will be fed through a pipeline for further processing (if required), prior to its visualization on a dashboard built using business intelligence tools such as Tableau, or customized using open-sourced languages such as Python / R.</div>
                    </li>
                    <li className="listfive">
                        <div className="date4">Automated Feedback Loop for Audit Follow Up</div>
                        <div className="descr4">Any anomalies churned out by the analytical system will be reflected on the dashboard for the business process owners to take action accordingly. Upon rectifying the issue, a feedback loop will be sent back to the auditors via the same analytical system for auditors to review and to close the issue.</div>
                    </li>
                  </ul>
                </VStack>
              </div>
            </div>

            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>   
        </VStack>
    </VStack>
    )}
</>
  );
};

export default PageTwo;

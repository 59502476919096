import React, { ReactNode } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  VStack,
  HStack,
  Avatar,
  Spacer,
  Divider,
  Center,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
} from "react-icons/fi";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { ColorModeSwitcher } from "../../constants/ColorModeSwitcher";

interface LinkItemProps {
  name: string;
  icon: IconType;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome },
  { name: "Trending", icon: FiTrendingUp },
  { name: "Explore", icon: FiCompass },
  { name: "Favourites", icon: FiStar },
  { name: "Settings", icon: FiSettings },
];

interface SettingsLinkItemProps {
  name: string;
}
const SettingsLinkItems: Array<SettingsLinkItemProps> = [
  { name: "Dark/Light Mode" },
];

export default function SimpleSidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" minW="100vw">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("#fbfbfa", "#202020")}
      borderRight="1px"
      borderRightColor={useColorModeValue("#f5f5f4", "#2b2b2b")}
      w={{ base: "full", md: "16em" }}
      pos="fixed"
      h="full"
      {...rest}
      overflow="scroll"
      pb="3em"
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        mt="1em"
        mb="1em"
      >
        <HStack w="100%" alignItems="center">
          <Avatar name="Dan Abrahmov" bg="#ab8353" />
          <Spacer />
          <VStack w="100%" alignItems="left">
            <Text textAlign="left" fontSize="sm" fontFamily="Helvetica Neue">
              Good Day 👋
            </Text>
            <Text
              textAlign="left"
              fontSize="sm"
              fontFamily="Helvetica Neue"
              fontWeight="bold"
              noOfLines={1}
              // overflowWrap="break-word"
              // textOverflow="clip"
            >
              Dan Abrahmov
            </Text>
          </VStack>
        </HStack>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>

      <Divider mb="2em" />

      <Center>
        <VStack alignItems="left" w="80%">
          <Text textAlign="left">Menu</Text>
        </VStack>
      </Center>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}

      <Divider mt="2em" mb="2em" />

      <Center>
        <VStack alignItems="left" w="80%">
          <Text textAlign="left">Settings</Text>
        </VStack>
      </Center>
      {SettingsLinkItems.map((link) => (
        <SettingsNavItem key={link.name}>{link.name}</SettingsNavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Link
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Box
        // border="solid"
        align="left"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Box>
    </Link>
  );
};

interface SettingsNavItemProps extends FlexProps {
  children: ReactText;
}
const SettingsNavItem = ({ children, ...rest }: SettingsNavItemProps) => {
  return (
    <Link
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Box
        align="left"
        pt="4"
        pb="4"
        pr="4"
        pl="0"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        <ColorModeSwitcher />
        {children}
      </Box>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Box
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("#fbfbfa", "#202020")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("#f5f5f4", "#2b2b2b")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text>
    </Box>
  );
};

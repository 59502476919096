module.exports = {
    "automaticRearrangeAfterDropNode":true,
    "collapsible":true,
    "directed":true,
    "focusAnimationDuration":0.75,
    "focusZoom":1,
    "freezeAllDragEvents":false,
    "height":700,
    "highlightDegree":2,
    "highlightOpacity":0.2,
    "linkHighlightBehavior":true,
    "maxZoom":12,
    "minZoom":0.05,
    "initialZoom":null,
    "nodeHighlightBehavior":true,
    "panAndZoom":false,
    "staticGraph":false,
    "staticGraphWithDragAndDrop":false,
    "width":800,
    "d3":{
        "alphaTarget":0.05,
        "gravity":-250,
        "linkLength":120,
        "linkStrength":2,
        "disableLinkForce":false
    },
    "node":{
        "color":"#d3d3d3",
        "fontColor":"black",
        "fontSize":10,
        "fontWeight":"normal",
        "highlightColor":"red",
        "highlightFontSize":14,
        "highlightFontWeight":"bold",
        "highlightStrokeColor":"red",
        "highlightStrokeWidth":1.5,
        "labelPosition":"",
        "mouseCursor":"crosshair",
        "opacity":1,
        "renderLabel":true,
        "size":500,
        "strokeColor":"none",
        "strokeWidth":1.5,
        "svg":"",
        "symbolType":"circle",
        "viewGenerator":null
    },
    "link":{
        "color":"lightgray",
        "fontColor":"black",
        "fontSize":8,
        "fontWeight":"normal",
        "highlightColor":"red",
        "highlightFontSize":8,
        "highlightFontWeight":"normal",
        "labelProperty":"label",
        "mouseCursor":"pointer",
        "opacity":0.5,
        "renderLabel":false,
        "semanticStrokeWidth":true,
        "strokeWidth":3,
        "markerHeight":6,
        "markerWidth":6,
        "type":"STRAIGHT",
        "selfLinkDirection":"TOP_RIGHT",
        "strokeDasharray":0,
        "strokeDashoffset":0,
        "strokeLinecap":"butt"
    }
}
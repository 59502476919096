import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const fonts = {
  heading: `"Crimson Text", serif`,
  body: `"Crimson Text", serif`,
};

const colors = {
  primary: {
    100: "#1b3968",
    200: "#95aece ",
    300: "#5b5aed",
  },
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const styles = {
  global: (props: any) => ({
    body: {
      color: mode("gray.800", "#cacaca")(props),
      bg: mode("#ffffff", "#191919")(props),
    },
  }),
};

const components = {
  Drawer: {
    // setup light/dark mode component defaults
    baseStyle: (props: any) => ({
      dialog: {
        bg: mode("white", "#141214")(props),
      },
    }),
  },
};

const theme = extendTheme({ fonts, colors, config, styles, components });

export default theme;

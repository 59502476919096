import React from "react";
import { Graph } from "react-d3-graph";
import {
  Center,
  Text,
  VStack,
  Box,
  HStack,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from "@chakra-ui/react";
import { FiUsers, FiDatabase, FiUserPlus } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";


import LineChart from "./components/LineChart"
import BarChart from "./components/BarChart"
import UsersNav from "./components/UsersNav"
import HeatMap from "./components/HeatMap"

import data from "./components/data";

import useWindowDimensions from "./hook/useWindowDimensions";



const PageThree = () => {

  const is2016x863 = useMediaQuery({
    minWidth: 2015.1,
  });
  const is1112x767 = useMediaQuery({
    maxWidth: 2015,
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });

  const { height, width } = useWindowDimensions();

  const config = {
    "automaticRearrangeAfterDropNode":true,
    "collapsible":true,
    "directed":true,
    "focusAnimationDuration":0.75,
    "focusZoom":1,
    "freezeAllDragEvents":false,
    "height":height*0.7,
    "highlightDegree":2,
    "highlightOpacity":0.2,
    "linkHighlightBehavior":true,
    "maxZoom":12,
    "minZoom":0.05,
    "initialZoom":null,
    "nodeHighlightBehavior":true,
    "panAndZoom":true,
    "staticGraph":false,
    "staticGraphWithDragAndDrop":false,
    "width":width*0.5,
    "d3":{
        "alphaTarget":0.05,
        "gravity":-250,
        "linkLength":120,
        "linkStrength":2,
        "disableLinkForce":false
    },
    "node":{
        "color":"#d3d3d3",
        "fontColor":"black",
        // "fontFamily": "helvetica neue",
        "fontSize":width*0.01,
        "fontWeight":"normal",
        "highlightColor":"red",
        "highlightFontSize":14,
        "highlightFontWeight":"bold",
        "highlightStrokeColor":"red",
        "highlightStrokeWidth":1.5,
        "labelPosition":"",
        "mouseCursor":"crosshair",
        "opacity":1,
        "renderLabel":true,
        "size":500,
        "strokeColor":"none",
        "strokeWidth":1.5,
        "svg":"",
        "symbolType":"circle",
        "viewGenerator":null
    },
    "link":{
        "color":"lightgray",
        "fontColor":"black",
        "fontSize":8,
        "fontWeight":"normal",
        "highlightColor":"red",
        "highlightFontSize":8,
        "highlightFontWeight":"normal",
        "labelProperty":"label",
        "mouseCursor":"pointer",
        "opacity":0.5,
        "renderLabel":false,
        "semanticStrokeWidth":true,
        "strokeWidth":3,
        "markerHeight":6,
        "markerWidth":6,
        "type":"STRAIGHT",
        "selfLinkDirection":"TOP_RIGHT",
        "strokeDasharray":0,
        "strokeDashoffset":0,
        "strokeLinecap":"butt"
    }
}

  return (
    <>
    {is2016x863 && (
    <UsersNav >
      <VStack fontFamily="Helvetica Neue"  w="85vw">


        <VStack position="relative" top="0vh">
          <HStack>

            <VStack w="80vw" p="1rem" borderRadius="10" textAlign="center" >

              <StatGroup w="100%">
                <HStack w="100%">
                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiDatabase color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel>Number of Systems</StatLabel>
                    <StatNumber>4</StatNumber>
                    <StatHelpText color="green">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="green">25.00%</Text>
                          <Text>from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUsers color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel>Number of Users</StatLabel>
                    <StatNumber>11</StatNumber>
                    <StatHelpText color="red">
                     <Center>
                        <HStack>
                          <StatArrow type='decrease' />
                          <Text color="red">15.38%</Text>
                          <Text>from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>

                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUserPlus color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel>Number of Privilege Users</StatLabel>
                    <StatNumber>2</StatNumber>
                    <StatHelpText color="black">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="black">0.00%</Text>
                          <Text>from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                </HStack>

              </StatGroup>

              <Center w="100%" >
                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="xl" ml="2rem">Timely Addition / Deletion of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="sm" ml="2rem" color="red">(+3) findings</Text> 
                    <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                  </HStack>
                  <LineChart />
                </Box>

                <Spacer />

                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="xl" ml="2rem">Appropriateness of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="sm" ml="2rem" color="green">(-4) findings </Text> 
                    <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                  </HStack>
                  <BarChart />
                </Box>
              </Center>

              <VStack w="100%" p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                <Text textAlign="left" w="100%" fontSize="xl" pl="2rem">User Access Network Visualization</Text>
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">4 System Nodes</Text> 
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">11 Personnel Nodes</Text> 
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">2 Departments</Text> 

               <Graph id="graph" config={config} data={data} />
              </VStack>

              <VStack w="100%" p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                <Text textAlign="left" w="100%" fontSize="xl" pl="2rem">User Access Heatmap</Text>

                <HeatMap />
              </VStack>

            </VStack>
            <Spacer />
          </HStack>
        </VStack>

    </VStack>
    </UsersNav>
    )}

{is1112x767 && (
    <UsersNav >
      <VStack fontFamily="Helvetica Neue"  w="75vw">


        <VStack position="relative" top="0vh" >
          <HStack>

            <VStack w="75vw" p="1rem" borderRadius="10" textAlign="center" >

              <StatGroup w="100%" >
                <HStack w="100%">
                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiDatabase color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel fontSize="xs">Number of Systems</StatLabel>
                    <StatNumber fontSize="sm">4</StatNumber>
                    <StatHelpText>
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="green" fontSize="xs">25.00%</Text>
                          <Text fontSize="xs">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUsers color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel fontSize="xs">Number of Users</StatLabel>
                    <StatNumber fontSize="sm">11</StatNumber>
                    <StatHelpText>
                     <Center>
                        <HStack>
                          <StatArrow type='decrease' />
                          <Text color="red" fontSize="xs">15.38%</Text>
                          <Text fontSize="xs">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>

                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUserPlus color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel fontSize="xs">Number of Privilege Users</StatLabel>
                    <StatNumber fontSize="sm">2</StatNumber>
                    <StatHelpText color="black">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="black" fontSize="xs">0.00%</Text>
                          <Text fontSize="xs">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                </HStack>

              </StatGroup>

              <Center w="100%">
                <VStack w="100%">
                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="xl" ml="2rem">Timely Addition / Deletion of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="sm" ml="2rem" color="red">(+3) findings</Text> 
                    <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                  </HStack>
                  <LineChart />
                </Box>

                <Spacer />

                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="xl" ml="2rem">Appropriateness of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="sm" ml="2rem" color="green">(-4) findings </Text> 
                    <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                  </HStack>
                  <BarChart />
                </Box>
                </VStack>
              </Center>

              <VStack w="100%" p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                <Text textAlign="left" w="100%" fontSize="xl" pl="2rem">User Access Network Visualization</Text>
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">4 System Nodes</Text> 
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">11 Personnel Nodes</Text> 
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">2 Departments</Text> 

               <Graph id="graph" config={config} data={data} />
              </VStack>

              <VStack w="100%" p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                <Text textAlign="left" w="100%" fontSize="xl" pl="2rem">User Access Heatmap</Text>
                <HeatMap />
              </VStack>

            </VStack>
            <Spacer />
          </HStack>
        </VStack>

    </VStack>
    </UsersNav>
    )}

{is800x600 && (
    <UsersNav >
      <VStack fontFamily="Helvetica Neue"  w="60vw">


        <VStack position="relative" top="0vh" >
          <HStack>

            <VStack w="75vw" p="1rem" borderRadius="10" textAlign="center" >

              <StatGroup w="100%" >
                <HStack w="100%">
                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiDatabase color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel fontSize="xs">Number of Systems</StatLabel>
                    <StatNumber fontSize="sm">4</StatNumber>
                    <StatHelpText>
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="green" fontSize="xs">25.00%</Text>
                          <Text fontSize="xs">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUsers color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel fontSize="xs">Number of Users</StatLabel>
                    <StatNumber fontSize="sm">11</StatNumber>
                    <StatHelpText>
                     <Center>
                        <HStack>
                          <StatArrow type='decrease' />
                          <Text color="red" fontSize="xs">15.38%</Text>
                          <Text fontSize="xs">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>

                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUserPlus color="#355993" fontSize="2rem"/>
                    </Center>
                    <StatLabel fontSize="xs">Number of Privilege Users</StatLabel>
                    <StatNumber fontSize="sm">2</StatNumber>
                    <StatHelpText color="black">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="black" fontSize="xs">0.00%</Text>
                          <Text fontSize="xs">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                </HStack>

              </StatGroup>

              <Center w="100%">
                <VStack w="100%">
                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="xl" ml="2rem">Timely Addition / Deletion of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="sm" ml="2rem" color="red">(+3) findings</Text> 
                    <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                  </HStack>
                  <LineChart />
                </Box>

                <Spacer />

                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="xl" ml="2rem">Appropriateness of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="sm" ml="2rem" color="green">(-4) findings </Text> 
                    <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                  </HStack>
                  <BarChart />
                </Box>
                </VStack>
              </Center>

              <VStack w="100%" p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                <Text textAlign="left" w="100%" fontSize="xl" pl="2rem">User Access Network Visualization</Text>
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">4 System Nodes</Text> 
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">11 Personnel Nodes</Text> 
                <Text textAlign="left" w="100%" fontSize="sm" pl="2rem">2 Departments</Text> 

               <Graph id="graph" config={config} data={data} />
              </VStack>

              <VStack w="100%" p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                <Text textAlign="left" w="100%" fontSize="xl" pl="2rem">User Access Heatmap</Text>
                <HeatMap />
              </VStack>

            </VStack>
            <Spacer />
          </HStack>
        </VStack>

    </VStack>
    </UsersNav>
    )}

{isIphone8Hor && (
    <UsersNav >
      <VStack fontFamily="Helvetica Neue"  w="85vw" >


        <VStack position="relative" top="0vh" >
          <HStack w="100%">

            <VStack w="32vw" p="1rem" borderRadius="10" textAlign="center" >

              <StatGroup w="100%" >
                <HStack w="100%">
                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiDatabase color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Systems</StatLabel>
                    <StatNumber fontSize="xs">4</StatNumber>
                    <StatHelpText>
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="green" fontSize="10px">25.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUsers color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Users</StatLabel>
                    <StatNumber fontSize="xs">11</StatNumber>
                    <StatHelpText>
                     <Center>
                        <HStack>
                          <StatArrow type='decrease' />
                          <Text color="red" fontSize="10px">15.38%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>

                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUserPlus color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Privilege Users</StatLabel>
                    <StatNumber fontSize="xs">2</StatNumber>
                    <StatHelpText color="black">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="black" fontSize="10px">0.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                </HStack>

              </StatGroup>

              <Center w="100%">
                <VStack w="100%">
                  <HStack w="100%"> 
                  <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md" >
                    <Text textAlign="left" fontSize="md" ml="2rem">Timely Addition / Deletion of Access Overview in 2022</Text>
                    <HStack>
                      <Text textAlign="left" fontSize="sm" ml="2rem" color="red">(+3) findings</Text> 
                      <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                      <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                    </HStack>
                    <LineChart />
                  </Box>
                  </HStack>

                <Spacer />

                <HStack w="100%"> 

                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="md" ml="2rem">Appropriateness of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="sm" ml="2rem" color="green">(-4) findings </Text> 
                    <Text textAlign="left" fontSize="sm">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="sm">as compared to the previous month</Text> 
                  </HStack>
                  <BarChart />
                </Box>
                </HStack>
                </VStack>
              </Center>

              <HStack w="100%">
              <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">

                <Text textAlign="left" w="100%" fontSize="md" pl="2rem">User Access Heatmap</Text>
                <HeatMap />
              </Box>
              </HStack>

            </VStack>
            <Spacer />
          </HStack>
        </VStack>

    </VStack>
    </UsersNav>
    )}

{isIphone8SmallHor && (
    <UsersNav >
      <VStack fontFamily="Helvetica Neue"  w="85vw" >


        <VStack position="relative" top="0vh" >
          <HStack w="100%">

            <VStack w="32vw" p="1rem" borderRadius="10" textAlign="center" >

              <StatGroup w="100%" >
                <HStack w="100%">
                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiDatabase color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Systems</StatLabel>
                    <StatNumber fontSize="xs">4</StatNumber>
                    <StatHelpText>
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="green" fontSize="10px">25.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUsers color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Users</StatLabel>
                    <StatNumber fontSize="xs">11</StatNumber>
                    <StatHelpText>
                     <Center>
                        <HStack>
                          <StatArrow type='decrease' />
                          <Text color="red" fontSize="10px">15.38%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>

                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUserPlus color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Privilege Users</StatLabel>
                    <StatNumber fontSize="xs">2</StatNumber>
                    <StatHelpText color="black">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="black" fontSize="10px">0.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                </HStack>

              </StatGroup>

              <Center w="100%">
                <VStack w="100%">
                  <HStack w="100%"> 
                  <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md" >
                    <Text textAlign="left" fontSize="sm" ml="2rem">Timely Addition / Deletion of Access Overview in 2022</Text>
                    <HStack>
                      <Text textAlign="left" fontSize="xs" ml="2rem" color="red">(+3) findings</Text> 
                      <Text textAlign="left" fontSize="xs">in Dec 2022</Text> 
                      <Text textAlign="left" fontSize="xs">as compared to the previous month</Text> 
                    </HStack>
                    <LineChart />
                  </Box>
                  </HStack>

                <Spacer />

                <HStack w="100%"> 

                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="sm" ml="2rem">Appropriateness of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="xs" ml="2rem" color="green">(-4) findings </Text> 
                    <Text textAlign="left" fontSize="xs">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="xs">as compared to the previous month</Text> 
                  </HStack>
                  <BarChart />
                </Box>
                </HStack>
                </VStack>
              </Center>

              <HStack w="100%">
              <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">

                <Text textAlign="left" w="100%" fontSize="md" pl="2rem">User Access Heatmap</Text>
                <HeatMap />
              </Box>
              </HStack>

            </VStack>
            <Spacer />
          </HStack>
        </VStack>

    </VStack>
    </UsersNav>
    )}

{isIphoneSEHor && (
    <UsersNav >
      <VStack fontFamily="Helvetica Neue"  w="60vw" >


        <VStack position="relative" top="0vh" >
          <HStack w="100%">

            <VStack w="10vw" p="1rem" borderRadius="10" textAlign="center" >

              <StatGroup w="100%" >
                <HStack w="100%">
                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiDatabase color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Systems</StatLabel>
                    <StatNumber fontSize="xs">4</StatNumber>
                    <StatHelpText>
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="green" fontSize="10px">25.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUsers color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Users</StatLabel>
                    <StatNumber fontSize="xs">11</StatNumber>
                    <StatHelpText>
                     <Center>
                        <HStack>
                          <StatArrow type='decrease' />
                          <Text color="red" fontSize="10px">15.38%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>

                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUserPlus color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Privilege Users</StatLabel>
                    <StatNumber fontSize="xs">2</StatNumber>
                    <StatHelpText color="black">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="black" fontSize="10px">0.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                </HStack>

              </StatGroup>

              <Center w="100%">
                <VStack w="100%">
                  <HStack w="100%"> 
                  <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md" >
                    <Text textAlign="left" fontSize="sm" ml="2rem">Timely Addition / Deletion of Access Overview in 2022</Text>
                    <HStack>
                      <Text textAlign="left" fontSize="xs" ml="2rem" color="red">(+3) findings</Text> 
                      <Text textAlign="left" fontSize="xs">in Dec 2022</Text> 
                      <Text textAlign="left" fontSize="xs">as compared to the previous month</Text> 
                    </HStack>
                    <LineChart />
                  </Box>
                  </HStack>

                <Spacer />

                <HStack w="100%"> 

                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="sm" ml="2rem">Appropriateness of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="xs" ml="2rem" color="green">(-4) findings </Text> 
                    <Text textAlign="left" fontSize="xs">in Dec 2022</Text> 
                    <Text textAlign="left" fontSize="xs">as compared to the previous month</Text> 
                  </HStack>
                  <BarChart />
                </Box>
                </HStack>
                </VStack>
              </Center>

              <HStack w="100%">
              <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">

                <Text textAlign="left" w="100%" fontSize="md" pl="2rem">User Access Heatmap</Text>
                <HeatMap />
              </Box>
              </HStack>

            </VStack>
            <Spacer />
          </HStack>
        </VStack>

    </VStack>
    </UsersNav>
    )}

{(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
    <UsersNav >
      <HStack>
        <Spacer />
      <VStack fontFamily="Helvetica Neue"  w="50vw" >


        {/* <VStack position="relative" top="0vh" > */}
          {/* <HStack w="100%"> */}

            <VStack w="100%" p="1rem" borderRadius="10" textAlign="center" >

              <StatGroup w="100%" >
                <VStack w="100%">
                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiDatabase color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Systems</StatLabel>
                    <StatNumber fontSize="xs">4</StatNumber>
                    <StatHelpText>
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="green" fontSize="10px">25.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUsers color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Users</StatLabel>
                    <StatNumber fontSize="xs">11</StatNumber>
                    <StatHelpText>
                     <Center>
                        <HStack>
                          <StatArrow type='decrease' />
                          <Text color="red" fontSize="10px">15.38%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>

                  </Stat>

                  <Stat borderRadius="20" p="1rem" bgColor="white" boxShadow="md">
                    <Center mb="1rem">
                      <FiUserPlus color="#355993" fontSize="1rem"/>
                    </Center>
                    <StatLabel fontSize="10px"># of Privilege Users</StatLabel>
                    <StatNumber fontSize="xs">2</StatNumber>
                    <StatHelpText color="black">
                      <Center>
                        <HStack>
                          <StatArrow type='increase' />
                          <Text color="black" fontSize="10px">0.00%</Text>
                          <Text fontSize="10px">from last month</Text>
                        </HStack>
                      </Center>
                    </StatHelpText>
                  </Stat>

                </VStack>

              </StatGroup>

              <Center w="100%">
                <VStack w="100%">
                  <HStack w="100%"> 
                  <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md" >
                    <Text textAlign="left" fontSize="10px" ml="2rem">Timely Addition / Deletion of Access Overview in 2022</Text>
                    <HStack>
                      <Text textAlign="left" fontSize="10px" ml="2rem" color="red">(+3) findings in Dec 2022</Text> 
                    </HStack>
                    <LineChart />
                  </Box>
                  </HStack>

                <Spacer />

                <HStack w="100%"> 

                <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">
                  <Text textAlign="left" fontSize="10px" ml="2rem">Appropriateness of Access Overview in 2022</Text>
                  <HStack>
                    <Text textAlign="left" fontSize="10px" ml="2rem" color="green">(-4) findings in Dec 2022</Text> 
                  </HStack>
                  <BarChart />
                </Box>
                </HStack>
                </VStack>
              </Center>

              <HStack w="100%">
              <Box p='1rem' borderRadius="20" bgColor="white" boxShadow="md">

                <Text textAlign="left" w="100%" fontSize="10px" pl="2rem">User Access Heatmap</Text>
                <HeatMap />
              </Box>
              </HStack>

            </VStack>
            <Spacer />
          {/* </HStack> */}
        {/* </VStack> */}

    </VStack>
    </HStack>
    </UsersNav>
    )}
    </>
  );
};

export default PageThree;

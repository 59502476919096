import React, { Component } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "../hook/useWindowDimensions";
import { useMediaQuery } from "react-responsive";


const LineChart = () => {
    const is2016x863 = useMediaQuery({
        minWidth: 2015.1,
      });
      const is1112x767 = useMediaQuery({
        maxWidth: 2015,
        minWidth: 1111,
      });
    
      const is1024x768 = useMediaQuery({
        maxWidth: 1111,
        minWidth: 1023,
      });
    
      const is800x600 = useMediaQuery({
        maxWidth: 1023,
        minWidth: 799,
      });
    
      const isIphone8Hor = useMediaQuery({
        maxWidth: 799,
        minWidth: 735,
      });
    
      const isIphone8SmallHor = useMediaQuery({
        maxWidth: 735,
        minWidth: 666,
      });
    
      const isIphoneSEHor = useMediaQuery({
        maxWidth: 666,
        minWidth: 567,
      });
    
      const isIphone8Vert = useMediaQuery({
        maxWidth: 567,
        minWidth: 413,
      });
    
      const isIphone8SmallVert = useMediaQuery({
        maxWidth: 413,
        minWidth: 374,
      });
    
      const isIphoneSEVert = useMediaQuery({
        maxWidth: 374,
        minWidth: 319,
      });

    const state = {
        options: {
            chart: {
                id: "basic-bar"
            },
            dataLabels: {
                enabled: true
            },
            xaxis: {
                categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            }
            },
            series: [
            {
                name: "Resignees' Accesses Not Revoked",
                data: [0, 2, 3, 1, 0, 0, 0, 2, 1, 0, 0, 2]
            },
            {
                name: "Department Transferees' Accesses Not Revoked",
                data: [1, 0, 1, 0, 2, 0, 1, 0, 2, 0, 0, 1]
            }
            ]
    };

    const state2 = {
        options: {
            chart: {
                id: "basic-bar"
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            }
            },
            series: [
            {
                name: "Resignees' Accesses Not Revoked",
                data: [0, 2, 3, 1, 0, 0, 0, 2, 1, 0, 0, 2]
            },
            {
                name: "Department Transferees' Accesses Not Revoked",
                data: [1, 0, 1, 0, 2, 0, 1, 0, 2, 0, 0, 1]
            }
            ]
    };

    const { height, width } = useWindowDimensions();
    
    return (
        <>
        {is2016x863 && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="line"
                stacked="true"
                width={width*0.365}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}
              {is1112x767 && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="line"
                stacked="true"
                width={width*0.695}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}
      {is800x600 && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="line"
                stacked="true"
                width={width*0.695}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}
      {isIphone8Hor && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="line"
                stacked="true"
                width={width*0.6}
                height={height*0.5}            
            />
          </div>
        </div>
      </div>
      )}

{isIphone8SmallHor && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="line"
                stacked="true"
                width={width*0.6}
                height={height*0.5}            
            />
          </div>
        </div>
      </div>
      )}

{isIphoneSEHor && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="line"
                stacked="true"
                width={width*0.6}
                height={height*0.5}            
            />
          </div>
        </div>
      </div>
      )}

{(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state2.options}
                series={state2.series}
                type="line"
                stacked="true"
                width={width*0.4}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}
      </>
    );
  }


export default LineChart;
import * as React from "react";
import { Suspense } from 'react'

import * as THREE from "three";
import { GiCampfire } from "react-icons/gi";
import {
  Box,
  Text,
  Link,
  VStack,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Spacer,
  HStack,
} from "@chakra-ui/react";
import UsersNav from "../../components/UsersNav/UsersNav";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, Bounds, BakeShadows, useProgress, Html, useBounds } from "@react-three/drei";
import { Camping } from "./Camping4";
import { useMediaQuery } from "react-responsive";

import "./home.css"


const Scene = () => {

  // const isBigScreen = useMediaQuery({ minWidth: 1824 });

  const is1112x767 = useMediaQuery({
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });

  // const is1366x1024 = useMediaQuery({
  //   // maxWidth: 1367,
  //   // minWidth: 1365,
  //   // minHeight: 1023,
  //   // maxHeight: 1025,
  //   minDeviceWidth: 1365
  // });



  const [clickedProjects, setClickedProjects] = React.useState(false)
  const [clickedFire, setClickedFire] = React.useState(false)
  const [clickedAboutMe, setClickedAboutMe] = React.useState(false)
  const [clickedDataViz, setClickedDataViz] = React.useState(false)

  const [showAboutMe, setShowAboutMe] = React.useState(false)

  const [reset, setReset] = React.useState(false)

  const vec = new THREE.Vector3();

  const boundsRef = React.useRef();
	// const { camera2 } = useThree();
  // const camera2 = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 1000);
  const camera2 = new THREE.PerspectiveCamera();

  

  // const bounds = useBounds()

  // React.useEffect(() => {
    // boundsRef.current.object.zoom(426)
    // boundsRef.current.reset()
  // })




  useFrame(state => {
    if (clickedAboutMe && is1112x767) {
      state.camera.lookAt(vec.set(-0.1, 0.5, 0.21 ), 1)
      state.camera.position.lerp(vec.set(25,0,-5 ), 0.01)
      state.camera.zoom = 2870
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && is1112x767) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 3200
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && is1112x767) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 3200
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && is1112x767) {
      state.camera.lookAt(vec.set(-0.5,0.2,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 1700
      state.camera.updateProjectionMatrix()
    } else if (is1112x767) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      // state.camera.zoom = 450
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && is800x600) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 1600
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && is800x600) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 2000
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && is800x600) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 2000
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && is800x600) {
      state.camera.lookAt(vec.set(-0.5,0.2,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 1200
      state.camera.updateProjectionMatrix()
    } else if (is800x600) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      state.camera.zoom = 320
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && is1024x768) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 1600
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && is1024x768) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 2000
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && is1024x768) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 2000
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && is1024x768) {
      state.camera.lookAt(vec.set(-0.5,0.2,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 2000
      state.camera.updateProjectionMatrix()
    } else if (is1024x768) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      state.camera.zoom = 500
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && isIphone8Hor) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 1400
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && isIphone8Hor) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1500
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && isIphone8Hor) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1500
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && isIphone8Hor) {
      state.camera.lookAt(vec.set(-0.9,0,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 1000
      state.camera.updateProjectionMatrix()
    } else if (isIphone8Hor) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      state.camera.zoom = 230
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && isIphone8Vert) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 800
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && isIphone8Vert) {
      state.camera.lookAt(vec.set(0, 0.1, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1300
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && isIphone8Vert) {
      state.camera.lookAt(vec.set(0, 0.1, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1300
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && isIphone8Vert) {
      state.camera.lookAt(vec.set(-0.9,0,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 1000
      state.camera.updateProjectionMatrix()
    } else if (isIphone8Vert) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      // state.camera.zoom = 170
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && isIphone8SmallHor) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 1380
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && isIphone8SmallHor) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1700
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && isIphone8SmallHor) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1700
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && isIphone8SmallHor) {
      state.camera.lookAt(vec.set(-0.9,0,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 800
      state.camera.updateProjectionMatrix()
    } else if (isIphone8SmallHor) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      state.camera.zoom = 210
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && isIphone8SmallVert) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 800
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && isIphone8SmallVert) {
      state.camera.lookAt(vec.set(0, 0.1, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1200
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && isIphone8SmallVert) {
      state.camera.lookAt(vec.set(0, 0.1, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1200
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && isIphone8SmallVert) {
      state.camera.lookAt(vec.set(-0.9,0,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 1000
      state.camera.updateProjectionMatrix()
    } else if (isIphone8SmallVert) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      // state.camera.zoom = 150
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && isIphoneSEHor) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 1150
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && isIphoneSEHor) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1200
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && isIphoneSEHor) {
      state.camera.lookAt(vec.set(0, 0, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 1200
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && isIphoneSEHor) {
      state.camera.lookAt(vec.set(-0.9,0,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 700
      state.camera.updateProjectionMatrix()
    } else if (isIphoneSEHor) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      state.camera.zoom = 200
      state.camera.updateProjectionMatrix()
    }

    else if (clickedAboutMe && isIphoneSEVert) {
      state.camera.lookAt(vec.set(-0.1, 0.48, 0.31 ), 1)
      state.camera.position.lerp(vec.set(20,0,0 ), 0.01)
      state.camera.zoom = 650
      state.camera.updateProjectionMatrix()

      if (Math.round(state.camera.position.x) >= 15 && Math.round(state.camera.position.y) === 9 && Math.round(state.camera.position.z) <= 2.5) {
        setShowAboutMe(true)
      }
    } else if (clickedFire && isIphoneSEVert) {
      state.camera.lookAt(vec.set(0, 0.1, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 900
      state.camera.updateProjectionMatrix()
    } else if (clickedDataViz && isIphoneSEVert) {
      state.camera.lookAt(vec.set(0, 0.1, 0 ), 1)
      state.camera.position.lerp(vec.set(30,35,90 ), 0.01)
      state.camera.zoom = 900
      state.camera.updateProjectionMatrix()
    } else if (clickedProjects && isIphoneSEVert) {
      state.camera.lookAt(vec.set(-0.9,0,-0.25), 1)
      state.camera.position.lerp(vec.set(0 ,0.5,-0.1), 0.01)
      // state.camera.rotateZ(2.5)
      state.camera.zoom = 1000
      state.camera.updateProjectionMatrix()
    } else if (isIphoneSEVert) {
      state.camera.position.lerp(vec.set(4.533619664870043,4.65008833762,4.503956295538191 ), 0.01)
      // state.camera.zoom = 150
      state.camera.updateProjectionMatrix()
    }

  })

  // console.log("clickedFire", clickedFire)
  // console.log("clickedAboutMe", clickedAboutMe)
  // console.log("projects", clickedProjects)

  const Screen = () => {
    const ref = React.useRef()
    useFrame((state, delta) => {
      const sin = Math.sin(state.clock.elapsedTime) + Math.cos(state.clock.elapsedTime * 10)
      ref.current.intensity = (0.5 + Math.abs(sin / 10)) * 40
    })
    return (
      // <spotLight
      //   ref={ref}
      //   intensity={1000}
      //   color="white"
      //   position={[0, 0, 0]}
      //   angle={1}
      //   penumbra={1}
      //   distance={8}
      //   target-position={[10, 5.5, -8.6]}
      //   onUpdate={(self) => self.target.updateMatrixWorld()}
      // />
      <>
        <spotLight
          ref={ref}
          castShadow
          color="#D7A33E"
          intensity={1}
          position={[0, 20, 20]}
          // position={[8,-50,15]}
          target-position={[0.05,0,0.7]}
          angle={0.018}
          penumbra={1}
          shadow-mapSize={[128, 128]}
          shadow-bias={0.00005}
          onUpdate={(self) => self.target.updateMatrixWorld()}
        />

        {clickedFire && (<><spotLight
          ref={ref}
          castShadow
          color="orange"
          intensity={1}
          position={[0, 20, 20]}
          // position={[8,-50,15]}
          target-position={[0.207,0.347,0.7]}
          // angle={0.0008}
          angle={0.0025}
          penumbra={1}
          shadow-mapSize={[128, 128]}
          shadow-bias={0.00005}
          onUpdate={(self) => self.target.updateMatrixWorld()}
        />
        <spotLight
          // ref={ref}
          castShadow
          color="orange"
          intensity={20}
          position={[0, 20, 20]}
          // position={[8,-50,15]}
          target-position={[0.06,0.24,0.7]}
          // angle={0.0008}
          angle={0.002}
          penumbra={1}
          shadow-mapSize={[128, 128]}
          shadow-bias={0.00005}
          onUpdate={(self) => self.target.updateMatrixWorld()}
        />
        </>
        )}

        {clickedDataViz && (<><spotLight
          ref={ref}
          castShadow
          color="orange"
          intensity={1}
          position={[0, 20, 20]}
          // position={[8,-50,15]}
          target-position={[0.207,0.347,0.7]}
          // angle={0.0008}
          angle={0.002}
          penumbra={1}
          shadow-mapSize={[128, 128]}
          shadow-bias={0.00005}
          onUpdate={(self) => self.target.updateMatrixWorld()}
        />
        <spotLight
          // ref={ref}
          castShadow
          color="orange"
          intensity={20}
          position={[0, 20, 20]}
          // position={[8,-50,15]}
          target-position={[0.06,0.24,0.7]}
          // angle={0.0008}
          angle={0.002}
          penumbra={1}
          shadow-mapSize={[128, 128]}
          shadow-bias={0.00005}
          onUpdate={(self) => self.target.updateMatrixWorld()}
        />
        </>
        )}


        {clickedProjects && (<spotLight
          ref={ref}
          castShadow
          color="#D7A33E"
          // color="white"
          intensity={1}
          // position={[-0.4, 0.02, -0.2]}
          position={[-0.415, 0.08, -0.2]}
          target-position={[-0.44, 0.086, -0.2]}
          // target-position={[-0.4, 0.01, -0.2]}

          angle={0.7}
          penumbra={1}
          shadow-mapSize={[128, 128]}
          shadow-bias={0.00005}
          onUpdate={(self) => self.target.updateMatrixWorld()}
        />
        )}


      </>
    )
  }

  return (
    <>
<color attach="background" args={["#252530"]} />
          
          <ambientLight intensity={0.01} />
          <hemisphereLight
            intensity={0.125}
            color="#8040df"
            groundColor="red"
          />
          {!clickedDataViz && !clickedFire && !clickedProjects && (
            <>
          <spotLight
            castShadow
            color="blue"
            intensity={2}
            position={[-50, 50, 40]}
            angle={0.25}
            penumbra={1}
            shadow-mapSize={[128, 128]}
            shadow-bias={0.00005}
          />
          <spotLight
            castShadow
            color="orange"
            intensity={5}
            position={[9.29726, -10.2478, 7.68613]}
            angle={0.05}
            penumbra={1}
          />
          <spotLight
            castShadow
            color="white"
            intensity={0.7}
            position={[0,20,10]}
            angle={0.25}
            penumbra={1}
          />        
          {/* <spotLight
            castShadow
            color="blue"
            intensity={5}
            position={[0,10,10]}
            angle={0.25}
            penumbra={1}
          />       */}
          </>
          )} 
        
          <spotLight
            castShadow
            color="white"
            intensity={100}
            position={[-3.25, 1.23, 5.5]}
            angle={0.0015}
            penumbra={0.5}
            shadow-mapSize={[64, 64]}
            shadow-bias={-0.000001}
            target-position={[0, 20, 0]}
          />

          <spotLight
            castShadow
            color="white"
            intensity={100}
            position={[-4.23, 1.14, 5.5]}
            angle={0.0012}
            penumbra={0.5}
            shadow-mapSize={[64, 64]}
            shadow-bias={-0.000001}
            target-position={[0, 20, 0]}
          />

          <Screen />

          <Bounds fit clip observe margin={1}>
            <Camping scale={0.1} setShowAboutMe={setShowAboutMe} showAboutMe={showAboutMe} setClickedProjects={setClickedProjects} clickedProjects={clickedProjects} setClickedFire={setClickedFire} clickedFire={clickedFire} clickedAboutMe={clickedAboutMe} setClickedAboutMe={setClickedAboutMe} clickedDataViz={clickedDataViz} setClickedDataViz={setClickedDataViz}/>
          </Bounds>
          <BakeShadows />

          <OrbitControls
            makeDefault
            // minAzimuthAngle={0}
            maxAzimuthAngle={Math.PI / 3}
            // minPolarAngle={Math.PI / 2.5}
            maxPolarAngle={Math.PI / 3}
            minZoom={150}
            maxZoom={1000}
            enableZoom={true}
            enablePan={true}
            zoomSpeed={0.5}
            ref={boundsRef} 
          />
          {/* <gridHelper
            args={[1000, 200, "#151515", "#020202"]}
            position={[0, -4, 0]}
          /> */}
          <mesh
            scale={200}
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -4, 0]}
            receiveShadow
          >
            <planeGeometry />
            <shadowMaterial transparent opacity={0.3} />
          </mesh>
          </>
  )
}


const Model = ({}) => {

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  const [showModel, setShowModel] = React.useState(false)


  const PlaySound = () => {
    onClose()

    // var myAudioCampfire = new Audio(CampfireSound); 
    var myAudioCampfire = new Audio("https://samtwl.s3.ap-southeast-1.amazonaws.com/firecrackle.mp3"); 
    myAudioCampfire.addEventListener('timeupdate', function() {
        var buffer = .6
        if(this.currentTime > this.duration - buffer){
          this.currentTime = 0
          this.play()
      }
    });
    myAudioCampfire.play();


    // var myAudioForestNight = new Audio(ForestNight); 
    var myAudioForestNight = new Audio("https://samtwl.s3.ap-southeast-1.amazonaws.com/forestnight.mp3"); 
    myAudioForestNight.addEventListener('timeupdate', function() {
      var buffer = .44
      if(this.currentTime > this.duration - buffer){
        this.currentTime = 0
        this.play()
    }
  });
    myAudioForestNight.play();
  }

  // function Loader() {
    const { active, progress, errors, item, loaded, total } = useProgress()
  //   return <Html center>{progress} % loaded</Html>
  // }




  if (progress === 100) {

  return (
          <Center w="100vw" h="100vh" bg="black">
            {!showModel && (
              
            <VStack textAlign="center" p="1em" h="100%" w="100%">

              <Spacer />

              <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" color="gray.400" as="i">Hey, I'm</Text>
              <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="6xl" color="gray.400">Samuel Tong</Text>

              <Link  pb="0rem" fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" color="gray.500" onClick={()=> {PlaySound(); setShowModel(true)}}>
                    <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="2xl" color="gray.500">Begin</Text>
              </Link>

              <Box h="50%" >
              <div className = "flame" id = "flame-2"></div>
              <div className = "flame" id = "flame-1"></div>
              <div className = "flame" id = "flame-3"></div>
                <div className = "small-element" id = "small-element-1"></div>
                <div className = "small-element" id = "small-element-2"></div>
                <div className = "fire-bottom">
                  <div className = "main-fire"></div>
                </div>
                <div className = "wood" id = "wood-1"></div>
                <div className = "wood" id = "wood-2"></div>
              </Box>







              {/* <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" color="gray.500">A Data Visualization Journal</Text> */}
              {/* <Link pt="0rem" pb="5rem" fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" color="gray.500" onClick={()=> {PlaySound(); setShowModel(true)}}>
                <VStack>
                  <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="2xl" color="gray.500">Begin</Text>
                </VStack>
              </Link> */}
            </VStack>
            )}

            {showModel && (
            <VStack w="100vw" h="100vh">
              <HStack w="100%" pt="1rem">
              <Spacer />

                <Box>
                  <div class="scroll-downs">
                    <div class="mousey">
                      <div class="scroller"></div>
                    </div>
                  </div>
                </Box>
                <Text pb="1em" textAlign="center" fontFamily="Helvetica Neue" fontWeight="light" fontSize="sm" color="gray.400">Scroll to zoom</Text>

                <Spacer />


                  <div class="scroll-downs3">
                    <div class="mousey3">
                      <div class="scroller2"></div>
                    </div>
                  </div>
                <Text pb="1em" textAlign="center" fontFamily="Helvetica Neue" fontWeight="light" fontSize="sm" color="gray.400">Drag to rotate</Text>

                <Spacer />
              </HStack>
              <Canvas
              orthographic
              shadows
              dpr={[1, 2]}
              // dpr={window.devicePixelRatio}
              camera={{ position: [10, 10, 10], zoom: 10 }}
              >
                <Scene />
              </Canvas>
              <HStack >
                <Text pb="1em" textAlign="center" fontFamily="Helvetica Neue" fontWeight="light" fontSize="sm" color="gray.400">Designed and developed by Samuel Tong</Text>
              </HStack>
                    
            </VStack>
            
            )}

          </Center>

      // <VStack >
      //   <Box w="100vw" h="100vh" p="1em" bg="black">
      //   <Modal isOpen={isOpen} onClose={onClose} size={"full"} >
      //   <ModalOverlay />
      //   <ModalContent bg="black">
      //     <Center w="100vw" h="100vh">
      //     <VStack>
      //     <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="6xl" color="gray.400">Campfire Stories</Text>
      //     <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" color="gray.500">A Data Visualization Journal</Text>
      //     <Link pt="3rem" fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" color="gray.500" onClick={PlaySound}>
      //       <VStack>
      //       <GiCampfire />
      //       <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="2xl" color="gray.500">Begin</Text>
      //       </VStack>
      //       </Link>
      //     </VStack>
      //     </Center>
      //   </ModalContent>
      // </Modal>
    
      //     <Canvas
      //       orthographic
      //       shadows
      //       dpr={[1, 2]}
      //       camera={{ position: [10, 10, 10], zoom: 10 }}
      //     >
      //             {/* <Suspense fallback={<Loader />}> */}

      //       <Scene />
      //       {/* </Suspense> */}

      //     </Canvas>
      //     </Box>
      //   </VStack>
    )
  }

  else {
    return (
        <Box w="100vw" h="100vh" p="1em" bg="black">
            <VStack w="100%" h="100%" textAlign="center">
              <Center w="100%" h="100%">
                <VStack>
                <GiCampfire color="white" pb="1rem" />
                <Text color="white" fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl">Loading interactive application...</Text>
                {/* <Text color="white" fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl">{progress}%</Text> */}
                {/* <Text color="white" fontFamily="Helvetica Neue" fontWeight="light" fontSize="xl">This is an interactive application</Text> */}
                </VStack>
              </Center>
            </VStack>
        </Box>
    )
  }

};

export default Model;

// import { FiUser } from "react-icons/fi";
import userblue from "./userblue.svg"
import usergreen from "./usergreen.svg"



const data = {

// module.exports = {
    "links":[
        {"source":"System A","target":"Alexander"},
        {"source":"System A","target":"Brooklyn"},
        {"source":"System A","target":"Christopher"},
        {"source":"System A","target":"Danny"},
        {"source":"System A","target":"Emma"},
        {"source":"System A","target":"Fernando"},
        {"source":"System A","target":"Gabriel"},
        {"source":"System A","target":"Hannah"},
        {"source":"System A","target":"Isabella"},
        {"source":"System B","target":"Jacob"},
        {"source":"System B","target":"Kaylee"},
        {"source":"System B","target":"Liam"},
        {"source":"System B","target":"Madison"},
        {"source":"System B","target":"Noah"},
        {"source":"System B","target":"Olivia"},
        {"source":"System B","target":"Pete"},
        {"source":"System C","target":"Quinn"},
        {"source":"System D","target":"Liam"},
        {"source":"System A","target":"Quinn"},
        {"source":"System C","target":"Jacob"},
        {"source":"System A","target":"Ryan"},
    ],
    "nodes":[
        {"id":"System A","symbolType":"circle","color":"#55709c","size":300},
        {"id":"System B","symbolType":"circle","color":"#55709c","size":300},
        {"id":"System C","symbolType":"circle","color":"#55709c","size":300},
        {"id":"System D","symbolType":"circle","color":"#55709c","size":300},
        {"id":"Alexander","name":"Alexander","svg":userblue,"size":400},
        {"id":"Brooklyn","name":"Brooklyn","svg":userblue,"size":400},
        {"id":"Christopher","name":"Christopher","svg":userblue,"size":400},
        {"id":"Danny","name":"Danny","svg":userblue,"size":400},
        {"id":"Emma","name":"Emma","svg":userblue,"size":400},
        {"id":"Fernando","name":"Fernando","svg":userblue,"size":400},
        {"id":"Gabriel","name":"Gabriel","svg":userblue,"size":400},
        {"id":"Hannah","name":"Hannah","svg":userblue,"size":400},
        {"id":"Isabella","name":"Isabella","svg":userblue,"size":400},
        {"id":"Jacob","name":"Jacob","svg":usergreen,"size":400},
        {"id":"Kaylee","name":"Kaylee","svg":usergreen,"size":400},
        {"id":"Liam","name":"Liam","svg":usergreen,"size":400},
        {"id":"Madison","name":"Madison","svg":usergreen,"size":400},
        {"id":"Noah","name":"Noah","svg":usergreen,"size":400},
        {"id":"Olivia","name":"Olivia","svg":usergreen,"size":400},
        {"id":"Pete","name":"Pete","svg":usergreen,"size":400},
        {"id":"Quinn","name":"Quinn","svg":userblue,"size":400},
        {"id":"Ryan","name":"Ryan","svg":userblue,"size":400},
    ]
}

export default data;

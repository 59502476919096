import * as React from "react";
import {
    Center,
    Text,
    VStack,
    Box,
    Divider,
    Link,
    HStack,
    Spacer,
    SlideFade,
    Img
  } from "@chakra-ui/react";
  import { Link as ReachLink } from "react-router-dom"
  import { FiArrowDown, FiArrowDownRight, FiArrowLeft, FiTwitter } from "react-icons/fi";
  import { useMediaQuery } from "react-responsive";


const Cover = () => {
    const is2016x863 = useMediaQuery({
      minWidth: 2015.1,
    });
    const is1112x767 = useMediaQuery({
      maxWidth: 2015,
      minWidth: 1111,
    });
  
    const is1024x768 = useMediaQuery({
      maxWidth: 1111,
      minWidth: 1023,
    });
  
    const is800x600 = useMediaQuery({
      maxWidth: 1023,
      minWidth: 799,
    });
  
    const isIphone8Hor = useMediaQuery({
      maxWidth: 799,
      minWidth: 735,
    });
  
    const isIphone8SmallHor = useMediaQuery({
      maxWidth: 735,
      minWidth: 666,
    });
  
    const isIphoneSEHor = useMediaQuery({
      maxWidth: 666,
      minWidth: 567,
    });
  
    const isIphone8Vert = useMediaQuery({
      maxWidth: 567,
      minWidth: 413,
    });
  
    const isIphone8SmallVert = useMediaQuery({
      maxWidth: 413,
      minWidth: 374,
    });
  
    const isIphoneSEVert = useMediaQuery({
      maxWidth: 374,
      minWidth: 319,
    });
  
  
    const [introHover, setIntroHover] = React.useState(false)
    const [autoHover, setAutoHover] = React.useState(false)
    const [dashboardHover, setDashboardHover] = React.useState(false)
    const [networkHover, setNetworkHover] = React.useState(false)
  
    const updateIntroHoverIn = () => {
      setIntroHover(true)
    }
    const updateIntroHoverOut = () => {
      setIntroHover(false)
    }
    const updateAutoHoverIn = () => {
      setAutoHover(true)
    }
    const updateAutoHoverOut = () => {
      setAutoHover(false)
    }  
    const updateDashboardHoverIn = () => {
      setDashboardHover(true)
    }
    const updateDashboardHoverOut = () => {
      setDashboardHover(false)
    }  
    const updateNetworkHoverIn = () => {
      setNetworkHover(true)
    }
    const updateNetworkHoverOut = () => {
      setNetworkHover(false)
    }
  
    return (
      <>
      {(is2016x863 || is1112x767 || is800x600 || isIphone8Hor || isIphone8SmallHor || isIphoneSEHor) && (
      <HStack fontFamily="Helvetica Neue" h="100vh" w="100vw" bg="#e9e6e4">
        <VStack w="15vw" alignItems="start" h="90vh" borderRight="solid 1px">
            <Link as={ReachLink} to="/" pl="1.5vw" fontSize="1.5vw" _hover={{bg: "none"}}>Home</Link>
            <Link href="https://medium.com/@samueltongwl" pl="1.5vw" fontSize="1.5vw" _hover={{bg: "none"}}>Medium Articles</Link>
            <Spacer />
            <HStack pl="1.5vw">
                <FiTwitter fontSize="1vw"/>
                <Link href="https://twitter.com/samueltongwl" fontSize="1vw" pl="0.5vw" _hover={{bg: "none"}}>@samueltongwl</Link>
            </HStack>
        </VStack>
        <HStack w="60vw" alignItems="start" h="90vh" textAlign="center" borderRight="solid 1px">
            <VStack w="30%" h="100%" >
                <Text w="80%" fontSize="6vw" textAlign="center" borderBottom="solid 1px">Data</Text>
                <VStack w="100%" h="100%" borderRight="solid 1px">
                    <Spacer />
                    <Text w="100%" fontSize="1.5vw" textAlign="left" pl="2vw" pt="5vw" pr="2vw">A collection of curated data visualizations and articles on all things data science and web development</Text>
                    <Spacer />
                    <HStack w="100%" fontSize="0.8vw" textAlign="justify" pl="2vw" pt="5vw" pr="2vw" pb="1vh">
                        <Text>Scroll down on Storytelling & Read for more</Text>
                        <FiArrowDownRight />
                    </HStack>
                </VStack>
            </VStack>
            <VStack w="70%"  h="100%" overflow="scroll">
                <Text w="92%" fontSize="6vw" textAlign="left" borderBottom="solid 1px">Storytelling</Text>
                <Text w="92%" fontSize="6vw" textAlign="left" borderBottom="solid 1px">01.</Text>
                <Img
                  w="12vw"
                  objectFit="cover"
                  src={require("./images/robot_on_computer.png")}
                  alt="Schedule"
                  borderRadius="15"
                  border="solid 1px"
                  borderColor="blackAlpha.50"
                />  
                <Link as={ReachLink} to="/dataviz/1/cover" w="92%" fontSize="1.5vw" textAlign="left" borderBottom="solid 1px" pt="0.5rem" pb="1rem" _hover={{bg: "none"}}>Enhancing User Access Management Audit with Automation and Analysis</Link>
            </VStack>
        </HStack>
        <VStack w="20vw" alignItems="center" h="90vh" textAlign="center" borderRight="solid 1px">
            <Text w="90%" fontSize="6vw" textAlign="center" borderBottom="solid 1px">Read</Text>
            <Img
                  w="10vw"
                  objectFit="cover"
                  src={require("./images/man_on_computer.png")}
                  alt="Schedule"
                  borderRadius="15"
                  border="solid 1px"
                  borderColor="blackAlpha.50"
                />     
            <Link href="https://medium.com/@samueltongwl/how-i-developed-web-development-and-data-analysis-skills-from-being-an-auditor-aee014abde74" w="90%" fontSize="1.2vw" textAlign="justify" borderBottom="solid 1px" pt="0.5rem" pb="1rem" _hover={{bg: "none"}}>• How I Developed Web Development and Data Analysis Skills from Being an Auditor</Link>
        </VStack>
      </HStack>
      )}
      {(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
      <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" bg="#e9e6e4">
        <HStack h="7vh" alignItems="start" w="90vw" pt="3vh" borderBottom="solid 1px">
            <Link as={ReachLink} to="/" pl="1.5vw" fontSize="1.5vw" _hover={{bg: "none"}}>Home</Link>
            <Link href="https://medium.com/@samueltongwl" pl="1.5vw" fontSize="1.5vw" _hover={{bg: "none"}}>Medium Articles</Link>
            <Spacer />
            <HStack pl="1.5vw">
                <FiTwitter fontSize="1vw"/>
                <Link href="https://twitter.com/samueltongwl" fontSize="1vw" pl="0.5vw" _hover={{bg: "none"}}>@samueltongwl</Link>
            </HStack>
        </HStack>
        <HStack h="43vh" alignItems="start" w="90vw" textAlign="center" >
            <VStack w="30%" h="100%" >
                <Text w="80%" fontSize="6vw" textAlign="center" borderBottom="solid 1px">Data</Text>
                <VStack w="100%" h="100%" borderRight="solid 1px">
                    <Spacer />
                    <Text w="100%" fontSize="1.5vw" textAlign="left" pl="2vw" pt="5vw" pr="2vw">A collection of curated data visualizations and articles on all things data science and web development</Text>
                    <Spacer />
                    <HStack w="100%" fontSize="0.8vw" textAlign="justify" pl="2vw" pt="5vw" pr="2vw" pb="1vh">
                        <Text>Scroll down on Storytelling & Read for more</Text>
                        <FiArrowDownRight />
                    </HStack>
                </VStack>
            </VStack>
            <VStack w="70%"  h="100%" overflow="scroll">
                <Text w="92%" fontSize="6vw" textAlign="left" borderBottom="solid 1px">Storytelling</Text>
                <Text w="92%" fontSize="6vw" textAlign="left" borderBottom="solid 1px">01.</Text>
                <HStack borderBottom="solid 1px" w="92%" pb="1vh">
                <Img
                  w="12vw"
                  objectFit="cover"
                  src={require("./images/robot_on_computer.png")}
                  alt="Schedule"
                  borderRadius="5"
                  border="solid 1px"
                  borderColor="blackAlpha.50"
                />  
                <Link as={ReachLink} to="/dataviz/1/cover" w="92%" fontSize="1.5vw" textAlign="left"  _hover={{bg: "none"}}>Enhancing User Access Management Audit with Automation and Analysis</Link>
                </HStack>
            </VStack>
        </HStack>
        <VStack h="50vh" alignItems="center" w="90vw" textAlign="center" borderTop="solid 1px" overflow="scroll">
            <Text w="100%" fontSize="6vw" textAlign="center" borderBottom="solid 1px">Read</Text>
            <HStack borderBottom="solid 1px" w="100%" pb="1vh" pl="2vw">
            <Img
                  w="10vw"
                  objectFit="cover"
                  src={require("./images/man_on_computer.png")}
                  alt="Schedule"
                  borderRadius="5"
                  border="solid 1px"
                  borderColor="blackAlpha.50"
                />     
            <Link href="https://medium.com/@samueltongwl/how-i-developed-web-development-and-data-analysis-skills-from-being-an-auditor-aee014abde74" w="90%" fontSize="1.2vw" textAlign="left"  _hover={{bg: "none"}}>• How I Developed Web Development and Data Analysis Skills from Being an Auditor</Link>
            </HStack>
        </VStack>
      </VStack>
      )}
      </>
    );
  };
  
  export default Cover;
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import {
  Home,
  Model,
  DataViz1Cover,
  DataViz1PageOne,
  DataViz1PageTwo,
  DataViz1PageThree,
  DataViz1PageFour,
  DataVizLanding,
} from "./pages";

const App: React.FC<unknown> = () => {
  return (
    <Routes>
      <Route path="/" element={<Model />} />
      <Route path="/blog" element={<DataVizLanding />} />
      <Route path="/dataviz/1/cover" element={<DataViz1Cover />} />
      <Route path="/dataviz/1/introduction" element={<DataViz1PageOne />} />
      <Route path="/dataviz/1/automated-system" element={<DataViz1PageTwo />} />
      <Route path="/dataviz/1/dashboard" element={<DataViz1PageThree />} />
      <Route
        path="/dataviz/1/network-analysis"
        element={<DataViz1PageFour />}
      />
    </Routes>
  );
};

export default App;

import React from "react";
import {
  Center,
  Text,
  VStack,
  Link,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom"
import { FiLock, FiFeather } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";

import "./style.css"


const PageOne = () => {
  const is2016x863 = useMediaQuery({
    minWidth: 2015.1,
  });
  const is1112x767 = useMediaQuery({
    maxWidth: 2015,
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });


  function init() {
    new SmoothScroll(document, 30, 12);
  }
  
  function SmoothScroll(target, speed, smooth) {
    if (target === document)
      target =
        document.scrollingElement ||
        document.documentElement ||
        document.body.parentNode ||
        document.body; // cross browser support for document scrolling

    var moving = false;
    var pos = target.scrollTop;
    var frame =
      target === document.body && document.documentElement
        ? document.documentElement
        : target; // safari is the new IE

    target.addEventListener("mousewheel", scrolled, { passive: false });
    target.addEventListener("DOMMouseScroll", scrolled, { passive: false });



    function scrolled(e) {
      e.preventDefault(); // disable default scrolling
  
      var delta = normalizeWheelDelta(e);
  
      pos += -delta * speed;
      pos = Math.max(0, Math.min(pos, target.scrollHeight - frame.clientHeight)); // limit scrolling
  
      if (!moving) update();
    }
  
    function normalizeWheelDelta(e) {
      if (e.detail) {
        if (e.wheelDelta)
          return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1); // Opera
        else return -e.detail / 3; // Firefox
      } else return e.wheelDelta / 120; // IE,Safari,Chrome
    }
  
    function update() {
      moving = true;
  
      var delta = (pos - target.scrollTop) / smooth;
  
      target.scrollTop += delta;
  
      if (Math.abs(delta) > 0.5) requestFrame(update);
      else moving = false;
    }
  
    var requestFrame = (function() {
      // requestAnimationFrame cross browser
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(func) {
          window.setTimeout(func, 1000 / 50);
        }
      );
    })();
  }

  document.body.onload = init();
  document.body.onscroll = function() {
    document.querySelector(".dotgrid").style.top =
      (
        58 -
        window.scrollY / document.documentElement.scrollHeight * 60
      ).toString() + "vh";
    document.querySelector(".dotgridbg").style.top =
      (
        60 -
        window.scrollY / document.documentElement.scrollHeight * 47
      ).toString() + "vh";
    document.querySelector(".dgcirc").style.top =
      (
        55 -
        window.scrollY / document.documentElement.scrollHeight * 30
      ).toString() + "vh";
    document.querySelector(".moveName").style.top =
      (
        5.5 -
        window.scrollY / document.documentElement.scrollHeight * 20
      ).toString() + "vh";
    
    document.querySelector(".arc").style.top =
      (
        28 -
        window.scrollY / document.documentElement.scrollHeight * 45
      ).toString() + "vh";
    document.querySelector(".arc").style.transform =
      "rotate(" + 
      Math.round(
        135 +
        window.scrollY / document.documentElement.scrollHeight * 500
      ).toString() + "deg)";

      document.querySelector(".dotgrid").style.top =
      (
        58 -
        window.scrollY / document.documentElement.scrollHeight * 45
      ).toString() + "vh";
    document.querySelector(".dotgrid").style.transform =
      "rotate(" + 
      Math.round(
        90 +
        window.scrollY / document.documentElement.scrollHeight * 500
      ).toString() + "deg)";

    document.querySelector(".triangle").style.top =
      (
        27 -
        window.scrollY / document.documentElement.scrollHeight * 55
      ).toString() + "vh";

    document.querySelector(".linebg").style.top =
      (
        35 -
        window.scrollY / document.documentElement.scrollHeight * 70
      ).toString() + "vh";
    document.querySelector(".btn").style.top =
      (
        15 -
        window.scrollY / document.documentElement.scrollHeight * 35
      ).toString() + "vh";
    document.querySelector(".linebg").style.backgroundPosition =
      "0 " + 
      (
        0 +
        window.scrollY / document.documentElement.scrollHeight * -50
      ).toString() + "%";
  };  




  return (
    <>
    {is2016x863 && (
    <VStack fontFamily="Helvetica Neue" h="330vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="5rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="1rem" pb="1rem">
                        <FiLock color="white" fontSize="3rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text p="1rem" fontSize="md" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="1rem" pb="1rem">
                        <FiFeather color="white" fontSize="3rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text p="1rem" fontSize="md" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="8rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{is1112x767 && (
    <VStack fontFamily="Helvetica Neue" h="330vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="5rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="1rem" pb="1rem">
                        <FiLock color="white" fontSize="3rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text p="1rem" fontSize="sm" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="1rem" pb="1rem">
                        <FiFeather color="white" fontSize="3rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text p="1rem" fontSize="sm" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="8rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{is800x600 && (
    <VStack fontFamily="Helvetica Neue" h="330vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="5rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="1rem" pb="1rem">
                        <FiLock color="white" fontSize="3rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="1rem" pb="1rem">
                        <FiFeather color="white" fontSize="3rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="8rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{isIphone8Hor && (
    <VStack fontFamily="Helvetica Neue" h="340vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="5rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="0rem" pb="0rem">
                        <FiLock color="white" fontSize="2rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text pl="1rem" pr="1rem" fontSize="1.1vw" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="0rem" pb="0rem">
                        <FiFeather color="white" fontSize="2rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text pl="1rem" pr="1rem" fontSize="1.1vw" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="0rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{isIphone8SmallHor && (
    <VStack fontFamily="Helvetica Neue" h="340vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="5rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="0rem" pb="0rem">
                        <FiLock color="white" fontSize="2rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text pl="1rem" pr="1rem" fontSize="1.1vw" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="0rem" pb="0rem">
                        <FiFeather color="white" fontSize="2rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text pl="1rem" pr="1rem" fontSize="1.1vw" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="0rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{isIphoneSEHor && (
    <VStack fontFamily="Helvetica Neue" h="350vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="5rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="0rem" pb="0rem">
                        <FiLock color="white" fontSize="2rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text pl="1rem" pr="1rem" fontSize="1.1vw" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="0rem" pb="0rem">
                        <FiFeather color="white" fontSize="2rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.2vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text pl="1rem" pr="1rem" fontSize="1.1vw" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="0rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{isIphone8Vert && (
    <VStack fontFamily="Helvetica Neue" h="310vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="5rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="1rem" pb="1rem">
                        <FiLock color="white" fontSize="2rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="1rem" pb="1rem">
                        <FiFeather color="white" fontSize="2rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="0rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{isIphone8SmallVert && (
    <VStack fontFamily="Helvetica Neue" h="320vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="2rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="1rem" pb="1rem">
                        <FiLock color="white" fontSize="2rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="1rem" pb="1rem">
                        <FiFeather color="white" fontSize="2rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="4rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}

{isIphoneSEVert && (
    <VStack fontFamily="Helvetica Neue" h="320vh" w="100vw" border="solid 1px" bg="white">

      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="192vh" position="relative" top="8vh">
            <div className="dotgridbg"></div>
            <div className="dgcirc"></div>
            <div className="dotgrid"></div>
            <div className="cont" id="home" style={{paddingTop: "55vh"}}>
              <div className="mainhead">
                <VStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginLeft: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>Effective </span> {" "}
                    <span style={{animationDelay: ".1s"}}>audit</span> {" "}
                    <span style={{animationDelay: ".1s"}}>analysis</span> {" "}
                    <span style={{animationDelay: ".2s"}}>in</span> {" "}
                    <span style={{animationDelay: ".3s"}} className="blueeee">user</span> {" "}
                    <span style={{animationDelay: ".4s"}} className="blueeee">access</span> {" "} 
                    <span style={{animationDelay: ".5s"}} className="blueeee">management</span> {" "} 
                    <span style={{animationDelay: ".6s"}}>can be effortless</span>
                    <span style={{animationDelay: ".7s"}}>.</span> {" "}
                  </h1>
                  <Text pt="1rem" className="mainarrow"><i className="down"></i></Text>
                </VStack>
              </div>
            </div>
            <div className="cont" id="testimonials" style={{paddingBottom: "62vh"}}>
              <div className="mainhead">
                <h1
                  style={{
                    fontSize: "2vw", 
                    lineHeight: "2.6vw",
                    marginLeft: "3.4vw",
                    marginBottom: "0vw",
                    fontWeight: "900",
                    textAlign: "left",
                  }}
                >
                  <span className="tshadow">"Connect Anywhere Computing</span> {" "}
                  <span>will further drive</span> {" "}
                  <span>need for</span> 
                  <span className="blueeee">Smarter Access Control</span>
                  <span>."</span>
                  <a 
                    style={{fontSize: "1.5vw", marginLeft:"24.5vw", position: "relative"}} 
                    className="moveName" href="https://www.gartner.com/en/articles/iam-leaders-plan-to-adopt-these-6-identity-and-access-management-trends">-Gartner</a>
                </h1>
              </div>
            </div>
            <div className="arc"></div>
            <div className="triangle"></div>
            <div className="linebg"></div>    
        </VStack>

        <VStack h="100vh" position="relative" top="45vh">
          <div className="cont" id="home" style={{paddingTop: "55vh"}}>
            <div className="mainhead2" id="desiredoutcomes">
            <VStack h="100%">
              <h1 
                style={{
                  fontSize: "3vw", 
                  lineHeight: "2.6vw",
                  marginLeft: "3.4vw",
                  marginBottom: "0vw",
                  fontWeight: "900",
                }}
              >
                <span style={{animationDelay: "0s"}}>Desired </span> {" "}
                <span style={{animationDelay: ".1s"}} className="blueeee">outcomes</span>
                <span style={{animationDelay: ".2s"}}>.</span> {" "}
              </h1>
              <VStack p="2rem" h="100%">
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>01.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Center pt="1rem" pb="1rem">
                        <FiLock color="white" fontSize="2rem"/> 
                      </Center>
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Prevent Unauthorised Access
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Reduce risk of data misuse and data breach by swiftly identifying and restricting unauthorized accesses. Examples include dormant accounts that are still active and duplicated accounts.</Text>
                    </div>
                  </div>
                </HStack>
                <Spacer pt="5rem"/>
                <HStack>
                  <h1 
                    style={{
                      fontSize: "3vw", 
                      lineHeight: "2.6vw",
                      marginRight: "3.4vw",
                      marginBottom: "0vw",
                      fontWeight: "900",
                    }}
                  >
                    <span style={{animationDelay: "0s"}}>02.</span>
                  </h1>
                  <div className="card" style={{color: "#89ec5b"}}>
                    <div className="text">
                      <Spacer />
                      <Center pt="1rem" pb="1rem">
                        <FiFeather color="white" fontSize="2rem"/> 
                      </Center>                    
                      <h1
                        style={{
                          // height: "6rem",
                          lineHeight: "2.6vw",
                          fontSize: "1.4vw", 
                          fontWeight: "900",
                          textAlign: "center",
                          paddingLeft: "1rem",
                          paddingRight: "1rem"
                        }}>
                          Ensure Regulatory Compliance
                      </h1>
                      <Text p="1rem" fontSize="1.2vw" color="white">Failing to meet regulatory requirements puts organizations at risk of security related events, extraordinary fines and reputational damage - eroding client and investor trust.</Text>
                    </div>
                  </div>
                  </HStack>
              </VStack>
              
            </VStack>
               <VStack h="100%" >
                  <Link mt="4rem" as={ReachLink} to="/dataviz/1/automated-system" className="btn">learn how to build an automated analytical system</Link>
                </VStack>
            </div>
          
            </div>

            
        </VStack>
    </VStack>
    )}
    </>
  );
};

export default PageOne;

import React from "react";
import {
  Center,
  Text,
  VStack,
  Box,
  Divider,
  Link,
  HStack,
  Spacer,
  SlideFade,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom"
import { FiArrowLeft } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";

import ParticlesContainer from "./ParticlesContainer";

import "./styles.css"


const Cover = () => {
  const is2016x863 = useMediaQuery({
    minWidth: 2015.1,
  });
  const is1112x767 = useMediaQuery({
    maxWidth: 2015,
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });


  const [introHover, setIntroHover] = React.useState(false)
  const [autoHover, setAutoHover] = React.useState(false)
  const [dashboardHover, setDashboardHover] = React.useState(false)
  const [networkHover, setNetworkHover] = React.useState(false)

  const updateIntroHoverIn = () => {
    setIntroHover(true)
  }
  const updateIntroHoverOut = () => {
    setIntroHover(false)
  }
  const updateAutoHoverIn = () => {
    setAutoHover(true)
  }
  const updateAutoHoverOut = () => {
    setAutoHover(false)
  }  
  const updateDashboardHoverIn = () => {
    setDashboardHover(true)
  }
  const updateDashboardHoverOut = () => {
    setDashboardHover(false)
  }  
  const updateNetworkHoverIn = () => {
    setNetworkHover(true)
  }
  const updateNetworkHoverOut = () => {
    setNetworkHover(false)
  }

  return (
    <>
    {is2016x863 && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="lg" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack h="85%" position="relative" top="8vh">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="5xl" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <HStack h="100%" 
            >
              <Box pt="5rem" pl="7rem" w="35%" alignSelf="start">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" textAlign="left" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="64%" w="100%">
                <ParticlesContainer />
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="20%">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text>01. </Text>
                      <Link 
                        fontSize="lg"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="lg" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text>03. </Text>
                      <Link 
                        fontSize="lg"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="lg" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="20%" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text>02. </Text>
                      <Link 
                        fontSize="lg"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="lg" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text>04. </Text>
                      <Link 
                        fontSize="lg"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="lg" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </HStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

    {is1112x767 && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Spacer />
        <Link
          w="15%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Spacer />
        <Link   
          w="15%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Spacer />
        <Link       
          w="15%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Spacer />
        <Link         
          w="15%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="md" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
        <Spacer />
      </Center>
      

        <VStack h="85%" position="relative" top="8vh">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="5xl" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <HStack h="100%" 
            >
              <Box pt="5rem" pl="7rem" w="35%" alignSelf="start">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="3xl" textAlign="left" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="64%" w="100%">
                <ParticlesContainer />
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="28vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text>01. </Text>
                      <Link 
                        fontSize="md"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="sm" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text>03. </Text>
                      <Link 
                        fontSize="md"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="sm" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="28vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text>02. </Text>
                      <Link 
                        fontSize="md"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="sm" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text>04. </Text>
                      <Link 
                        fontSize="md"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="sm" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </HStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

  {is800x600 && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="sm" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="85%" position="relative" top="8vh">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="3xl" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <HStack h="100%" 
            >
              <Box pt="2rem" pl="3rem" w="45%" alignSelf="start">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="xl" textAlign="left" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="64%" w="100%">
                <ParticlesContainer />
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="30vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text>01. </Text>
                      <Link 
                        fontSize="sm"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="xs" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text>03. </Text>
                      <Link 
                        fontSize="sm"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="xs" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="30vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text>02. </Text>
                      <Link 
                        fontSize="sm"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="xs" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text>04. </Text>
                      <Link 
                        fontSize="sm"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="xs" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </HStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
          fontSize="sm"
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

{isIphone8Hor && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="xs" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="85%" position="relative" top="8vh">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem" fontSize="xs">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem" fontSize="xs">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="lg" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <HStack h="100%" 
            >
              <Box pt="2rem" pl="3rem" w="45%" alignSelf="start">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="md" textAlign="left" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="64%" w="100%">
                <ParticlesContainer />
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="35vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="xs">01. </Text>
                      <Link 
                        fontSize="xs"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="xs" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="xs">03. </Text>
                      <Link 
                        fontSize="xs"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="xs" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="35vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="xs">02. </Text>
                      <Link 
                        fontSize="xs"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="xs" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="xs">04. </Text>
                      <Link 
                        fontSize="xs"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="xs" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </HStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
          fontSize="sm"
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

{isIphone8SmallHor && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="85%" position="relative" top="8vh">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem" fontSize="10px">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem" fontSize="10px">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="md" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <HStack h="100%" 
            >
              <Box pt="2rem" pl="3rem" w="45%" alignSelf="start">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="sm" textAlign="left" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="64%" w="100%">
                <Box w="100%" h="100%" mt="-8rem">
                <ParticlesContainer />
                </Box>
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="37vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">01. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="9px" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">03. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="37vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">02. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">04. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </HStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
          fontSize="sm"
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

{isIphoneSEHor && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="10vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="85%" position="relative" top="8vh">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem" fontSize="10px">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem" fontSize="10px">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="md" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <HStack h="100%" 
            >
              <Box pt="2rem" pl="3rem" w="45%" alignSelf="start">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="sm" textAlign="left" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="64%" w="100%">
                <Box w="100%" h="100%" mt="-8rem">
                <ParticlesContainer />
                </Box>
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="37vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">01. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="9px" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">03. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="37vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">02. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">04. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </HStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
          fontSize="sm"
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

{isIphone8Vert && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="85%" position="relative" top="8vh" w="100vw">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem" fontSize="10px">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem" fontSize="10px">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="md" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <VStack h="100%" w="100%" 
            >
              <Box pt="1rem" w="80%">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="sm" textAlign="center" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="100%" w="100%">
                <Box w="100%" h="70%" mt="-11rem">
                <ParticlesContainer />
                </Box>
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="37vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">01. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="9px" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">03. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="37vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">02. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">04. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </VStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
          fontSize="10px"
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

{isIphone8SmallVert && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="10px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="85%" position="relative" top="8vh" w="100vw">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem" fontSize="10px">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem" fontSize="10px">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="1rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="md" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <VStack h="100%" w="100%" 
            >
              <Box pt="1rem" w="80%">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="sm" textAlign="center" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="100%" w="100%">
                <Box w="100%" h="50%" mt="-11rem">
                <ParticlesContainer />
                </Box>
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="37vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">01. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="9px" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">03. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="37vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">02. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">04. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </VStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
          fontSize="10px"
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}

{isIphoneSEVert && (
    <VStack fontFamily="Helvetica Neue" h="100vh" w="100vw" border="solid 1px" bg="white">
      <Center 
        h="7vh"
        w="100vw" 
        style={{ 
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "0.7rem 2rem",
          overflow: "hidden",
          zIndex: 1,
          width: "100%",
          margin: "auto",
          top: 0,
          opacity: 0.9,
          background: "white",
          boxShadow: "12px 0 15px -4px white, -12px 0 8px -4px rgba(31, 73, 125, 0.8)",
        }}
      >
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/cover">
          Cover
        </Link>
        <Link
          w="20%"
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/introduction">
          Introduction
        </Link>        
        <Link   
          w="20%"    
          className="hover-4"
          p="10px"      
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/automated-system">
          Automated System
        </Link>
        <Link       
          w="20%" 
          className="hover-4"
          p="10px"   
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          href="/dataviz/1/dashboard">
          Dashboard
        </Link>
        <Link         
          w="20%"  
          className="hover-4"
          p="10px"
          color="gray.800"  
          _hover={{
            background: "white",
            color: "gray.500",
          }} 
          fontFamily="helvetica neue" 
          fontSize="8px" 
          fontStyle="normal" 
          as={ReachLink} 
          to="/dataviz/1/network-analysis">
          Network Analysis
        </Link>
      </Center>
      

        <VStack h="85%" position="relative" top="7vh" w="100vw">
          <Box textAlign="right" w="100%" pr="2rem">
          <Text textAlign="right" pt="0rem" fontSize="8px">Data Viz Issue #1</Text>
          <Text textAlign="right" pb="0rem" fontSize="8px">24th November 2022</Text>
          </Box>

          <VStack 
            h="100%" 
            w="98%"
            pt="0rem"
            pb="1rem"
            border="solid 1px" 
            borderRadius="5"
            borderColor="#dbdbdb"
            >
            <Text fontWeight="light" fontSize="sm" textAlign="center" mt="1rem" color="#355993" borderBottom="solid 1px black">USER ACCESS ANALYTICS</Text>
            <VStack h="100%" w="100%" 
            >
              <Box pt="1rem" w="80%">
                <Divider borderColor="black"/>
                <Text fontFamily="Helvetica Neue" fontWeight="light" fontSize="xs" textAlign="center" pt="1rem" pb="1rem" color="#ef8545">Enhancing User Access Management Audit with an Automated Analytical System</Text>
                <Divider borderColor="black"/>
              </Box>

              <Center m="2rem" p="1rem" h="100%" w="100%">
                <Box w="100%" h="50%" mt="-11rem">
                <ParticlesContainer />
                </Box>
                <HStack w="100%">

                  <Spacer />
                  <Spacer />
                  <Spacer />


                  <VStack w="37vh">

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">01. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateIntroHoverIn}
                        onMouseOut={updateIntroHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/introduction"
                      >
                        Introduction
                      </Link>
                    </HStack>
                      <SlideFade in={introHover} offsetY='20px'>
                        <Text fontSize="9px" color="#355993">Effective user access reviews - why it is important and how to achieve it</Text>
                      </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">03. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateDashboardHoverIn}
                        onMouseOut={updateDashboardHoverOut}
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </Link>
                    </HStack>
                    <SlideFade in={dashboardHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Providing visual context to risk identification for enhanced risk management</Text>
                    </SlideFade>
                  </Box>

                  </VStack>

                  <Spacer />

                  <VStack w="37vh" >

                  <Box textAlign="left" w="100%" mb="2rem">
                    <HStack pb="1rem">
                      <Text fontSize="10px">02. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateAutoHoverIn}
                        onMouseOut={updateAutoHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/automated-system"
                      >
                        Automated System
                      </Link>
                    </HStack> 
                    <SlideFade in={autoHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Direct data extraction, processing, modeling, analysis, visualizing and reporting</Text>
                    </SlideFade>
                  </Box>

                  <Box textAlign="left" w="100%">
                    <HStack pb="1rem">
                      <Text fontSize="10px">04. </Text>
                      <Link 
                        fontSize="10px"
                        fontWeight="bold" 
                        onMouseOver={updateNetworkHoverIn}
                        onMouseOut={updateNetworkHoverOut}
                        as={ReachLink} 
                        to="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </Link>
                    </HStack>
                    <SlideFade in={networkHover} offsetY='20px'>
                      <Text fontSize="9px" color="#355993">Useful for visualising increasingly complex relationships within user access data</Text>
                    </SlideFade>
                  </Box>
                  
                  </VStack>

                  <Spacer />
                  <Spacer />
                  <Spacer />

                </HStack>
              </Center>
            </VStack>
          </VStack>

          <HStack>
          <Link 
            href="/">
          <FiArrowLeft /> 
          </Link>
          <Link 
          fontSize="10px"
            href="/"    
            _hover={{
              background: "white",
              color: "gray.500",
            }} >
              Back to mainpage
          </Link>
          </HStack>
        </VStack>
    </VStack>
    )}
    </>
  );
};

export default Cover;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactText } from "react";
// import UserService from "../../services/UserService";

import {
  Box,
  Flex,
  VStack,
  Text,
  HStack,
  Center,
  Link,
  CloseButton,
  useColorModeValue,
  BoxProps,
  FlexProps,
  Icon,
  useDisclosure,
  Drawer,
  DrawerContent,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuGroup,
  Avatar,
  Spacer,
  Img,
} from "@chakra-ui/react";
import { IconType } from "react-icons";

import {
  FiCalendar,
  FiUsers,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiBriefcase,
  FiClipboard,
  FiLayout,
  FiLogOut,
  FiBookOpen,
  FiMessageSquare,
  FiBook,
  FiHeadphones,
  FiClock,
  FiMonitor,
  FiUser,
  FiColumns,
  FiCloud,
  FiDatabase,
} from "react-icons/fi";

import { Link as ReachLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "../styles.css";

const UsersNav = ({
  children,
  title,
  linkItemsSelectedSidebar,
  linkItemsAnalyticsSelectedSidebar,
  linkItemsResourcesSelectedSidebar,
}: any) => {
  const is2016x863 = useMediaQuery({
    minWidth: 2015.1,
  });
  const is1112x767 = useMediaQuery({
    maxWidth: 2015,
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });

  interface SidebarProps extends BoxProps {
    onClose: () => void;
  }

  interface LinkItemProps {
    name: string;
    icon: IconType;
    link: string;
    selectedSidebar: boolean;
  }

  const LinkItems: Array<LinkItemProps> = [
    {
      name: "Dashboard",
      icon: FiCalendar,
      link: "/dataviz/1/dashboard",
      selectedSidebar: true,
    },
    {
      name: "Data",
      icon: FiColumns,
      link: "#",
      selectedSidebar: false,
    },
    {
      name: "Schedule",
      icon: FiCalendar,
      link: "#",
      selectedSidebar: false,
    },
    {
      name: "Settings",
      icon: FiSettings,
      link: "#",
      selectedSidebar: false,
    },
  ];

  const LinkItemsAnalytics: Array<LinkItemProps> = [
    {
      name: "Profile",
      icon: FiUser,
      link: "#",
      selectedSidebar: false,
    },
    {
      name: "Team",
      icon: FiUsers,
      link: "#",
      selectedSidebar: false,
    },
  ];

  const LinkItemsResources: Array<LinkItemProps> = [
    {
      name: "Support",
      icon: FiHeadphones,
      link: "#",
      selectedSidebar: false,
    },
  ];

  interface NavItemProps extends FlexProps {
    icon: IconType;
    link: string;
    // selectedSidebar: boolean;
    children: ReactText;
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  interface MobileProps extends FlexProps {
    onOpen: () => void;
  }

  const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    // const logOut = () => {
    //   localStorage.clear();
    //   window.location.href = "/";
    // };

    return (
      <>
        {is2016x863 && (
          <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            // height="20"
            height="7vh"
            alignItems="center"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            // borderBottomWidth="1px"
            // borderBottomColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent={{ base: "space-between", md: "flex-end" }}
            // border="solid 1px"
            // {...rest}
            // w="80%"
            // boxShadow="sm"
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
            />
            <Spacer />
            <Text
              fontSize="xl"
              fontFamily="helvetica"
              color="#323b4b"
              align="center"
            >
              {title}
            </Text>
            <Spacer />

            <HStack w="10%">
              <Text
                fontFamily="helvetica neue"
                fontSize="lg"
                fontWeight="black"
                color="white"
              >
                Dashboard
              </Text>
            </HStack>

            <Spacer />

            <HStack w="60%">
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                // color="gray.800"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="md"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/cover"
              >
                Cover
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="md"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/introduction"
              >
                Introduction
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="md"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/automated-system"
              >
                Automated System
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="md"
                fontStyle="normal"
                href="/dataviz/1/dashboard"
              >
                Dashboard
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="md"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/network-analysis"
              >
                Network Analysis
              </Link>
              <Spacer />
            </HStack>

            <HStack spacing={{ base: "0", md: "6" }} mr="5">
              <Menu>
                <MenuButton>
                  <IconButton
                    fontWeight="sm"
                    size="lg"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell color="white" />}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="Notifications">
                    <MenuItem icon={<FiMessageSquare />} fontSize="xs">
                      No new notifications
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton fontWeight="sm">
                    <HStack>
                      <Avatar size={"sm"} name="User" />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        {/* <Text fontSize="sm">{users}</Text> */}
                        <Text fontSize="sm" color="white">
                          Username
                        </Text>
                      </VStack>
                      <Spacer />
                      <FiChevronDown />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Navigate">
                      <MenuItem
                        icon={<FiLayout />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FiLogOut />}
                        fontSize="xs"
                        as={ReachLink}
                        to="/dataviz/1/cover"
                      >
                        Log Out
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Help">
                      <MenuItem icon={<FiBookOpen />} fontSize="xs">
                        <Link href="#">FAQ</Link>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </Flex>
        )}

        {is1112x767 && (
          <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            // height="20"
            height="7vh"
            alignItems="center"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            // borderBottomWidth="1px"
            // borderBottomColor={useColorModeValue("gray.200", "gray.700")}
            justifyContent={{ base: "space-between", md: "flex-end" }}
            // border="solid 1px"
            // {...rest}
            // w="80%"
            // boxShadow="sm"
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
            />
            <Spacer />
            <Text
              fontSize="xl"
              fontFamily="helvetica"
              color="#323b4b"
              align="center"
            >
              {title}
            </Text>
            <Spacer />

            <HStack w="10%">
              <Spacer />
              <Text
                fontFamily="helvetica neue"
                fontSize="sm"
                fontWeight="black"
                color="white"
              >
                Dashboard
              </Text>
            </HStack>

            <HStack w="70%">
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                // color="gray.800"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/cover"
              >
                Cover
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/introduction"
              >
                Introduction
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/automated-system"
              >
                Automated System
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                href="/dataviz/1/dashboard"
              >
                Dashboard
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/network-analysis"
              >
                Network Analysis
              </Link>
              <Spacer />
            </HStack>

            <HStack spacing={{ base: "0", md: "6" }} mr="5">
              <Menu>
                <MenuButton>
                  <IconButton
                    fontWeight="sm"
                    size="xs"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell color="white" />}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="Notifications">
                    <MenuItem icon={<FiMessageSquare />} fontSize="xs">
                      No new notifications
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton fontWeight="sm">
                    <HStack>
                      <Avatar size={"xs"} name="User" />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        {/* <Text fontSize="sm">{users}</Text> */}
                        <Text fontSize="xs" color="white">
                          Username
                        </Text>
                      </VStack>
                      <Spacer />
                      <FiChevronDown />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Navigate">
                      <MenuItem
                        icon={<FiLayout />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FiLogOut />}
                        fontSize="xs"
                        as={ReachLink}
                        to="/dataviz/1/cover"
                      >
                        Log Out
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Help">
                      <MenuItem icon={<FiBookOpen />} fontSize="xs">
                        <Link href="#">FAQ</Link>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </Flex>
        )}

        {is800x600 && (
          <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="7vh"
            alignItems="center"
            justifyContent={{ base: "space-between", md: "flex-end" }}
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
            />
            <Spacer />
            <Text
              fontSize="xl"
              fontFamily="helvetica"
              color="#323b4b"
              align="center"
            >
              {title}
            </Text>
            <Spacer />

            {/* <HStack w="100%"> */}

            {/* </HStack> */}

            {/* <HStack w="70%">
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                // color="gray.800"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="10px"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/cover"
              >
                Cover
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/introduction"
              >
                Introduction
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/automated-system"
              >
                Automated System
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                href="/dataviz/1/dashboard"
              >
                Dashboard
              </Link>
              <Spacer />
              <Link
                className="hover-4"
                p="10px"
                color="white"
                _hover={{
                  //   background: "white",
                  color: "gray.100",
                }}
                fontFamily="helvetica neue"
                fontSize="xs"
                fontStyle="normal"
                as={ReachLink}
                to="/dataviz/1/network-analysis"
              >
                Network Analysis
              </Link>
              <Spacer />
            </HStack> */}

            <HStack spacing={{ base: "0", md: "6" }} mr="5">
              <Text
                fontFamily="helvetica neue"
                fontSize="xs"
                fontWeight="black"
                color="white"
              >
                Dashboard
              </Text>
              <Menu>
                <MenuButton>
                  <IconButton
                    fontWeight="sm"
                    size="xs"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell color="white" />}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="Notifications">
                    <MenuItem icon={<FiMessageSquare />} fontSize="xs">
                      No new notifications
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton fontWeight="sm">
                    <HStack>
                      <Avatar size={"xs"} name="User" />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        {/* <Text fontSize="sm">{users}</Text> */}
                        <Text fontSize="xs" color="white">
                          Username
                        </Text>
                      </VStack>
                      <Spacer />
                      <FiChevronDown />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Navigate">
                      <MenuItem
                        icon={<FiBook />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/cover"
                      >
                        Cover
                      </MenuItem>
                      <MenuItem
                        icon={<FiDatabase />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/automated-system"
                      >
                        Automated System
                      </MenuItem>
                      <MenuItem
                        icon={<FiLayout />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FiCloud />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </MenuItem>
                      <MenuItem
                        icon={<FiLogOut />}
                        fontSize="xs"
                        as={ReachLink}
                        to="/dataviz/1/cover"
                      >
                        Log Out
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Help">
                      <MenuItem icon={<FiBookOpen />} fontSize="xs">
                        <Link href="#">FAQ</Link>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </Flex>
        )}

        {isIphone8Hor && (
          <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="7vh"
            alignItems="center"
            justifyContent={{ base: "space-between", md: "flex-end" }}
          >
            {/* <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
            /> */}
            <Spacer />
            <Text
              fontSize="xl"
              fontFamily="helvetica"
              color="#323b4b"
              align="center"
            >
              {title}
            </Text>
            <Spacer />

            <HStack spacing={{ base: "0", md: "6" }} mr="5">
              <Text
                fontFamily="helvetica neue"
                fontSize="xs"
                fontWeight="black"
                color="white"
              >
                Dashboard
              </Text>
              <Menu>
                <MenuButton>
                  <IconButton
                    fontWeight="sm"
                    size="xs"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell color="white" />}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="Notifications">
                    <MenuItem icon={<FiMessageSquare />} fontSize="xs">
                      No new notifications
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton fontWeight="sm">
                    <HStack>
                      <Avatar size={"xs"} name="User" />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        {/* <Text fontSize="sm">{users}</Text> */}
                        <Text fontSize="xs" color="white">
                          Username
                        </Text>
                      </VStack>
                      <Spacer />
                      <FiChevronDown />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Navigate">
                      <MenuItem
                        icon={<FiBook />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/cover"
                      >
                        Cover
                      </MenuItem>
                      <MenuItem
                        icon={<FiDatabase />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/automated-system"
                      >
                        Automated System
                      </MenuItem>
                      <MenuItem
                        icon={<FiLayout />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FiCloud />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </MenuItem>
                      <MenuItem
                        icon={<FiLogOut />}
                        fontSize="xs"
                        as={ReachLink}
                        to="/dataviz/1/cover"
                      >
                        Log Out
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Help">
                      <MenuItem icon={<FiBookOpen />} fontSize="xs">
                        <Link href="#">FAQ</Link>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </Flex>
        )}

        {isIphone8SmallHor && (
          <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="7vh"
            alignItems="center"
            justifyContent={{ base: "space-between", md: "flex-end" }}
          >
            {/* <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
            /> */}
            <Spacer />
            <Text
              fontSize="xl"
              fontFamily="helvetica"
              color="#323b4b"
              align="center"
            >
              {title}
            </Text>
            <Spacer />

            <HStack spacing={{ base: "0", md: "6" }} mr="5">
              <Text
                fontFamily="helvetica neue"
                fontSize="xs"
                fontWeight="black"
                color="white"
              >
                Dashboard
              </Text>
              <Menu>
                <MenuButton>
                  <IconButton
                    fontWeight="sm"
                    size="xs"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell color="white" />}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="Notifications">
                    <MenuItem icon={<FiMessageSquare />} fontSize="xs">
                      No new notifications
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton fontWeight="sm">
                    <HStack>
                      <Avatar size={"xs"} name="User" />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        {/* <Text fontSize="sm">{users}</Text> */}
                        <Text fontSize="xs" color="white">
                          Username
                        </Text>
                      </VStack>
                      <Spacer />
                      <FiChevronDown />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Navigate">
                      <MenuItem
                        icon={<FiBook />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/cover"
                      >
                        Cover
                      </MenuItem>
                      <MenuItem
                        icon={<FiDatabase />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/automated-system"
                      >
                        Automated System
                      </MenuItem>
                      <MenuItem
                        icon={<FiLayout />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FiCloud />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </MenuItem>
                      <MenuItem
                        icon={<FiLogOut />}
                        fontSize="xs"
                        as={ReachLink}
                        to="/dataviz/1/cover"
                      >
                        Log Out
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Help">
                      <MenuItem icon={<FiBookOpen />} fontSize="xs">
                        <Link href="#">FAQ</Link>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </Flex>
        )}

        {isIphoneSEHor && (
          <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="7vh"
            alignItems="center"
            justifyContent={{ base: "space-between", md: "flex-end" }}
          >
            {/* <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
            /> */}
            <Spacer />
            <Text
              fontSize="xl"
              fontFamily="helvetica"
              color="#323b4b"
              align="center"
            >
              {title}
            </Text>
            <Spacer />

            <HStack spacing={{ base: "0", md: "6" }} mr="5">
              <Text
                fontFamily="helvetica neue"
                fontSize="xs"
                fontWeight="black"
                color="white"
              >
                Dashboard
              </Text>
              <Menu>
                <MenuButton>
                  <IconButton
                    fontWeight="sm"
                    size="xs"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell color="white" />}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="Notifications">
                    <MenuItem icon={<FiMessageSquare />} fontSize="xs">
                      No new notifications
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton fontWeight="sm">
                    <HStack>
                      <Avatar size={"xs"} name="User" />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        {/* <Text fontSize="sm">{users}</Text> */}
                        <Text fontSize="xs" color="white">
                          Username
                        </Text>
                      </VStack>
                      <Spacer />
                      <FiChevronDown />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Navigate">
                      <MenuItem
                        icon={<FiBook />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/cover"
                      >
                        Cover
                      </MenuItem>
                      <MenuItem
                        icon={<FiDatabase />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/automated-system"
                      >
                        Automated System
                      </MenuItem>
                      <MenuItem
                        icon={<FiLayout />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FiCloud />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </MenuItem>
                      <MenuItem
                        icon={<FiLogOut />}
                        fontSize="xs"
                        as={ReachLink}
                        to="/dataviz/1/cover"
                      >
                        Log Out
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Help">
                      <MenuItem icon={<FiBookOpen />} fontSize="xs">
                        <Link href="#">FAQ</Link>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </Flex>
        )}

        {(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
          <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="7vh"
            alignItems="center"
            justifyContent={{ base: "space-between", md: "flex-end" }}
          >
            {/* <IconButton
              display={{ base: "flex", md: "none" }}
              onClick={onOpen}
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
            /> */}
            <Spacer />
            <Text
              fontSize="xl"
              fontFamily="helvetica"
              color="#323b4b"
              align="center"
            >
              {title}
            </Text>
            <Spacer />

            <HStack spacing={{ base: "0", md: "6" }} mr="5">
              <Text
                fontFamily="helvetica neue"
                fontSize="xs"
                fontWeight="black"
                color="white"
              >
                Dashboard
              </Text>
              <Menu>
                <MenuButton>
                  <IconButton
                    fontWeight="sm"
                    size="xs"
                    variant="ghost"
                    aria-label="open menu"
                    icon={<FiBell color="white" />}
                  />
                </MenuButton>
                <MenuList>
                  <MenuGroup title="Notifications">
                    <MenuItem icon={<FiMessageSquare />} fontSize="xs">
                      No new notifications
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>

              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton fontWeight="sm">
                    <HStack>
                      <Avatar size={"xs"} name="User" />
                      <VStack
                        display={{ base: "none", md: "flex" }}
                        alignItems="flex-start"
                        spacing="1px"
                        ml="2"
                      >
                        {/* <Text fontSize="sm">{users}</Text> */}
                        <Text fontSize="xs" color="white">
                          Username
                        </Text>
                      </VStack>
                      <Spacer />
                      <FiChevronDown />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuGroup title="Navigate">
                      <MenuItem
                        icon={<FiBook />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/cover"
                      >
                        Cover
                      </MenuItem>
                      <MenuItem
                        icon={<FiDatabase />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/automated-system"
                      >
                        Automated System
                      </MenuItem>
                      <MenuItem
                        icon={<FiLayout />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/dashboard"
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        icon={<FiCloud />}
                        fontSize="xs"
                        as="a"
                        href="/dataviz/1/network-analysis"
                      >
                        Network Analysis
                      </MenuItem>
                      <MenuItem
                        icon={<FiLogOut />}
                        fontSize="xs"
                        as={ReachLink}
                        to="/dataviz/1/cover"
                      >
                        Log Out
                      </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title="Help">
                      <MenuItem icon={<FiBookOpen />} fontSize="xs">
                        <Link href="#">FAQ</Link>
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </HStack>
          </Flex>
        )}
      </>
    );
  };

  const NavItem = ({
    icon,
    link,
    // selectedSidebar,
    children,
    ...rest
  }: NavItemProps) => {
    return (
      <>
        {is2016x863 && (
          <Link
            href={link}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            fontFamily="helvetica neue"
            fontWeight="light"
          >
            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{
                bg: "#355993",
                color: "white",
              }}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="16"
                  color="#355993"
                  _groupHover={{
                    color: "white",
                  }}
                  as={icon}
                />
              )}
              {children}
            </Flex>
          </Link>
        )}

        {is1112x767 && (
          <Link
            href={link}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            fontFamily="helvetica neue"
            fontWeight="light"
            fontSize="sm"
          >
            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{
                bg: "#355993",
                color: "white",
              }}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="sm"
                  color="#355993"
                  _groupHover={{
                    color: "white",
                  }}
                  as={icon}
                />
              )}
              {children}
            </Flex>
          </Link>
        )}
        {is800x600 && (
          <Link
            href={link}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            fontFamily="helvetica neue"
            fontWeight="light"
            fontSize="xs"
          >
            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{
                bg: "#355993",
                color: "white",
              }}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="xs"
                  color="#355993"
                  _groupHover={{
                    color: "white",
                  }}
                  as={icon}
                />
              )}
              {children}
            </Flex>
          </Link>
        )}

        {isIphone8Hor && (
          <Link
            href={link}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            fontFamily="helvetica neue"
            fontWeight="light"
            fontSize="xs"
          >
            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{
                bg: "#355993",
                color: "white",
              }}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="xs"
                  color="#355993"
                  _groupHover={{
                    color: "white",
                  }}
                  as={icon}
                />
              )}
              {children}
            </Flex>
          </Link>
        )}

        {isIphone8SmallHor && (
          <Link
            href={link}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            fontFamily="helvetica neue"
            fontWeight="light"
            fontSize="xs"
          >
            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{
                bg: "#355993",
                color: "white",
              }}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="xs"
                  color="#355993"
                  _groupHover={{
                    color: "white",
                  }}
                  as={icon}
                />
              )}
              {children}
            </Flex>
          </Link>
        )}

        {isIphoneSEHor && (
          <Link
            href={link}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            fontFamily="helvetica neue"
            fontWeight="light"
            fontSize="xs"
          >
            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{
                bg: "#355993",
                color: "white",
              }}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="xs"
                  color="#355993"
                  _groupHover={{
                    color: "white",
                  }}
                  as={icon}
                />
              )}
              {children}
            </Flex>
          </Link>
        )}

        {(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
          <Link
            href={link}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            fontFamily="helvetica neue"
            fontWeight="light"
            fontSize="xs"
          >
            <Flex
              align="center"
              p="4"
              mx="4"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              _hover={{
                bg: "#355993",
                color: "white",
              }}
            >
              {icon && (
                <Icon
                  mr="4"
                  fontSize="xs"
                  color="#355993"
                  _groupHover={{
                    color: "white",
                  }}
                  as={icon}
                />
              )}
              {children}
            </Flex>
          </Link>
        )}
      </>
    );
  };

  const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
      <>
        {is2016x863 && (
          <Box
            transition="3s ease"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            bg="white"
            // borderRight="1px"
            // borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", md: 60 }}
            pos="fixed"
            h="95vh"
            // h="full"
            top="2vh"
            left="1vw"
            {...rest}
            overflow="scroll"
            pb="3em"
            // border="solid 1px"
            borderRadius="20"
            boxShadow="md"
          >
            <Center pt="5" pb="5">
              <HStack>
                <Link href="#" style={{ textDecoration: "none" }}>
                  <HStack>
                    {/* <Img
                  boxSize="35px"
                  objectFit="contain"
                  //   src={require("./images/logo.png")}
                /> */}
                    <Text
                      fontSize="2xl"
                      fontFamily="helvetica neue"
                      color="#323b4b"
                      pt="1rem"
                      pb="1rem"
                    >
                      IA Analytics
                    </Text>
                  </HStack>
                </Link>
                <CloseButton
                  display={{ base: "flex", md: "none" }}
                  onClick={onClose}
                />
              </HStack>
            </Center>

            <VStack align="left" overflow="scroll">
              <Text pl="8" pt="5" pb="2" color="#383839">
                MY WORKSPACE
              </Text>

              <Link
                href="/dataviz/1/dashboard"
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                fontFamily="helvetica neue"
                fontWeight="light"
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg="#355993"
                  color="white"
                  _hover={{
                    bg: "#355993",
                    color: "white",
                  }}
                >
                  <Icon
                    mr="4"
                    fontSize="16"
                    _groupHover={{
                      color: "white",
                    }}
                    as={FiMonitor}
                  />
                  Dashboard
                </Flex>
              </Link>

              <NavItem key="Data" icon={FiColumns} link="#" color="gray.200">
                Data
              </NavItem>

              <NavItem
                key="Schedule"
                icon={FiCalendar}
                link="#"
                color="#78797a"
              >
                Schedule
              </NavItem>

              <NavItem key="Reports" icon={FiBookOpen} link="#" color="#78797a">
                Audit Reports
              </NavItem>

              <NavItem
                key="Settings"
                icon={FiSettings}
                link="#"
                color="#78797a"
              >
                Settings
              </NavItem>

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839">
                ACCOUNT
              </Text>

              {LinkItemsAnalytics.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839">
                RESOURCES
              </Text>

              {LinkItemsResources.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </Box>
        )}

        {is1112x767 && (
          <Box
            transition="3s ease"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            bg="white"
            // borderRight="1px"
            // borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", md: 60 }}
            pos="fixed"
            h="95vh"
            // h="full"
            top="2vh"
            left="1vw"
            {...rest}
            overflow="scroll"
            pb="3em"
            // border="solid 1px"
            borderRadius="20"
            boxShadow="md"
          >
            <Center pt="5" pb="5">
              <HStack>
                <Link href="#" style={{ textDecoration: "none" }}>
                  <HStack>
                    {/* <Img
                  boxSize="35px"
                  objectFit="contain"
                  //   src={require("./images/logo.png")}
                /> */}
                    <Text
                      fontSize="xl"
                      fontFamily="helvetica neue"
                      color="#323b4b"
                      pt="1rem"
                      pb="1rem"
                    >
                      IA Analytics
                    </Text>
                  </HStack>
                </Link>
                <CloseButton
                  display={{ base: "flex", md: "none" }}
                  onClick={onClose}
                />
              </HStack>
            </Center>

            <VStack align="left" overflow="scroll">
              <Text pl="8" pt="5" pb="2" color="#383839" fontSize="sm">
                MY WORKSPACE
              </Text>

              <Link
                href="/dataviz/1/dashboard"
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                fontFamily="helvetica neue"
                fontWeight="light"
                fontSize="sm"
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg="#355993"
                  color="white"
                  _hover={{
                    bg: "#355993",
                    color: "white",
                  }}
                >
                  <Icon
                    mr="4"
                    fontSize="sm"
                    _groupHover={{
                      color: "white",
                    }}
                    as={FiMonitor}
                  />
                  Dashboard
                </Flex>
              </Link>

              <NavItem key="Data" icon={FiColumns} link="#" color="gray.200">
                Data
              </NavItem>

              <NavItem
                key="Schedule"
                icon={FiCalendar}
                link="#"
                color="#78797a"
              >
                Schedule
              </NavItem>

              <NavItem key="Reports" icon={FiBookOpen} link="#" color="#78797a">
                Audit Reports
              </NavItem>

              <NavItem
                key="Settings"
                icon={FiSettings}
                link="#"
                color="#78797a"
              >
                Settings
              </NavItem>

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="sm">
                ACCOUNT
              </Text>

              {LinkItemsAnalytics.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="sm">
                RESOURCES
              </Text>

              {LinkItemsResources.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </Box>
        )}

        {is800x600 && (
          <Box
            transition="3s ease"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            bg="white"
            // borderRight="1px"
            // borderRightColor={useColorModeValue("gray.200", "gray.700")}
            // w={{ base: "full", md: 60 }}
            pos="fixed"
            h="95vh"
            // h="full"
            top="2vh"
            left="1vw"
            {...rest}
            overflow="scroll"
            pb="3em"
            // border="solid 1px"
            borderRadius="20"
            boxShadow="md"
          >
            <Center pt="5" pb="5">
              <HStack>
                <Link href="#" style={{ textDecoration: "none" }}>
                  <HStack>
                    {/* <Img
                  boxSize="35px"
                  objectFit="contain"
                  //   src={require("./images/logo.png")}
                /> */}
                    <Text
                      fontSize="lg"
                      fontFamily="helvetica neue"
                      color="#323b4b"
                      pt="1rem"
                      //   pb="1rem"
                    >
                      IA Analytics
                    </Text>
                  </HStack>
                </Link>
                <CloseButton
                  display={{ base: "flex", md: "none" }}
                  onClick={onClose}
                />
              </HStack>
            </Center>

            <VStack align="left" overflow="scroll">
              <Text pl="8" pt="5" pb="2" color="#383839" fontSize="xs">
                MY WORKSPACE
              </Text>

              <Link
                href="/dataviz/1/dashboard"
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                fontFamily="helvetica neue"
                fontWeight="light"
                fontSize="xs"
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg="#355993"
                  color="white"
                  _hover={{
                    bg: "#355993",
                    color: "white",
                  }}
                >
                  <Icon
                    mr="4"
                    fontSize="xs"
                    _groupHover={{
                      color: "white",
                    }}
                    as={FiMonitor}
                  />
                  Dashboard
                </Flex>
              </Link>

              <NavItem key="Data" icon={FiColumns} link="#" color="gray.200">
                Data
              </NavItem>

              <NavItem
                key="Schedule"
                icon={FiCalendar}
                link="#"
                color="#78797a"
              >
                Schedule
              </NavItem>

              <NavItem key="Reports" icon={FiBookOpen} link="#" color="#78797a">
                Audit Reports
              </NavItem>

              <NavItem
                key="Settings"
                icon={FiSettings}
                link="#"
                color="#78797a"
              >
                Settings
              </NavItem>

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                ACCOUNT
              </Text>

              {LinkItemsAnalytics.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                RESOURCES
              </Text>

              {LinkItemsResources.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </Box>
        )}

        {isIphone8Hor && (
          <Box
            transition="3s ease"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            bg="white"
            // borderRight="1px"
            // borderRightColor={useColorModeValue("gray.200", "gray.700")}
            // w={{ base: "full", md: 60 }}
            pos="fixed"
            h="95vh"
            // h="full"
            top="2vh"
            left="1vw"
            {...rest}
            overflow="scroll"
            pb="3em"
            // border="solid 1px"
            borderRadius="20"
            boxShadow="md"
            // border="solid 1px"
          >
            <Center pt="5" pb="5">
              <HStack>
                <Link href="#" style={{ textDecoration: "none" }}>
                  <HStack>
                    {/* <Img
                  boxSize="35px"
                  objectFit="contain"
                  //   src={require("./images/logo.png")}
                /> */}
                    <Text
                      fontSize="md"
                      fontFamily="helvetica neue"
                      color="#323b4b"
                      //   pt="1rem"
                      //   pb="1rem"
                    >
                      IA Analytics
                    </Text>
                  </HStack>
                </Link>
                {/* <CloseButton
                  display={{ base: "flex", md: "none" }}
                  onClick={onClose}
                /> */}
              </HStack>
            </Center>

            <VStack align="left" overflow="scroll">
              <Text pl="8" pt="2" pb="2" color="#383839" fontSize="xs">
                MY WORKSPACE
              </Text>

              <Link
                href="/dataviz/1/dashboard"
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                fontFamily="helvetica neue"
                fontWeight="light"
                fontSize="xs"
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg="#355993"
                  color="white"
                  _hover={{
                    bg: "#355993",
                    color: "white",
                  }}
                >
                  <Icon
                    mr="4"
                    fontSize="xs"
                    _groupHover={{
                      color: "white",
                    }}
                    as={FiMonitor}
                  />
                  Dashboard
                </Flex>
              </Link>

              <NavItem key="Data" icon={FiColumns} link="#" color="gray.200">
                Data
              </NavItem>

              <NavItem
                key="Schedule"
                icon={FiCalendar}
                link="#"
                color="#78797a"
              >
                Schedule
              </NavItem>

              <NavItem key="Reports" icon={FiBookOpen} link="#" color="#78797a">
                Audit Reports
              </NavItem>

              <NavItem
                key="Settings"
                icon={FiSettings}
                link="#"
                color="#78797a"
              >
                Settings
              </NavItem>

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                ACCOUNT
              </Text>

              {LinkItemsAnalytics.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                RESOURCES
              </Text>

              {LinkItemsResources.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </Box>
        )}

        {isIphone8SmallHor && (
          <Box
            transition="3s ease"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            bg="white"
            // borderRight="1px"
            // borderRightColor={useColorModeValue("gray.200", "gray.700")}
            // w={{ base: "full", md: 60 }}
            pos="fixed"
            h="95vh"
            // h="full"
            top="2vh"
            left="1vw"
            {...rest}
            overflow="scroll"
            pb="3em"
            // border="solid 1px"
            borderRadius="20"
            boxShadow="md"
            // border="solid 1px"
          >
            <Center pt="5" pb="5">
              <HStack>
                <Link href="#" style={{ textDecoration: "none" }}>
                  <HStack>
                    {/* <Img
                  boxSize="35px"
                  objectFit="contain"
                  //   src={require("./images/logo.png")}
                /> */}
                    <Text
                      fontSize="md"
                      fontFamily="helvetica neue"
                      color="#323b4b"
                      //   pt="1rem"
                      //   pb="1rem"
                    >
                      IA Analytics
                    </Text>
                  </HStack>
                </Link>
                {/* <CloseButton
                  display={{ base: "flex", md: "none" }}
                  onClick={onClose}
                /> */}
              </HStack>
            </Center>

            <VStack align="left" overflow="scroll">
              <Text pl="8" pt="2" pb="2" color="#383839" fontSize="xs">
                MY WORKSPACE
              </Text>

              <Link
                href="/dataviz/1/dashboard"
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                fontFamily="helvetica neue"
                fontWeight="light"
                fontSize="xs"
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg="#355993"
                  color="white"
                  _hover={{
                    bg: "#355993",
                    color: "white",
                  }}
                >
                  <Icon
                    mr="4"
                    fontSize="xs"
                    _groupHover={{
                      color: "white",
                    }}
                    as={FiMonitor}
                  />
                  Dashboard
                </Flex>
              </Link>

              <NavItem key="Data" icon={FiColumns} link="#" color="gray.200">
                Data
              </NavItem>

              <NavItem
                key="Schedule"
                icon={FiCalendar}
                link="#"
                color="#78797a"
              >
                Schedule
              </NavItem>

              <NavItem key="Reports" icon={FiBookOpen} link="#" color="#78797a">
                Audit Reports
              </NavItem>

              <NavItem
                key="Settings"
                icon={FiSettings}
                link="#"
                color="#78797a"
              >
                Settings
              </NavItem>

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                ACCOUNT
              </Text>

              {LinkItemsAnalytics.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                RESOURCES
              </Text>

              {LinkItemsResources.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </Box>
        )}

        {isIphoneSEHor && (
          <Box
            transition="3s ease"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            bg="white"
            // borderRight="1px"
            // borderRightColor={useColorModeValue("gray.200", "gray.700")}
            // w={{ base: "full", md: 60 }}
            pos="fixed"
            h="95vh"
            // h="full"
            top="2vh"
            left="1vw"
            {...rest}
            overflow="scroll"
            pb="3em"
            // border="solid 1px"
            borderRadius="20"
            boxShadow="md"
            // border="solid 1px"
          >
            <Center pt="5" pb="5">
              <HStack>
                <Link href="#" style={{ textDecoration: "none" }}>
                  <HStack>
                    {/* <Img
                  boxSize="35px"
                  objectFit="contain"
                  //   src={require("./images/logo.png")}
                /> */}
                    <Text
                      fontSize="md"
                      fontFamily="helvetica neue"
                      color="#323b4b"
                      //   pt="1rem"
                      //   pb="1rem"
                    >
                      IA Analytics
                    </Text>
                  </HStack>
                </Link>
                {/* <CloseButton
                  display={{ base: "flex", md: "none" }}
                  onClick={onClose}
                /> */}
              </HStack>
            </Center>

            <VStack align="left" overflow="scroll">
              <Text pl="8" pt="2" pb="2" color="#383839" fontSize="xs">
                MY WORKSPACE
              </Text>

              <Link
                href="/dataviz/1/dashboard"
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                fontFamily="helvetica neue"
                fontWeight="light"
                fontSize="xs"
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg="#355993"
                  color="white"
                  _hover={{
                    bg: "#355993",
                    color: "white",
                  }}
                >
                  <Icon
                    mr="4"
                    fontSize="xs"
                    _groupHover={{
                      color: "white",
                    }}
                    as={FiMonitor}
                  />
                  Dashboard
                </Flex>
              </Link>

              <NavItem key="Data" icon={FiColumns} link="#" color="gray.200">
                Data
              </NavItem>

              <NavItem
                key="Schedule"
                icon={FiCalendar}
                link="#"
                color="#78797a"
              >
                Schedule
              </NavItem>

              <NavItem key="Reports" icon={FiBookOpen} link="#" color="#78797a">
                Audit Reports
              </NavItem>

              <NavItem
                key="Settings"
                icon={FiSettings}
                link="#"
                color="#78797a"
              >
                Settings
              </NavItem>

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                ACCOUNT
              </Text>

              {LinkItemsAnalytics.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                RESOURCES
              </Text>

              {LinkItemsResources.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </Box>
        )}

        {(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
          <Box
            transition="3s ease"
            // bg={useColorModeValue("#FFFFFF", "gray.900")}
            bg="white"
            // borderRight="1px"
            // borderRightColor={useColorModeValue("gray.200", "gray.700")}
            // w={{ base: "full", md: 60 }}
            pos="fixed"
            h="95vh"
            // h="full"
            top="2vh"
            left="1vw"
            {...rest}
            overflow="scroll"
            pb="3em"
            // border="solid 1px"
            borderRadius="20"
            boxShadow="md"
            // border="solid 1px"
          >
            <Center pt="5" pb="5">
              <HStack>
                <Link href="#" style={{ textDecoration: "none" }}>
                  <HStack>
                    {/* <Img
                  boxSize="35px"
                  objectFit="contain"
                  //   src={require("./images/logo.png")}
                /> */}
                    <Text
                      fontSize="md"
                      fontFamily="helvetica neue"
                      color="#323b4b"
                      //   pt="1rem"
                      //   pb="1rem"
                    >
                      IA Analytics
                    </Text>
                  </HStack>
                </Link>
                {/* <CloseButton
                  display={{ base: "flex", md: "none" }}
                  onClick={onClose}
                /> */}
              </HStack>
            </Center>

            <VStack align="left" overflow="scroll">
              <Text pl="8" pt="2" pb="2" color="#383839" fontSize="xs">
                MY WORKSPACE
              </Text>

              <Link
                href="/dataviz/1/dashboard"
                style={{ textDecoration: "none" }}
                _focus={{ boxShadow: "none" }}
                fontFamily="helvetica neue"
                fontWeight="light"
                fontSize="xs"
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  bg="#355993"
                  color="white"
                  _hover={{
                    bg: "#355993",
                    color: "white",
                  }}
                >
                  <Icon
                    mr="4"
                    fontSize="xs"
                    _groupHover={{
                      color: "white",
                    }}
                    as={FiMonitor}
                  />
                  Dashboard
                </Flex>
              </Link>

              <NavItem key="Data" icon={FiColumns} link="#" color="gray.200">
                Data
              </NavItem>

              <NavItem
                key="Schedule"
                icon={FiCalendar}
                link="#"
                color="#78797a"
              >
                Schedule
              </NavItem>

              <NavItem key="Reports" icon={FiBookOpen} link="#" color="#78797a">
                Audit Reports
              </NavItem>

              <NavItem
                key="Settings"
                icon={FiSettings}
                link="#"
                color="#78797a"
              >
                Settings
              </NavItem>

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                ACCOUNT
              </Text>

              {LinkItemsAnalytics.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}

              <Spacer />
              <Box></Box>
              <Spacer />

              <Text pl="8" pb="2" color="#383839" fontSize="xs">
                RESOURCES
              </Text>

              {LinkItemsResources.map((link) => (
                <NavItem
                  key={link.name}
                  icon={link.icon}
                  link={link.link}
                  //   selectedSidebar={link.selectedSidebar}
                  color="#78797a"
                >
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </Box>
        )}
      </>
    );
  };

  return (
    // <Box minH="100vh" bg={useColorModeValue("#fbfaf8", "gray.900")}>
    <Box minH="100vh" className="bg">
      {/* <div className="bg" /> */}
      {!isIphone8Hor && (
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          zIndex="999"
        />
      )}

      {isIphone8Hor && (
        <SidebarContent
          onClose={() => null}
          //   display={{ base: "none", md: "block" }}
          zIndex="999"
        />
      )}

      {isIphone8SmallHor && (
        <SidebarContent
          onClose={() => null}
          //   display={{ base: "none", md: "block" }}
          zIndex="999"
        />
      )}

      {isIphoneSEHor && (
        <SidebarContent
          onClose={() => null}
          //   display={{ base: "none", md: "block" }}
          zIndex="999"
        />
      )}

      {(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
        <SidebarContent
          onClose={() => null}
          //   display={{ base: "none", md: "block" }}
          zIndex="999"
        />
      )}

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />

      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
      {/* </div> */}
    </Box>
  );
};

export default UsersNav;

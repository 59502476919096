/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, MeshDistortMaterial, Clone, Html, useProgress } from '@react-three/drei'
import {Button, Text, Box, Spacer, Center, Link} from "@chakra-ui/react"
import { FiGithub, FiLinkedin, FiMail, FiCornerDownLeft } from "react-icons/fi";
import { GiCampfire } from "react-icons/gi";
import { RiArticleFill, RiBarChartBoxFill } from "react-icons/ri"
import "./styles.css"
import { useMediaQuery } from "react-responsive";
import { Link as ReachLink } from "react-router-dom"


export function Camping(props) {

  const is1112x767 = useMediaQuery({
    minWidth: 1111,
  });

  const is1024x768 = useMediaQuery({
    maxWidth: 1111,
    minWidth: 1023,
  });

  const is800x600 = useMediaQuery({
    maxWidth: 1023,
    minWidth: 799,
  });

  const isIphone8Hor = useMediaQuery({
    maxWidth: 799,
    minWidth: 735,
  });

  const isIphone8SmallHor = useMediaQuery({
    maxWidth: 735,
    minWidth: 666,
  });

  const isIphoneSEHor = useMediaQuery({
    maxWidth: 666,
    minWidth: 567,
  });

  const isIphone8Vert = useMediaQuery({
    maxWidth: 567,
    minWidth: 413,
  });

  const isIphone8SmallVert = useMediaQuery({
    maxWidth: 413,
    minWidth: 374,
  });

  const isIphoneSEVert = useMediaQuery({
    maxWidth: 374,
    minWidth: 319,
  });


  // const is1112x767 = useMediaQuery({
  //   minWidth: 1111,
  // });

  // const is1024x768 = useMediaQuery({
  //   maxWidth: 1111,
  //   minWidth: 1023,
  // });

  // const is800x600 = useMediaQuery({
  //   maxWidth: 1023,
  //   minWidth: 799,
  // });

  // const isIphone8Hor = useMediaQuery({
  //   maxWidth: 799,
  //   minWidth: 735,
  // });

  // const isIphone8SmallHor = useMediaQuery({
  //   maxWidth: 735,
  //   minWidth: 666,
  //   minHeight: 413,
  //   maxHeight: 567,
  // });

  // const isIphoneSEHor = useMediaQuery({
  //   maxWidth: 666,
  //   minWidth: 567,
  //   minHeight: 319,
  //   maxHeight: 413,
  // });

  // const isIphone8Vert = useMediaQuery({
  //   maxWidth: 567,
  //   minWidth: 413,
  //   minHeight: 735,
  // });

  // const isIphone8SmallVert = useMediaQuery({
  //   maxWidth: 413,
  //   minWidth: 374,
  //   minHeight: 666,
  //   maxHeight: 735,
  // });

  // const isIphoneSEVert = useMediaQuery({
  //   maxWidth: 374,
  //   minWidth: 319,
  //   minHeight: 567,
  //   maxHeight: 666,
  // });

  // const { nodes, materials } = useGLTF('/camping14.glb')
  const { nodes, materials } = useGLTF('https://samtwl.s3.ap-southeast-1.amazonaws.com/camping14.glb')
  const [hovered, setHovered] = React.useState(false)

  const {showAboutMe, setShowAboutMe, clickedProjects, setClickedProjects, clickedFire, setClickedFire, clickedAboutMe, setClickedAboutMe, clickedDataViz, setClickedDataViz} = props

  React.useEffect(() => {
  document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  return (
    <group {...props} dispose={null}>

      <group position={[0, 7.14, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[0.01, -0.07, -6.43]} rotation={[0, 0, -1.69]} scale={0.06}>
          <mesh geometry={nodes.Object_0.geometry} material={materials['Material.099']} />
          <lineSegments geometry={nodes.Object_0_1.geometry} material={materials['Material.099']} />
        </group>
      </group>


      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.096']} position={[-3.31, 1.97, 3.96]} scale={0.03} />
      <mesh geometry={nodes.Plane.geometry} material={materials['Material.001']} rotation={[-Math.PI, 0, -Math.PI]} scale={[8.94, 5.99, 8.65]} />
      <lineSegments geometry={nodes.grass1.geometry} material={materials['Material.001']} />
      
      <mesh geometry={nodes.Plane001.geometry} material={materials['Material.005']} position={[3.83, 0.19, -2.86]} rotation={[0, 0, -2.02]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane002.geometry} material={materials['Material.012']} position={[3.8, 0.2, -2.84]} rotation={[-Math.PI, 0, -2.01]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane003.geometry} material={materials['Material.020']} position={[3.82, 0.19, -2.86]} rotation={[0, -1.31, -2.02]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane004.geometry} material={materials['Material.031']} position={[3.78, 0.21, -2.84]} rotation={[-Math.PI, -0.07, 1.13]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane005.geometry} material={materials['Material.032']} position={[3.81, 0.19, -2.86]} rotation={[0, 1.31, 1.13]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane006.geometry} material={materials['Material.038']} position={[3.82, 0.2, -2.88]} rotation={[0.4, 0.64, -2.42]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane007.geometry} material={materials['Material.050']} position={[3.81, 0.2, -2.84]} rotation={[-2.74, -0.64, -1.61]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane008.geometry} material={materials['Material.056']} position={[3.81, 0.2, -2.87]} rotation={[-0.08, -0.59, -2.41]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane009.geometry} material={materials['Material.066']} position={[3.81, 0.21, -2.83]} rotation={[-2.71, -0.71, 1.56]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane010.geometry} material={materials['Material.049']} position={[3.81, 0.19, -2.86]} rotation={[2.66, 1.05, -1.32]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane011.geometry} material={materials['Material.006']} position={[3.73, 0.21, -2.83]} rotation={[0, -1.35, -2.02]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane012.geometry} material={materials['Material.013']} position={[3.7, 0.22, -2.86]} rotation={[-Math.PI, 1.35, -2.01]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane013.geometry} material={materials['Material.021']} position={[3.72, 0.21, -2.84]} rotation={[-Math.PI, -0.48, 1.13]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane014.geometry} material={materials['Material.030']} position={[3.69, 0.23, -2.87]} rotation={[Math.PI, 1.28, 1.13]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane015.geometry} material={materials['Material.033']} position={[3.72, 0.21, -2.85]} rotation={[0, -0.05, 1.13]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane016.geometry} material={materials['Material.039']} position={[3.72, 0.14, -2.92]} rotation={[Math.PI, 1.14, 1.13]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane017.geometry} material={materials['Material.051']} position={[3.77, 0.16, -2.88]} rotation={[0, -1.07, -2.02]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane018.geometry} material={materials['Material.057']} position={[3.77, 0.16, -2.91]} rotation={[-0.35, -0.36, -2.01]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane019.geometry} material={materials['Material.065']} position={[3.85, 0.17, -2.9]} rotation={[2.88, -0.89, 1.13]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane020.geometry} material={materials['Material.048']} position={[3.8, 0.19, -2.92]} rotation={[2.88, 0.88, 1.13]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane021.geometry} material={materials['Material.007']} position={[3.83, 0.17, -2.9]} rotation={[-0.26, 0.36, 1.13]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane022.geometry} material={materials['Material.014']} position={[3.94, 0.14, -2.76]} rotation={[0, 0, -2.33]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane023.geometry} material={materials['Material.003']} position={[3.91, 0.15, -2.74]} rotation={[-Math.PI, 0, -1.7]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane024.geometry} material={materials['Material.029']} position={[3.93, 0.14, -2.76]} rotation={[-0.85, -1.16, -2.91]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane025.geometry} material={materials['Material.034']} position={[3.9, 0.17, -2.74]} rotation={[-3.12, -0.07, 1.44]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane026.geometry} material={materials['Material.040']} position={[3.92, 0.14, -2.76]} rotation={[0.85, 1.16, 0.24]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane027.geometry} material={materials['Material.052']} position={[3.93, 0.14, -2.78]} rotation={[0.57, 0.49, -2.75]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane028.geometry} material={materials['Material.058']} position={[3.92, 0.15, -2.74]} rotation={[-2.57, -0.49, -1.28]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane029.geometry} material={materials['Material.064']} position={[3.92, 0.15, -2.77]} rotation={[-0.28, -0.53, -2.77]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane030.geometry} material={materials['Material.047']} position={[3.92, 0.16, -2.73]} rotation={[-2.51, -0.55, 1.89]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane031.geometry} material={materials['Material.008']} position={[3.92, 0.15, -2.76]} rotation={[2.31, 0.86, -0.9]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane032.geometry} material={materials['Material.015']} position={[3.84, 0.19, -2.73]} rotation={[-0.95, -1.19, -3]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane033.geometry} material={materials['Material.022']} position={[3.82, 0.2, -2.76]} rotation={[2.19, 1.19, -1.03]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane034.geometry} material={materials['Material.028']} position={[3.84, 0.19, -2.74]} rotation={[-2.98, -0.46, 1.47]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane035.geometry} material={materials['Material.035']} position={[3.81, 0.22, -2.77]} rotation={[2.34, 1.15, 1.97]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane036.geometry} material={materials['Material.041']} position={[3.83, 0.19, -2.75]} rotation={[-0.01, -0.05, 0.81]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane037.geometry} material={materials['Material.053']} position={[3.82, 0.12, -2.82]} rotation={[2.55, 1.04, 1.78]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane038.geometry} material={materials['Material.059']} position={[3.87, 0.12, -2.78]} rotation={[-0.51, -0.98, -2.6]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane039.geometry} material={materials['Material.063']} position={[3.87, 0.12, -2.81]} rotation={[-0.44, -0.24, -2.31]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane040.geometry} material={materials['Material.046']} position={[3.95, 0.12, -2.8]} rotation={[-3.01, -0.91, 1.63]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane041.geometry} material={materials['Material.009']} position={[3.91, 0.14, -2.82]} rotation={[2.57, 0.75, 1.54]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane042.geometry} material={materials['Material.016']} position={[3.93, 0.11, -2.8]} rotation={[-0.14, 0.42, 0.79]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane043.geometry} material={materials['Material.023']} position={[3.82, 0.23, -2.71]} rotation={[0, 0, -1.79]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane044.geometry} material={materials['Material.027']} position={[3.79, 0.23, -2.69]} rotation={[-Math.PI, 0, -2.24]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane045.geometry} material={materials['Material.036']} position={[3.81, 0.23, -2.71]} rotation={[0.7, -1.22, -1.29]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane046.geometry} material={materials['Material.042']} position={[3.77, 0.24, -2.69]} rotation={[3.12, -0.07, 0.9]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane047.geometry} material={materials['Material.054']} position={[3.8, 0.22, -2.71]} rotation={[-0.7, 1.22, 1.85]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane048.geometry} material={materials['Material.004']} position={[3.81, 0.23, -2.73]} rotation={[0.22, 0.71, -2.14]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane049.geometry} material={materials['Material.062']} position={[3.8, 0.23, -2.69]} rotation={[-2.92, -0.71, -1.89]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane050.geometry} material={materials['Material.045']} position={[3.8, 0.23, -2.72]} rotation={[0.07, -0.59, -2.13]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane051.geometry} material={materials['Material.010']} position={[3.79, 0.24, -2.67]} rotation={[-2.91, -0.78, 1.26]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane052.geometry} material={materials['Material.017']} position={[3.8, 0.22, -2.71]} rotation={[3.08, 1.11, -1.8]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane053.geometry} material={materials['Material.024']} position={[3.71, 0.22, -2.68]} rotation={[0.8, -1.26, -1.19]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane054.geometry} material={materials['Material.026']} position={[3.68, 0.22, -2.71]} rotation={[-2.34, 1.26, -2.84]} scale={[-0.7, -0.21, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane055.geometry} material={materials['Material.037']} position={[3.71, 0.22, -2.69]} rotation={[3.02, -0.47, 0.87]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane056.geometry} material={materials['Material.043']} position={[3.67, 0.23, -2.72]} rotation={[-2.49, 1.2, 0.44]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane057.geometry} material={materials['Material.055']} position={[3.7, 0.22, -2.7]} rotation={[0.01, -0.05, 1.36]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane058.geometry} material={materials['Material.060']} position={[3.73, 0.15, -2.77]} rotation={[-2.68, 1.09, 0.62]} scale={[0.7, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane059.geometry} material={materials['Material.061']} position={[3.77, 0.18, -2.73]} rotation={[0.39, -1.02, -1.57]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane060.geometry} material={materials['Material.044']} position={[3.77, 0.18, -2.76]} rotation={[-0.26, -0.43, -1.77]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane061.geometry} material={materials['Material.011']} position={[3.84, 0.21, -2.75]} rotation={[2.64, -0.8, 0.81]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane062.geometry} material={materials['Material.018']} position={[3.79, 0.22, -2.77]} rotation={[-3.12, 0.9, 0.76]} scale={[0.47, 0.21, 0.21]}><MeshDistortMaterial speed={2.5} distort={0.55} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Plane063.geometry} material={materials['Material.025']} position={[3.83, 0.21, -2.75]} rotation={[-0.34, 0.29, 1.36]} scale={[-0.33, -0.2, -0.21]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.004, 0.07, 0.047]} roughness={0.3} /></mesh>
      
      {/* <mesh geometry={nodes.Plane268.geometry} material={materials['Material.079']} position={[1.28, 1.16, 0]} rotation={[0, 0, -0.93]} scale={[1.37, 1, 1.91]} ref={markerRef} onClick={() => setClicked(true)} onPointerOver={() => setHovered(true)}
    onPointerOut={() => setHovered(false)}/> */}

<mesh geometry={nodes.Plane268.geometry} material={materials['Material.079']} position={[1.28, 1.16, 0]} rotation={[0, 0, -0.93]} scale={[1.37, 1, 1.91]} />

      <mesh geometry={nodes.Plane269.geometry} material={materials['Material.078']} position={[-0.35, 1.16, 0]} rotation={[-Math.PI, 0, -0.93]} scale={[-1.37, -1, -1.91]} />
      <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.086']} position={[-1.53, 0.13, -2.31]} scale={[0.03, 0.12, 0.02]} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material.087']} position={[2.43, 0.13, -2.14]} scale={[0.03, 0.12, 0.02]} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials['Material.085']} position={[2.43, 0.13, 2.27]} scale={[0.03, 0.12, 0.02]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials['Material.088']} position={[-1.48, 0.26, 2.27]} scale={[0.03, 0.17, 0.02]} />
      <mesh geometry={nodes.Cylinder005.geometry} material={materials['Material.084']} position={[0.5, 1.11, -1.87]} scale={[0.03, 1.09, 0.02]} />
      <mesh geometry={nodes.Cylinder006.geometry} material={materials['Material.083']} position={[0.43, 1.09, 1.88]} scale={[0.03, 1.15, 0.02]} />
      <mesh geometry={nodes.Plane270.geometry} material={materials['Material.080']} position={[-0.27, 1.31, 1.94]} rotation={[1.56, 0, 0]} scale={[0.72, 1, 0.97]} />
      <mesh geometry={nodes.Plane271.geometry} material={materials['Material.081']} position={[-0.2, 1.25, -1.9]} rotation={[1.56, 0, 0]} scale={[0.72, 1, 0.97]} />
      <mesh geometry={nodes.Plane272.geometry} material={materials['Material.082']} position={[1.12, 1.25, -1.9]} rotation={[-1.58, 0, 0]} scale={[-0.72, -1, -0.97]} />
      <mesh geometry={nodes.Cube.geometry} material={materials['Material.089']} position={[-0.08, 0.52, -0.14]} scale={[0.37, 0.06, 1.24]} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.100']} position={[-5.21, 0.73, 4.72]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.17, 0.1, 0.2]} />
      <mesh geometry={nodes.Cube001.geometry} material={materials['Material.093']} position={[-4.67, 1.85, 2.98]} rotation={[0, 0.26, 0]} scale={[1, 1, 2.24]} />
      
      <group position={[-4.64, 2.84, 3.13]} rotation={[0, 0.27, 0]} scale={[0.89, 0.1, 1.9]}>
        <mesh geometry={nodes.Cube002.geometry} material={materials['Material.097']}>

          {!clickedProjects && !clickedFire && !clickedAboutMe && (<Html rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[-1, 5, 0]} >
            <Box className="boxAboutMe" onClick={() => { setClickedAboutMe(true)}} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
              <Text className="textheadAboutMe">About Me</Text>
            </Box>          
          </Html>
          )}
        </mesh>
      </group>

      <mesh geometry={nodes.Cube003.geometry} material={nodes.Cube003.material} position={[-4.9, 2.93, 2.2]} rotation={[0, 0.27, 0]} scale={[0.5, 0.1, 0.5]} />
      <mesh geometry={nodes.Cube004.geometry} material={nodes.Cube004.material} position={[-4.34, 2.93, 4.27]} rotation={[0, 0.27, 0]} scale={[0.28, 0.09, 0.28]} />
      <mesh geometry={nodes.Cube005.geometry} material={materials['Material.090']} position={[-5.86, 1.95, 2.08]} rotation={[0, 0.27, -Math.PI / 2]} scale={[0.5, 0.11, 0.83]} />
      
      <group position={[-4.09, 1.9, 1.66]} rotation={[0, 0.27, -Math.PI / 2]} scale={[0.5, 0.11, 0.83]}>
        <mesh geometry={nodes.Cube006.geometry} material={materials['Material.090']} >       
        {clickedAboutMe && (<Html padding="0px" className="content" rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[0, 0.05, -0.09]} transform >
          <Box className="wrapper" padding="0px">
            <Text className="texthead2">About</Text>
            <Text className="text">I'm a data scientist and analyst by training and a self-taught web developer and digital artist. I enjoy expanding my knowledge and skills in both creative and logical domains and I absolutely love combining them into developing products to solve problems.</Text>
            <Text className="text">If you wish to collaborate or to hire me, please feel free to reach out!</Text>

            <Text className="texthead2">Socials</Text>
            <div className="container">
                <Link href="https://github.com/samtwl"><FiGithub className="socials"/></Link>
                <Link href="https://sg.linkedin.com/in/samueltongwl"><FiLinkedin className="socials"/></Link>
                <Link href={"mailto:samuel050590@gmail.com"}><FiMail className="socials"/></Link>
            </div>
          </Box>          
        </Html>)}

        </mesh>
      </group>
      
      <mesh geometry={nodes.Cube007.geometry} material={materials['Material.090']} position={[-5.37, 1.95, 3.87]} rotation={[0, 0.27, -Math.PI / 2]} scale={[0.5, 0.11, 0.83]} />
      
      <group position={[-3.71, 1.89, 3.05]} rotation={[0, 0.27, -Math.PI / 2]} scale={[0.5, 0.11, 0.43]}>
        <mesh geometry={nodes.Cube008.geometry} material={materials['Material.090']}>
          {clickedAboutMe && (<Html padding="0px" className="content" rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[0, 0.05, -0.09]} transform >
            <Box className="wrapper" padding="0px">
              {/* <Text className="text">Back to campfire.</Text> */}
              <Text className="texthead">Skills</Text>
              <ul className="cloud" role="navigation" aria-label="Webdev word cloud">
              <li><a href="#" data-weight="4">Django</a></li>
              <li><a href="#" data-weight="1">Figma</a></li>
              <li><a href="#" data-weight="5">Web-Development</a></li>
              <li><a href="#" data-weight="5">Data Science</a></li>
              <li><a href="#" data-weight="1">API</a></li>
              <li><a href="#" data-weight="4">Flask</a></li>
              <li><a href="#" data-weight="1">Graphic Design</a></li>
              <li><a href="#" data-weight="2">Blender</a></li>
              <li><a href="#" data-weight="3">HTML</a></li>
              <li><a href="#" data-weight="1">REST</a></li>
              <li><a href="#" data-weight="3">CSS</a></li>
              <li><a href="#" data-weight="1">Scikit-learn</a></li>
              <li><a href="#" data-weight="3">Python</a></li>
              <li><a href="#" data-weight="1">Three.js</a></li>
              <li><a href="#" data-weight="3">JavaScript</a></li>
              <li><a href="#" data-weight="1">Keras</a></li>
              <li><a href="#" data-weight="4">React</a></li>
              <li><a href="#" data-weight="3">R</a></li>
              <li><a href="#" data-weight="1">D3.js</a></li>
              <li><a href="#" data-weight="5">3D Modeling</a></li>
              <li><a href="#" data-weight="2">TypeScript</a></li>
              <li><a href="#" data-weight="5">Data Visualization</a></li>
              <li><a href="#" data-weight="1">Pandas</a></li>
              <li><a href="#" data-weight="2">Deep Learning / Machine Learning</a></li>
              <li><a href="#" data-weight="3">Tableau</a></li>
            </ul>
            </Box>          
          </Html>)}
        </mesh>
      </group>

      <group position={[-3.38, 1.93, 4.23]} rotation={[0, 0.27, -Math.PI / 2]} scale={[0.71, 0.11, 0.34]}>
        <mesh geometry={nodes.Cube009.geometry} material={materials['Material.095']}>
          {clickedAboutMe && (<Html padding="0px" className="content" rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[0, 0.05, -0.09]} transform >
            <Box className="wrapper" padding="0px" onClick={() => { setClickedAboutMe(false); setShowAboutMe(false)}} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
              <Text className="texthead3">Back to campsite</Text>
              <div className="container2">
                <FiCornerDownLeft className="texthead3"/>
              </div>
            </Box>          
          </Html>)}
        </mesh>
      </group>

      <group position={[-3.11, 2.27, 5.28]} rotation={[0, 0.27, -Math.PI / 2]} scale={[0.47, 0.11, 0.43]}>
        <mesh geometry={nodes.Cube013_1.geometry} material={materials['Material.090']} />
        <lineSegments geometry={nodes.Cube013_2.geometry} material={materials['Material.090']} />
      </group>
      <group position={[-4.91, 2.31, 5.78]} rotation={[-Math.PI, -0.14, -Math.PI / 2]} scale={[-0.47, -0.11, -0.43]}>
        <mesh geometry={nodes.Cube014_1.geometry} material={materials['Material.090']} />
        <lineSegments geometry={nodes.Cube014_2.geometry} material={materials['Material.090']} />
      </group>
      <mesh geometry={nodes.Cube012.geometry} material={materials['Material.090']} position={[-4.03, 2.26, 5.29]} rotation={[3.13, 1.31, 1.15]} scale={[0.43, 0.11, 0.91]} />
      <mesh geometry={nodes.Cube013.geometry} material={materials['Material.094']} position={[-4.67, 0.98, 2.98]} rotation={[0, 0.26, 0]} scale={[1.05, 0.12, 2.27]} />
      
      <mesh geometry={nodes.Cube014.geometry} material={materials.Material} position={[-3.34, 1.23, 5.5]} rotation={[0.03, -1.3, -1.51]} scale={[-0.06, -0.11, -0.13]} />
      <mesh geometry={nodes.Cube015.geometry} material={materials['Material.067']} position={[-4.59, 1.23, 5.83]} rotation={[0.03, -1.3, -1.51]} scale={[-0.06, -0.11, -0.13]} />
      
      <mesh geometry={nodes.Cylinder021.geometry} material={materials['Material.102']} position={[-5.21, 0.73, 4.72]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.28, 0.09, 0.29]} />
      <mesh geometry={nodes.Cylinder007.geometry} material={materials['Material.100']} position={[-3.16, 0.73, 4.72]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.17, 0.1, 0.2]} />
      <mesh geometry={nodes.Cylinder008.geometry} material={materials['Material.102']} position={[-3.16, 0.73, 4.72]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.28, 0.09, 0.29]} />
      <mesh geometry={nodes.Cylinder009.geometry} material={materials['Material.100']} position={[-4.06, 0.73, 1.38]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.17, 0.1, 0.2]} />
      <mesh geometry={nodes.Cylinder022.geometry} material={materials['Material.102']} position={[-4.06, 0.73, 1.38]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.28, 0.09, 0.29]} />
      <mesh geometry={nodes.Cylinder023.geometry} material={materials['Material.100']} position={[-6.02, 0.73, 1.73]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.17, 0.1, 0.2]} />
      <mesh geometry={nodes.Cylinder024.geometry} material={materials['Material.102']} position={[-6.02, 0.73, 1.73]} rotation={[0, 0.26, -Math.PI / 2]} scale={[0.28, 0.09, 0.29]} />
      <mesh geometry={nodes.Cube016.geometry} material={materials['Material.068']} position={[-3.97, 1.23, 5.65]} rotation={[-0.01, -1.3, -1.56]} scale={[-0.06, -0.14, -0.49]} />
      <mesh geometry={nodes.Cube017.geometry} material={materials['Material.070']} position={[-3.14, 1.23, 5.44]} rotation={[0.03, -1.3, -1.51]} scale={[-0.06, -0.11, -0.07]} />
      <mesh geometry={nodes.Cube018.geometry} material={materials['Material.069']} position={[-3.14, 1.23, 5.44]} rotation={[0.03, -1.3, -1.51]} scale={[-0.06, -0.11, -0.07]} />
      <mesh geometry={nodes.Cube019.geometry} material={materials['Material.070']} position={[-4.78, 1.23, 5.88]} rotation={[0.03, -1.3, -1.51]} scale={[-0.06, -0.11, -0.07]} />
      <mesh geometry={nodes.Cube020.geometry} material={materials['Material.097']} position={[-4.59, 1.24, 3.25]} rotation={[0, 0.26, 0]} scale={[1.02, 0.04, 2.55]} />
      <mesh geometry={nodes.Icosphere.geometry} material={materials['Material.109']} position={[1.2, 0.36, 4.83]} rotation={[-1.52, -1.17, -0.1]} scale={0.29} />
      <mesh geometry={nodes.Cylinder010.geometry} material={materials['Material.124']} position={[0.77, 0.21, 4.76]} rotation={[0.56, -0.28, 0.28]} scale={0.06} />
      <mesh geometry={nodes.Cylinder011.geometry} material={materials['Material.118']} position={[0.96, 0.22, 5.36]} rotation={[-0.13, -0.63, 0.65]} scale={0.06} />
      <mesh geometry={nodes.Cylinder012.geometry} material={materials['Material.120']} position={[0.65, 0.25, 5.42]} rotation={[2.39, -1.28, 3.13]} scale={0.06} />
      <mesh geometry={nodes.Cylinder013.geometry} material={materials['Material.126']} position={[0.87, 0.39, 5.2]} rotation={[-1.02, -0.88, 0.01]} scale={0.03} />
      <mesh geometry={nodes.Cylinder014.geometry} material={materials['Material.123']} position={[0.42, 0.18, 4.88]} rotation={[-0.08, -0.88, 2.71]} scale={-0.06} />
      <mesh geometry={nodes.Cylinder015.geometry} material={materials['Material.122']} position={[0.45, 0.34, 4.93]} rotation={[1.02, -0.87, -2.69]} scale={-0.03} />
      <mesh geometry={nodes.Cylinder016.geometry} material={materials['Material.119']} position={[0.36, 0.18, 5.18]} rotation={[2.77, 0.37, -2.7]} scale={0.06} />
      <mesh geometry={nodes.Cylinder017.geometry} material={materials['Material.121']} position={[0.48, 0.41, 5.15]} rotation={[-2.77, 0.55, -3.05]} scale={0.03} />
      <mesh geometry={nodes.Cylinder018.geometry} material={materials['Material.125']} position={[1.02, 0.23, 4.87]} rotation={[0.56, -0.09, 0.62]} scale={0.06} />
      <mesh geometry={nodes.Icosphere001.geometry} material={materials['Material.110']} position={[1.22, 0.34, 5.5]} rotation={[0, 1.32, 0]} scale={[0.18, 0.08, 0.13]} />
      <mesh geometry={nodes.Icosphere002.geometry} material={materials['Material.108']} position={[1.31, 0.34, 5.16]} rotation={[-2.54, 0.48, -0.29]} scale={[0.14, 0.08, 0.12]} />
      <mesh geometry={nodes.Icosphere003.geometry} material={materials['Material.111']} position={[0.95, 0.36, 5.7]} rotation={[-2.74, -0.11, -1.69]} scale={[0.29, 0.33, 0.29]} />
      <mesh geometry={nodes.Icosphere004.geometry} material={materials['Material.112']} position={[0.6, 0.34, 5.75]} rotation={[-2.56, -0.4, 0.27]} scale={[0.15, 0.08, 0.14]} />
      <mesh geometry={nodes.Icosphere005.geometry} material={materials['Material.113']} position={[0.26, 0.34, 5.64]} rotation={[-Math.PI, 1.03, -Math.PI]} scale={[0.18, 0.08, 0.13]} />
      <mesh geometry={nodes.Icosphere006.geometry} material={materials['Material.114']} position={[0.04, 0.34, 5.31]} rotation={[-1.24, 1.01, -1.94]} scale={[0.14, 0.11, 0.14]} />
      <mesh geometry={nodes.Icosphere007.geometry} material={materials['Material.115']} position={[0.06, 0.34, 4.92]} rotation={[-Math.PI, 1.14, -Math.PI]} scale={[0.18, 0.08, 0.13]} />
      <mesh geometry={nodes.Icosphere008.geometry} material={materials['Material.107']} position={[1.05, 0.34, 4.52]} rotation={[-2.56, -0.4, 0.27]} scale={[0.15, 0.08, 0.14]} />
      <mesh geometry={nodes.Icosphere009.geometry} material={materials['Material.117']} position={[0.71, 0.34, 4.41]} rotation={[-2, -0.98, 1.09]} scale={[0.18, 0.1, 0.15]} />
      <mesh geometry={nodes.Icosphere010.geometry} material={materials['Material.116']} position={[0.09, 0.34, 4.58]} rotation={[-2.45, -0.65, 0.48]} scale={[0.14, 0.08, 0.12]} />
      <mesh geometry={nodes.Icosphere014.geometry} material={materials['Material.113']} position={[0.38, 0.34, 4.41]} rotation={[Math.PI, -0.31, Math.PI]} scale={[0.18, 0.08, 0.13]} />

      <mesh geometry={nodes.Icosphere015.geometry} material={materials['Material.074']} position={[0.66, 0.34, 5.12]} rotation={[0, 1.32, 0]} scale={[0.2, 0.09, 0.15]} onClick={() => setClickedFire(!clickedFire)} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}><MeshDistortMaterial speed={12.5} distort={1} color={[0.68, 0.366, 0.048]} roughness={0.9} />
      {/* {!clickedFire && (<Html className="content3" rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[0, 0.05, -0.09]} >
            <Box className="wrapper4" onClick={() => { setClickedFire(true)}} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
              <Text className="texthead10">Data Visualizations</Text>
            </Box>          
          </Html>
      )} */}
      {/* {!clickedProjects && !clickedFire && !clickedAboutMe &&(<Html rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[-3, 16, -10]} >
            <Box className="boxArticles" padding="0px" onClick={() => { setClickedFire(true)}} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
              <Text className="textheadArticles" padding="0px">Data Visualizations</Text>
            </Box>          
          </Html>
          )} */}
    </mesh>


      
      <mesh geometry={nodes.Cone.geometry} material={materials['Material.091']} position={[-8.02, 1.81, -6.24]} rotation={[0, Math.PI / 4, 0]} scale={[0.41, 0.94, 0.41]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder019.geometry} material={materials['Material.092']} position={[-8.02, 0.66, -6.24]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone003.geometry} material={materials['Material.091']} position={[-6.52, 2.01, -6.63]} rotation={[0, Math.PI / 4, 0]} scale={[0.55, 1.27, 0.55]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder025.geometry} material={materials['Material.092']} position={[-6.52, 0.45, -6.63]} scale={[0.15, 0.48, 0.15]} />
      
      <mesh geometry={nodes.Cone004.geometry} material={materials['Material.091']} position={[-7.34, 1.9, -7.29]} rotation={[0, 1.16, 0]} scale={[0.47, 1.09, 0.47]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder026.geometry} material={materials['Material.092']} position={[-7.34, 0.57, -7.29]} rotation={[0, 0.38, 0]} scale={[0.13, 0.41, 0.13]} />
      
      <mesh geometry={nodes.Cone005.geometry} material={materials['Material.091']} position={[-5.6, 1.34, -7.29]} rotation={[0, 0.36, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder027.geometry} material={materials['Material.092']} position={[-5.6, 0.48, -7.29]} rotation={[0, -0.42, 0]} scale={[0.08, 0.26, 0.08]} />
      
      <mesh geometry={nodes.Cone006.geometry} material={materials['Material.091']} position={[-4.42, 2.28, -7.63]} rotation={[0, 0.04, 0]} scale={[0.55, 1.45, 0.55]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder028.geometry} material={materials['Material.092']} position={[-4.42, 0.5, -7.63]} rotation={[0, -0.74, 0]} scale={[0.15, 0.54, 0.15]} />
      
      <mesh geometry={nodes.Cone007.geometry} material={materials['Material.091']} position={[-2.71, 2.51, -6.5]} rotation={[0, 0.31, 0]} scale={[0.55, 1.45, 0.55]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder029.geometry} material={materials['Material.092']} position={[-2.71, 0.73, -6.5]} rotation={[0, -0.47, 0]} scale={[0.15, 0.54, 0.15]} />
     
      <mesh geometry={nodes.Cone008.geometry} material={materials['Material.091']} position={[-0.48, 1.98, -6.5]} rotation={[0, 1.39, 0]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder030.geometry} material={materials['Material.092']} position={[-0.48, 0.63, -6.5]} rotation={[0, 0.6, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone009.geometry} material={materials['Material.091']} position={[-1.54, 2.08, -8.06]} rotation={[-Math.PI, 0.59, -Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder031.geometry} material={materials['Material.092']} position={[-1.54, 0.53, -8.06]} rotation={[-Math.PI, 1.37, -Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone010.geometry} material={materials['Material.091']} position={[2.66, 2.33, -6.36]} rotation={[-Math.PI, 1.23, -Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder032.geometry} material={materials['Material.092']} position={[2.66, 0.79, -6.36]} rotation={[0, 1.13, 0]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone011.geometry} material={materials['Material.091']} position={[1.94, 2.33, -8.27]} rotation={[-Math.PI, 0.22, -Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder033.geometry} material={materials['Material.092']} position={[1.94, 0.79, -8.27]} rotation={[-Math.PI, 1.01, -Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone012.geometry} material={materials['Material.091']} position={[4.68, 2.33, -7.15]} rotation={[Math.PI, -0.32, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder034.geometry} material={materials['Material.092']} position={[4.68, 0.79, -7.15]} rotation={[-Math.PI, 0.47, -Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone013.geometry} material={materials['Material.091']} position={[6.79, 2.33, -8.04]} rotation={[Math.PI, -0.79, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder035.geometry} material={materials['Material.092']} position={[6.79, 0.79, -8.04]} rotation={[Math.PI, 0, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone014.geometry} material={materials['Material.091']} position={[6.79, 2.33, -8.04]} rotation={[Math.PI, -0.79, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder036.geometry} material={materials['Material.092']} position={[6.79, 0.79, -8.04]} rotation={[Math.PI, 0, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone015.geometry} material={materials['Material.091']} position={[8.31, 2.33, -6.94]} rotation={[Math.PI, -0.34, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder037.geometry} material={materials['Material.092']} position={[8.31, 0.79, -6.94]} rotation={[-Math.PI, 0.45, -Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone016.geometry} material={materials['Material.091']} position={[7.69, 2.33, -5.18]} rotation={[Math.PI, -1.24, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder038.geometry} material={materials['Material.092']} position={[7.69, 0.79, -5.18]} rotation={[Math.PI, -0.46, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone017.geometry} material={materials['Material.091']} position={[8.41, 2.33, -3.01]} rotation={[Math.PI, -0.48, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder039.geometry} material={materials['Material.092']} position={[8.41, 0.79, -3.01]} rotation={[-Math.PI, 0.3, -Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone018.geometry} material={materials['Material.091']} position={[7.84, 2.33, -1.28]} rotation={[Math.PI, -1.06, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder040.geometry} material={materials['Material.092']} position={[7.84, 0.79, -1.28]} rotation={[Math.PI, -0.28, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone019.geometry} material={materials['Material.091']} position={[8.3, 2.33, 0.78]} rotation={[Math.PI, -0.23, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder041.geometry} material={materials['Material.092']} position={[8.3, 0.79, 0.78]} rotation={[-Math.PI, 0.56, -Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone020.geometry} material={materials['Material.091']} position={[7.57, 2.33, 2.2]} rotation={[Math.PI, -1.15, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder042.geometry} material={materials['Material.092']} position={[7.57, 0.79, 2.2]} rotation={[Math.PI, -0.36, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone021.geometry} material={materials['Material.091']} position={[8.58, 2.33, 3.8]} rotation={[Math.PI, -1.47, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder043.geometry} material={materials['Material.092']} position={[8.58, 0.79, 3.8]} rotation={[Math.PI, -0.68, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone022.geometry} material={materials['Material.091']} position={[8.58, 2.33, 5.9]} rotation={[Math.PI, -1.47, Math.PI]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder044.geometry} material={materials['Material.092']} position={[8.58, 0.79, 5.9]} rotation={[Math.PI, -0.68, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone023.geometry} material={materials['Material.091']} position={[7.73, 2.33, 7.48]} rotation={[0, -1.17, 0]} scale={[0.48, 1.26, 0.48]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder045.geometry} material={materials['Material.092']} position={[7.73, 0.79, 7.48]} rotation={[Math.PI, -1.19, Math.PI]} scale={[0.13, 0.47, 0.13]} />
     
      <mesh geometry={nodes.Cone024.geometry} material={materials['Material.091']} position={[0.87, 1.98, -6.5]} rotation={[-Math.PI, 1.05, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder046.geometry} material={materials['Material.092']} position={[0.87, 0.63, -6.5]} rotation={[0, 1.31, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone025.geometry} material={materials['Material.091']} position={[-8.04, 1.98, -8.01]} rotation={[-Math.PI, 1.05, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder047.geometry} material={materials['Material.092']} position={[-8.04, 0.63, -8.01]} rotation={[0, 1.31, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone026.geometry} material={materials['Material.091']} position={[-6.33, 1.98, -8.01]} rotation={[0, 1.56, 0]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder048.geometry} material={materials['Material.092']} position={[-6.33, 0.63, -8.01]} rotation={[0, 0.77, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone027.geometry} material={materials['Material.091']} position={[-4.73, 1.98, -6.65]} rotation={[-Math.PI, 1.18, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder049.geometry} material={materials['Material.092']} position={[-4.73, 0.63, -6.65]} rotation={[0, 1.18, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone028.geometry} material={materials['Material.091']} position={[-3.28, 1.98, -7.89]} rotation={[-Math.PI, 0.77, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder050.geometry} material={materials['Material.092']} position={[-3.28, 0.63, -7.89]} rotation={[-Math.PI, 1.55, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone029.geometry} material={materials['Material.091']} position={[0.24, 1.98, -7.89]} rotation={[-Math.PI, 1.1, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder051.geometry} material={materials['Material.092']} position={[0.24, 0.63, -7.89]} rotation={[0, 1.25, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone030.geometry} material={materials['Material.091']} position={[3.61, 1.98, -7.4]} rotation={[-Math.PI, 1.1, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder052.geometry} material={materials['Material.092']} position={[3.61, 0.63, -7.4]} rotation={[0, 1.25, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone031.geometry} material={materials['Material.091']} position={[6.38, 1.98, -6.61]} rotation={[-Math.PI, 1.1, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder053.geometry} material={materials['Material.092']} position={[6.38, 0.63, -6.61]} rotation={[0, 1.25, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone032.geometry} material={materials['Material.091']} position={[6.38, 1.98, -8.64]} rotation={[-Math.PI, 1.1, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder054.geometry} material={materials['Material.092']} position={[6.38, 0.63, -8.64]} rotation={[0, 1.25, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone033.geometry} material={materials['Material.091']} position={[7.64, 1.98, -7.35]} rotation={[-Math.PI, 0.45, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder055.geometry} material={materials['Material.092']} position={[7.64, 0.63, -7.35]} rotation={[-Math.PI, 1.24, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone034.geometry} material={materials['Material.091']} position={[8.44, 1.98, -6.06]} rotation={[-Math.PI, 0.85, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder056.geometry} material={materials['Material.092']} position={[8.44, 0.63, -6.06]} rotation={[0, 1.5, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone035.geometry} material={materials['Material.091']} position={[8.44, 1.98, -4.27]} rotation={[-Math.PI, 1.36, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder057.geometry} material={materials['Material.092']} position={[8.44, 0.63, -4.27]} rotation={[0, 1, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone036.geometry} material={materials['Material.091']} position={[8.44, 1.98, -2.03]} rotation={[-Math.PI, 0.61, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder058.geometry} material={materials['Material.092']} position={[8.44, 0.63, -2.03]} rotation={[-Math.PI, 1.4, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone037.geometry} material={materials['Material.091']} position={[8.44, 1.98, -0.22]} rotation={[-Math.PI, 1.08, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder059.geometry} material={materials['Material.092']} position={[8.44, 0.63, -0.22]} rotation={[0, 1.27, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone038.geometry} material={materials['Material.091']} position={[6.99, 1.98, 0.68]} rotation={[-Math.PI, 0.83, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder060.geometry} material={materials['Material.092']} position={[6.99, 0.63, 0.68]} rotation={[0, 1.52, 0]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone039.geometry} material={materials['Material.091']} position={[8.46, 1.98, 1.87]} rotation={[-Math.PI, 0.37, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder061.geometry} material={materials['Material.092']} position={[8.46, 0.63, 1.87]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone040.geometry} material={materials['Material.091']} position={[8.46, 1.98, 4.79]} rotation={[-Math.PI, 0.37, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder062.geometry} material={materials['Material.092']} position={[8.46, 0.63, 4.79]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone041.geometry} material={materials['Material.091']} position={[7.5, 1.98, 3.46]} rotation={[Math.PI, -0.32, Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder063.geometry} material={materials['Material.092']} position={[7.5, 0.63, 3.46]} rotation={[-Math.PI, 0.47, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone042.geometry} material={materials['Material.091']} position={[7.5, 1.98, 6.33]} rotation={[-Math.PI, 0.28, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder064.geometry} material={materials['Material.092']} position={[7.5, 0.63, 6.33]} rotation={[-Math.PI, 1.07, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone043.geometry} material={materials['Material.091']} position={[8.25, 1.98, 8.25]} rotation={[-Math.PI, 0.51, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder065.geometry} material={materials['Material.092']} position={[8.25, 0.63, 8.25]} rotation={[-Math.PI, 1.29, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone044.geometry} material={materials['Material.091']} position={[7, 1.98, 8.25]} rotation={[-Math.PI, 0.14, -Math.PI]} scale={[0.42, 1.1, 0.42]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder066.geometry} material={materials['Material.092']} position={[7, 0.63, 8.25]} rotation={[-Math.PI, 0.93, -Math.PI]} scale={[0.12, 0.41, 0.12]} />
     
      <mesh geometry={nodes.Cone045.geometry} material={materials['Material.091']} position={[-8.3, 1.34, -7.29]} rotation={[0, 0.36, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder067.geometry} material={materials['Material.092']} position={[-8.3, 0.48, -7.29]} rotation={[0, -0.42, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone046.geometry} material={materials['Material.091']} position={[-7.09, 1.34, -8.42]} rotation={[0, 1.25, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder068.geometry} material={materials['Material.092']} position={[-7.09, 0.48, -8.42]} rotation={[0, 0.47, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone047.geometry} material={materials['Material.091']} position={[-2.38, 1.34, -8.42]} rotation={[0, 1.25, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder069.geometry} material={materials['Material.092']} position={[-2.38, 0.48, -8.42]} rotation={[0, 0.47, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone048.geometry} material={materials['Material.091']} position={[2.98, 1.34, -8.42]} rotation={[0, 0.33, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder070.geometry} material={materials['Material.092']} position={[2.98, 0.48, -8.42]} rotation={[0, -0.46, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone049.geometry} material={materials['Material.091']} position={[5.1, 1.34, -8.42]} rotation={[0, 1.08, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder071.geometry} material={materials['Material.092']} position={[5.1, 0.48, -8.42]} rotation={[0, 0.3, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone050.geometry} material={materials['Material.091']} position={[5.68, 1.34, -7.09]} rotation={[0, 1.08, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder072.geometry} material={materials['Material.092']} position={[5.68, 0.48, -7.09]} rotation={[0, 0.3, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone051.geometry} material={materials['Material.091']} position={[6.67, 1.34, -5.85]} rotation={[0, 0.53, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder073.geometry} material={materials['Material.092']} position={[6.67, 0.48, -5.85]} rotation={[0, -0.26, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone052.geometry} material={materials['Material.091']} position={[7.57, 1.34, -3.79]} rotation={[0, 0.53, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder074.geometry} material={materials['Material.092']} position={[7.57, 0.48, -3.79]} rotation={[0, -0.26, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone053.geometry} material={materials['Material.091']} position={[7.57, 1.34, -2.35]} rotation={[0, 0.53, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder075.geometry} material={materials['Material.092']} position={[7.57, 0.48, -2.35]} rotation={[0, -0.26, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone054.geometry} material={materials['Material.091']} position={[7.75, 1.34, 0.27]} rotation={[0, 1.08, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder076.geometry} material={materials['Material.092']} position={[7.75, 0.48, 0.27]} rotation={[0, 0.3, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone055.geometry} material={materials['Material.091']} position={[7.75, 1.34, 0.27]} rotation={[0, 1.08, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder077.geometry} material={materials['Material.092']} position={[7.75, 0.48, 0.27]} rotation={[0, 0.3, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone056.geometry} material={materials['Material.091']} position={[8.32, 1.34, 2.82]} rotation={[0, 1.08, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder078.geometry} material={materials['Material.092']} position={[8.32, 0.48, 2.82]} rotation={[0, 0.3, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone057.geometry} material={materials['Material.091']} position={[7.62, 1.34, 5.2]} rotation={[0, 1.08, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder079.geometry} material={materials['Material.092']} position={[7.62, 0.48, 5.2]} rotation={[0, 0.3, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone058.geometry} material={materials['Material.091']} position={[6.95, 1.34, 7.09]} rotation={[0, 1.08, 0]} scale={[0.31, 0.7, 0.31]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder080.geometry} material={materials['Material.092']} position={[6.95, 0.48, 7.09]} rotation={[0, 0.3, 0]} scale={[0.08, 0.26, 0.08]} />
     
      <mesh geometry={nodes.Cone001.geometry} material={materials['Material.091']} position={[2.74, 3.17, -7.23]} rotation={[0, -1.17, 0]} scale={[0.75, 1.97, 0.75]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder020.geometry} material={materials['Material.092']} position={[2.74, 0.75, -7.23]} rotation={[Math.PI, -1.19, Math.PI]} scale={[0.21, 0.74, 0.21]} />
     
      <mesh geometry={nodes.Cone002.geometry} material={materials['Material.091']} position={[0.77, 3.17, -8.19]} rotation={[Math.PI, -1.39, Math.PI]} scale={[0.75, 1.97, 0.75]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder112.geometry} material={materials['Material.092']} position={[0.77, 0.75, -8.19]} rotation={[Math.PI, -0.61, Math.PI]} scale={[0.21, 0.74, 0.21]} />
     
      <mesh geometry={nodes.Cone121.geometry} material={materials['Material.091']} position={[-3.97, 2.92, -8.36]} rotation={[0, -1.18, 0]} scale={[0.75, 1.57, 0.75]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder113.geometry} material={materials['Material.092']} position={[-3.97, 0.75, -8.36]} rotation={[Math.PI, -0.61, Math.PI]} scale={[0.21, 0.74, 0.21]} />
     
      <mesh geometry={nodes.Cone122.geometry} material={materials['Material.091']} position={[-2.36, 2.94, -5.35]} rotation={[Math.PI, -1.39, Math.PI]} scale={[0.61, 1.6, 0.61]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder114.geometry} material={materials['Material.092']} position={[-2.36, 0.98, -5.35]} rotation={[Math.PI, -0.61, Math.PI]} scale={[0.17, 0.6, 0.17]} />
     
      <mesh geometry={nodes.Cone123.geometry} material={materials['Material.091']} position={[-8.64, 2.68, -8.23]} rotation={[Math.PI, -1.39, Math.PI]} scale={[0.75, 1.58, 0.75]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder115.geometry} material={materials['Material.092']} position={[-8.64, 0.75, -8.23]} rotation={[Math.PI, -0.61, Math.PI]} scale={[0.21, 0.74, 0.21]} />
     
      <mesh geometry={nodes.Cone124.geometry} material={materials['Material.091']} position={[5.63, 3.17, -8.23]} rotation={[Math.PI, -1.39, Math.PI]} scale={[0.75, 1.97, 0.75]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder116.geometry} material={materials['Material.092']} position={[5.63, 0.75, -8.23]} rotation={[Math.PI, -0.61, Math.PI]} scale={[0.21, 0.74, 0.21]} />
     
      <mesh geometry={nodes.Icosphere011.geometry} material={materials['Material.105']} position={[4.83, 0.34, -3.72]} rotation={[-Math.PI, 0.98, -Math.PI]} scale={[0.76, 0.36, 0.56]} />
      <mesh geometry={nodes.Icosphere012.geometry} material={materials['Material.104']} position={[5.86, 0.34, 5.32]} rotation={[Math.PI, -0.76, Math.PI]} scale={[0.77, 0.36, 0.57]} />
      <mesh geometry={nodes.Icosphere013.geometry} material={materials['Material.106']} position={[-0.03, 0.34, -3.44]} rotation={[-2.16, -0.92, 0.89]} scale={[1.02, 0.56, 0.96]} />
      <mesh geometry={nodes.Icosphere016.geometry} material={materials['Material.105']} position={[4.92, 0.34, 5.19]} rotation={[Math.PI, -0.77, Math.PI]} scale={[0.48, 0.23, 0.36]} />
      
      <mesh geometry={nodes.Cone059.geometry} material={materials['Material.127']} position={[-0.38, 1.72, -8.59]} rotation={[0, Math.PI / 4, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone060.geometry} material={materials['Material.129']} position={[-0.38, 2.09, -8.59]} rotation={[0, Math.PI / 4, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder081.geometry} material={materials['Material.128']} position={[-0.38, 0.61, -8.59]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone061.geometry} material={materials['Material.127']} position={[-5.35, 1.72, -8.16]} rotation={[0, 1.32, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone062.geometry} material={materials['Material.129']} position={[-5.35, 2.09, -8.16]} rotation={[0, 1.32, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder082.geometry} material={materials['Material.128']} position={[-5.35, 0.61, -8.16]} rotation={[0, 0.53, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone063.geometry} material={materials['Material.127']} position={[-7.16, 1.72, -5.88]} rotation={[0, 1.32, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone064.geometry} material={materials['Material.129']} position={[-7.16, 2.09, -5.88]} rotation={[0, 1.32, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder083.geometry} material={materials['Material.128']} position={[-7.16, 0.61, -5.88]} rotation={[0, 0.53, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone065.geometry} material={materials['Material.127']} position={[-3.67, 1.72, -6.37]} rotation={[-Math.PI, 1.28, -Math.PI]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone066.geometry} material={materials['Material.129']} position={[-3.67, 2.09, -6.37]} rotation={[-Math.PI, 1.28, -Math.PI]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder084.geometry} material={materials['Material.128']} position={[-3.67, 0.61, -6.37]} rotation={[0, 1.08, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone067.geometry} material={materials['Material.127']} position={[-1.46, 1.72, -6.37]} rotation={[0, 1.35, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone068.geometry} material={materials['Material.129']} position={[-1.46, 2.09, -6.37]} rotation={[0, 1.35, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder085.geometry} material={materials['Material.128']} position={[-1.46, 0.61, -6.37]} rotation={[0, 0.57, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone069.geometry} material={materials['Material.127']} position={[1.8, 1.72, -6.94]} rotation={[0, 0.96, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone070.geometry} material={materials['Material.129']} position={[1.8, 2.09, -6.94]} rotation={[0, 0.96, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder086.geometry} material={materials['Material.128']} position={[1.8, 0.61, -6.94]} rotation={[0, 0.18, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone071.geometry} material={materials['Material.127']} position={[3.95, 1.72, -6.65]} rotation={[0, 0.96, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone072.geometry} material={materials['Material.129']} position={[3.95, 2.09, -6.65]} rotation={[0, 0.96, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder087.geometry} material={materials['Material.128']} position={[3.95, 0.61, -6.65]} rotation={[0, 0.18, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone073.geometry} material={materials['Material.127']} position={[5.23, 1.72, -5.79]} rotation={[0, 0.52, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone074.geometry} material={materials['Material.129']} position={[5.23, 2.09, -5.79]} rotation={[0, 0.52, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder088.geometry} material={materials['Material.128']} position={[5.23, 0.61, -5.79]} rotation={[0, -0.27, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone075.geometry} material={materials['Material.127']} position={[6.81, 1.72, -0.87]} rotation={[0, 0.52, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone076.geometry} material={materials['Material.129']} position={[6.81, 2.09, -0.87]} rotation={[0, 0.52, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder089.geometry} material={materials['Material.128']} position={[6.81, 0.61, -0.87]} rotation={[0, -0.27, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone077.geometry} material={materials['Material.127']} position={[6.98, 1.72, -5.01]} rotation={[0, 0.52, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone078.geometry} material={materials['Material.129']} position={[6.98, 2.09, -5.01]} rotation={[0, 0.52, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder090.geometry} material={materials['Material.128']} position={[6.98, 0.61, -5.01]} rotation={[0, -0.27, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone079.geometry} material={materials['Material.127']} position={[6.98, 1.72, -2.64]} rotation={[0, 1.32, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone080.geometry} material={materials['Material.129']} position={[6.98, 2.09, -2.64]} rotation={[0, 1.32, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder091.geometry} material={materials['Material.128']} position={[6.98, 0.61, -2.64]} rotation={[0, 0.54, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone081.geometry} material={materials['Material.127']} position={[6.98, 1.72, 4.45]} rotation={[0, 1.32, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone082.geometry} material={materials['Material.129']} position={[6.98, 2.09, 4.45]} rotation={[0, 1.32, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder092.geometry} material={materials['Material.128']} position={[6.98, 0.61, 4.45]} rotation={[0, 0.54, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone083.geometry} material={materials['Material.127']} position={[6.49, 1.72, 6.44]} rotation={[0, 1.32, 0]} scale={[0.54, 0.79, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone084.geometry} material={materials['Material.129']} position={[6.49, 2.09, 6.44]} rotation={[0, 1.32, 0]} scale={[0.38, 0.55, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder093.geometry} material={materials['Material.128']} position={[6.49, 0.61, 6.44]} rotation={[0, 0.54, 0]} scale={[0.11, 0.35, 0.11]} />
      
      <mesh geometry={nodes.Cone085.geometry} material={materials['Material.127']} position={[-6.47, 2.57, -5.36]} rotation={[0, 1.32, 0]} scale={[0.54, 1.36, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone086.geometry} material={materials['Material.129']} position={[-6.47, 3.2, -5.36]} rotation={[0, 1.32, 0]} scale={[0.38, 0.95, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder094.geometry} material={materials['Material.128']} position={[-6.47, 0.64, -5.36]} rotation={[0, 0.53, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone087.geometry} material={materials['Material.127']} position={[-2.08, 2.57, -7.43]} rotation={[0, 1.32, 0]} scale={[0.54, 1.36, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone088.geometry} material={materials['Material.129']} position={[-2.08, 3.2, -7.43]} rotation={[0, 1.32, 0]} scale={[0.38, 0.95, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder095.geometry} material={materials['Material.128']} position={[-2.08, 0.64, -7.43]} rotation={[0, 0.53, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone089.geometry} material={materials['Material.127']} position={[1.15, 2.57, -7.43]} rotation={[0, 1.32, 0]} scale={[0.54, 1.36, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone090.geometry} material={materials['Material.129']} position={[1.15, 3.2, -7.43]} rotation={[0, 1.32, 0]} scale={[0.38, 0.95, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder096.geometry} material={materials['Material.128']} position={[1.15, 0.64, -7.43]} rotation={[0, 0.53, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone091.geometry} material={materials['Material.127']} position={[4.14, 2.57, -8.19]} rotation={[0, 0.37, 0]} scale={[0.54, 1.36, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone092.geometry} material={materials['Material.129']} position={[4.14, 3.2, -8.19]} rotation={[0, 0.37, 0]} scale={[0.38, 0.95, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder097.geometry} material={materials['Material.128']} position={[4.14, 0.64, -8.19]} rotation={[0, -0.41, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone093.geometry} material={materials['Material.127']} position={[6.84, 2.57, -3.95]} rotation={[0, 0.37, 0]} scale={[0.54, 1.36, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone094.geometry} material={materials['Material.129']} position={[6.84, 3.2, -3.95]} rotation={[0, 0.37, 0]} scale={[0.38, 0.95, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder098.geometry} material={materials['Material.128']} position={[6.84, 0.64, -3.95]} rotation={[0, -0.41, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone095.geometry} material={materials['Material.127']} position={[6.84, 2.57, 1.9]} rotation={[0, 0.37, 0]} scale={[0.54, 1.36, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone096.geometry} material={materials['Material.129']} position={[6.84, 3.2, 1.9]} rotation={[0, 0.37, 0]} scale={[0.38, 0.95, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder099.geometry} material={materials['Material.128']} position={[6.84, 0.64, 1.9]} rotation={[0, -0.41, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone097.geometry} material={materials['Material.127']} position={[6.48, 2.57, 3.04]} rotation={[0, 0.37, 0]} scale={[0.54, 1.36, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone098.geometry} material={materials['Material.129']} position={[6.48, 3.2, 3.04]} rotation={[0, 0.37, 0]} scale={[0.38, 0.95, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder100.geometry} material={materials['Material.128']} position={[6.48, 0.64, 3.04]} rotation={[0, -0.41, 0]} scale={[0.11, 0.61, 0.11]} />
      
      {/* <Clone
          object={nodes.Cone099.geometry}
          material={materials['Material.129']} position={[-8.33, 2.22, -5.19]} rotation={[0, 1.32, 0]} scale={[0.49, 0.36, 0.49]}
          castShadow
          receiveShadow
          inject={(object) =>
            object.name === 'Cone099.geometry' && <MeshDistortMaterial speed={2.5} distort={0.25} color={[0.086, 0.58, 0.12]} roughness={0.3} />
          }
      /> */}
      <mesh geometry={nodes.Cone099.geometry} material={materials['Material.127']} position={[-8.33, 1.61, -5.19]} rotation={[0, 1.32, 0]} scale={[0.77, 0.66, 0.77]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone100.geometry} material={materials['Material.129']} position={[-8.33, 2.22, -5.19]} rotation={[0, 1.32, 0]} scale={[0.49, 0.36, 0.49]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder101.geometry} material={materials['Material.128']} position={[-8.33, 0.64, -5.19]} rotation={[0, 0.53, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone101.geometry} material={materials['Material.127']} position={[-4.94, 1.61, -5.19]} rotation={[0, 0.44, 0]} scale={[0.77, 0.66, 0.77]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone102.geometry} material={materials['Material.129']} position={[-4.94, 2.22, -5.19]} rotation={[0, 0.44, 0]} scale={[0.49, 0.36, 0.49]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder102.geometry} material={materials['Material.128']} position={[-4.94, 0.64, -5.19]} rotation={[0, -0.34, 0]} scale={[0.11, 0.61, 0.11]} />

      <mesh geometry={nodes.Cone103.geometry} material={materials['Material.127']} position={[-0.84, 1.61, -7.38]} rotation={[0, 1.36, 0]} scale={[0.77, 0.66, 0.77]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone104.geometry} material={materials['Material.129']} position={[-0.84, 2.22, -7.38]} rotation={[0, 1.36, 0]} scale={[0.49, 0.36, 0.49]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder103.geometry} material={materials['Material.128']} position={[-0.84, 0.64, -7.38]} rotation={[0, 0.58, 0]} scale={[0.11, 0.61, 0.11]} />

      <mesh geometry={nodes.Cone105.geometry} material={materials['Material.127']} position={[7.59, 1.61, -6.38]} rotation={[0, 1.36, 0]} scale={[0.77, 0.66, 0.77]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone106.geometry} material={materials['Material.129']} position={[7.59, 2.22, -6.38]} rotation={[0, 1.36, 0]} scale={[0.49, 0.36, 0.49]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder104.geometry} material={materials['Material.128']} position={[7.59, 0.64, -6.38]} rotation={[0, 0.58, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone107.geometry} material={materials['Material.127']} position={[7.81, 1.79, -0.26]} rotation={[0, 0.37, 0]} scale={[0.54, 0.69, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone108.geometry} material={materials['Material.129']} position={[7.81, 2.51, -0.26]} rotation={[0, 0.37, 0]} scale={[0.38, 0.57, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder105.geometry} material={materials['Material.128']} position={[7.81, 0.64, -0.26]} rotation={[0, -0.41, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone109.geometry} material={materials['Material.127']} position={[6.44, 1.79, -1.94]} rotation={[0, -0.14, 0]} scale={[0.54, 0.69, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone110.geometry} material={materials['Material.129']} position={[6.44, 2.51, -1.94]} rotation={[0, -0.14, 0]} scale={[0.38, 0.57, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder106.geometry} material={materials['Material.128']} position={[6.44, 0.64, -1.94]} rotation={[0, -0.92, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone111.geometry} material={materials['Material.127']} position={[5.97, 1.79, -5.06]} rotation={[0, -0.57, 0]} scale={[0.54, 0.69, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone112.geometry} material={materials['Material.129']} position={[5.97, 2.51, -5.06]} rotation={[0, -0.57, 0]} scale={[0.38, 0.57, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder107.geometry} material={materials['Material.128']} position={[5.97, 0.64, -5.06]} rotation={[0, -1.35, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone113.geometry} material={materials['Material.127']} position={[0.14, 1.79, -6.03]} rotation={[0, -0.57, 0]} scale={[0.54, 0.69, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone114.geometry} material={materials['Material.129']} position={[0.14, 2.51, -6.03]} rotation={[0, -0.57, 0]} scale={[0.38, 0.57, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder108.geometry} material={materials['Material.128']} position={[0.14, 0.64, -6.03]} rotation={[0, -1.35, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone115.geometry} material={materials['Material.127']} position={[4.46, 1.79, -6.03]} rotation={[0, -0.57, 0]} scale={[0.54, 0.69, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone116.geometry} material={materials['Material.129']} position={[4.46, 2.51, -6.03]} rotation={[0, -0.57, 0]} scale={[0.38, 0.57, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder109.geometry} material={materials['Material.128']} position={[4.46, 0.64, -6.03]} rotation={[0, -1.35, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone117.geometry} material={materials['Material.127']} position={[4.46, 1.79, -6.03]} rotation={[0, -0.57, 0]} scale={[0.54, 0.69, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone118.geometry} material={materials['Material.129']} position={[4.46, 2.51, -6.03]} rotation={[0, -0.57, 0]} scale={[0.38, 0.57, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder110.geometry} material={materials['Material.128']} position={[4.46, 0.64, -6.03]} rotation={[0, -1.35, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone119.geometry} material={materials['Material.127']} position={[6.22, 1.79, 7.48]} rotation={[0, -0.57, 0]} scale={[0.54, 0.69, 0.54]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone120.geometry} material={materials['Material.129']} position={[6.22, 2.51, 7.48]} rotation={[0, -0.57, 0]} scale={[0.38, 0.57, 0.38]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder111.geometry} material={materials['Material.128']} position={[6.22, 0.64, 7.48]} rotation={[0, -1.35, 0]} scale={[0.11, 0.61, 0.11]} />
      
      <mesh geometry={nodes.Cone125.geometry} material={materials['Material.127']} position={[8.3, 3.29, -8.08]} rotation={[0, Math.PI / 4, 0]} scale={[1.15, 1.66, 1.15]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone126.geometry} material={materials['Material.129']} position={[8.3, 4.07, -8.08]} rotation={[0, Math.PI / 4, 0]} scale={[0.8, 1.16, 0.8]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder117.geometry} material={materials['Material.128']} position={[8.3, 0.94, -8.08]} scale={[0.24, 0.75, 0.24]} />
      
      <mesh geometry={nodes.Cone127.geometry} material={materials['Material.127']} position={[8.3, 3.29, -8.08]} rotation={[0, Math.PI / 4, 0]} scale={[1.15, 1.66, 1.15]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone128.geometry} material={materials['Material.129']} position={[8.3, 4.07, -8.08]} rotation={[0, Math.PI / 4, 0]} scale={[0.8, 1.16, 0.8]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder118.geometry} material={materials['Material.128']} position={[8.3, 0.94, -8.08]} scale={[0.24, 0.75, 0.24]} />
      
      <mesh geometry={nodes.Cone129.geometry} material={materials['Material.127']} position={[8.3, 3.29, -1.01]} rotation={[0, 0.38, 0]} scale={[1.15, 1.66, 1.15]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone130.geometry} material={materials['Material.129']} position={[8.3, 4.07, -1.01]} rotation={[0, 0.38, 0]} scale={[0.8, 1.16, 0.8]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder119.geometry} material={materials['Material.128']} position={[8.3, 0.94, -1.01]} rotation={[0, -0.4, 0]} scale={[0.24, 0.75, 0.24]} />
      
      <mesh geometry={nodes.Cone131.geometry} material={materials['Material.127']} position={[8.3, 3.29, 4.37]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[1.15, 1.66, 1.15]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone132.geometry} material={materials['Material.129']} position={[8.3, 4.07, 4.37]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[0.8, 1.16, 0.8]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder120.geometry} material={materials['Material.128']} position={[8.3, 0.94, 4.37]} rotation={[0, 1.19, 0]} scale={[0.24, 0.75, 0.24]} />
      
      <mesh geometry={nodes.Cone133.geometry} material={materials['Material.127']} position={[1.78, 3.29, -6.05]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[1.15, 1.66, 1.15]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone134.geometry} material={materials['Material.129']} position={[1.78, 4.07, -6.05]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[0.8, 1.16, 0.8]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder121.geometry} material={materials['Material.128']} position={[1.78, 0.94, -6.05]} rotation={[0, 1.19, 0]} scale={[0.24, 0.75, 0.24]} />
      
      <mesh geometry={nodes.Cone135.geometry} material={materials['Material.127']} position={[-6.73, 3.29, -8.33]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[1.15, 1.66, 1.15]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone136.geometry} material={materials['Material.129']} position={[-6.73, 4.07, -8.33]} rotation={[-Math.PI, 1.16, -Math.PI]} scale={[0.8, 1.16, 0.8]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder122.geometry} material={materials['Material.128']} position={[-6.73, 0.94, -8.33]} rotation={[0, 1.19, 0]} scale={[0.24, 0.75, 0.24]} />
      
      <mesh geometry={nodes.Cone137.geometry} material={materials['Material.127']} position={[8.79, 2.83, -3.65]} rotation={[0, 0.12, 0]} scale={[0.95, 1.38, 0.95]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cone138.geometry} material={materials['Material.129']} position={[8.79, 3.48, -3.65]} rotation={[0, 0.12, 0]} scale={[0.67, 0.96, 0.67]}><MeshDistortMaterial speed={2.5} distort={0.25} color={[0.007, 0.017, 0.005]} roughness={0.3} /></mesh>
      <mesh geometry={nodes.Cylinder123.geometry} material={materials['Material.128']} position={[8.79, 0.88, -3.65]} rotation={[0, -0.66, 0]} scale={[0.2, 0.62, 0.2]} />
    
      {/* <group> */}
      <group position={[1.51, 0.34, 3.65]} rotation={[0.02, 0.41, 0.06]} scale={[0.18, 0.26, 0.26]} >
        <mesh geometry={nodes.Plane070.geometry} material={nodes.Plane070.material}>
          {clickedFire && (<Html className="content" padding="0px" rotation-x={-Math.PI / 2} position={[0, 0.05, -0.09]} transform >
              <Box className="wrapper2" padding="0px">
              <Text className="texthead3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu turpis varius, elementum augue et, consequat erat. Duis tincidunt enim at fringilla tincidunt. Vestibulum nisl leo, elementum ut purus eget, fermentum mattis neque. </Text>
              </Box>          
            </Html>)}
        </mesh>
      </group>

      <group position={[2.09, 0.39, 3.99]} scale={[0.18, 0.26, 0.26]}>
        <mesh geometry={nodes.Plane071.geometry} material={nodes.Plane071.material}>
          {clickedFire && (<Html className="content" padding="0px" rotation-x={-Math.PI / 2}  position={[0, 0.05, -0.09]} transform >
          <Link href="https://www.samueltong.co/blog">
            <Box className="wrapper2" padding="0px">
              <Text className="texthead4">Link to Blog</Text>
              <div className="container2">
                <RiArticleFill className="texthead5"/>
              </div>                
            </Box>     
            </Link>     
          </Html>)}

          {!clickedProjects && !clickedFire && !clickedAboutMe &&(<Html rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[-4, 3, -1]} >
            <Box className="boxArticles" padding="0px" onClick={() => { setClickedFire(true)}} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
              <Text className="textheadArticles" padding="0px">Blog</Text>
            </Box>          
          </Html>
          )}
        </mesh>
      </group>

      <group position={[2.04, 0.39, 3.21]} rotation={[0, -0.43, 0]} scale={[0.18, 0.26, 0.26]}>
        <mesh geometry={nodes.Plane072.geometry} material={nodes.Plane072.material}>
        {clickedFire && (<Html className="content" padding="0px" rotation-x={-Math.PI / 2} position={[0, 0.05, -0.09]} transform >
              <Box className="wrapper2" padding="0px">
              <Text className="texthead3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu turpis varius, elementum augue et, consequat erat. Duis tincidunt enim at fringilla tincidunt. Vestibulum nisl leo, elementum ut purus eget, fermentum mattis neque. </Text>
              </Box>          
            </Html>)}
        </mesh>
      </group>
      
      <group position={[2.5, 0.35, 3.44]} rotation={[0, 0.16, 0]} scale={[0.18, 0.26, 0.26]}>
        <mesh geometry={nodes.Plane073.geometry} material={nodes.Plane073.material}>
        {clickedFire && (<Html className="content" padding="0px" rotation-x={-Math.PI / 2} position={[0, 0.05, -0.09]} transform >
              <Box className="wrapper2" padding="0px">
              <Text className="texthead3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu turpis varius, elementum augue et, consequat erat. Duis tincidunt enim at fringilla tincidunt. Vestibulum nisl leo, elementum ut purus eget, fermentum mattis neque. </Text>
              </Box>          
            </Html>)}
        </mesh>
      </group>

      <group position={[1.8, 0.43, 4.31]} rotation={[0, -0.2, 0]} scale={[0.18, 0.26, 0.26]}>
        <mesh geometry={nodes.Plane074.geometry} material={nodes.Plane074.material}>
        {clickedFire && (<Html className="content" padding="0px" rotation-x={-Math.PI / 2} position={[0, 0.05, -0.09]} transform >
              <Box className="wrapper2" padding="0px" onClick={() => { setClickedFire(false)}} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
              <Text className="texthead6">back to campsite</Text>
              <div className="container2">
                <FiCornerDownLeft className="texthead6"/>
              </div>
              </Box>          
            </Html>)}
        </mesh>
      </group>

      <group position={[2.53, 0.34, 4.22]} rotation={[0, 1.24, 0]} scale={[0.18, 0.26, 0.26]}>
        <mesh geometry={nodes.Plane075.geometry} material={nodes.Plane075.material}>
        {clickedFire && (<Html className="content" padding="0px" rotation-x={-Math.PI / 2} position={[0, 0.05, -0.09]} transform >
              <Box className="wrapper2" padding="0px">
              <Text className="texthead3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu turpis varius, elementum augue et, consequat erat. Duis tincidunt enim at fringilla tincidunt. Vestibulum nisl leo, elementum ut purus eget, fermentum mattis neque. </Text>
              </Box>          
            </Html>)}
            {/* {clickedFire && (<Html className="content" padding="0px" rotation-x={-Math.PI / 2}  position={[0, 0.05, -0.09]} transform >
          <Link as={ReachLink} to="/blog">
            <Box className="wrapper2" padding="0px">
              <Text className="texthead4">Blog</Text>
              <div className="container2">
                <RiBarChartBoxFill className="texthead5"/>
              </div>                
            </Box>     
            </Link>     
          </Html>)} */}
        </mesh>
      </group>

      {/* <mesh geometry={nodes.Text.geometry} material={materials['Material.077']} position={[1.45, 0.44, 3.74]} rotation={[0.02, 0.38, -0.22]} scale={0.09} /> */}
      {/* {clickedAboutMe && (
        <> */}
          {/* <group position={[0.51, 0.45, 3.00]} rotation={[-Math.PI, 1.2, -Math.PI]} scale={[-0.24, -0.02, -0.32]}>
            <mesh geometry={nodes.Cube021.geometry} material={materials['Material.076']}>
              {clickedFire && (<Html className="content" rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[0, 0.05, -0.09]} transform >
                <Box className="wrapper2">
                  <RiMediumLine />
                </Box>          
              </Html>)}
            </mesh>
          </group>
          <mesh geometry={nodes.Cube022.geometry} material={materials['Material.075']} position={[0.51, 0.43, 3.00]} rotation={[-Math.PI, 1.2, -Math.PI]} scale={[-0.26, -0.01, -0.35]}/> */}
        {/* </>
      )} */}
      {/* <mesh geometry={nodes.Text001.geometry} material={materials['Material.077']} position={[2.53, 0.4, 3.4]} rotation={[0.7, 0.13, -0.22]} scale={0.09} /> */}
      {/* <mesh geometry={nodes.Text002.geometry} material={materials['Material.077']} position={[0.49, 0.33, 3]} rotation={[0.06, 0.71, 0.48]} scale={0.08} /> */}
      <mesh geometry={nodes.Text003.geometry} material={materials['Material.098']} position={[-7.98, 0.59, 7.09]} />
      <mesh geometry={nodes.Text004.geometry} material={materials['Material.098']} position={[-7.98, 0.59, 7.64]} />
      {/* <mesh geometry={nodes.Text005.geometry} material={materials['Material.077']} position={[2.1, 0.42, 3.9]} rotation={[0.02, 0.13, -0.22]} scale={0.09} /> */}
     

     {/* Link */}
      {/* <mesh geometry={nodes.Text006.geometry} material={materials['Material.098']} position={[-3.92, 0.66, 7.74]} />
      <mesh geometry={nodes.Text007.geometry} material={materials['Material.098']} position={[-3.92, 0.66, 8.15]} />
      <mesh geometry={nodes.Text008.geometry} material={materials['Material.098']} position={[-3.92, 0.66, 8.56]}/> */}
      {/* <mesh geometry={nodes.Text009.geometry} material={materials['Material.098']} position={[-3.98, 0.59, 7.1]} /> */}
      {/* <mesh geometry={nodes.Plane064.geometry} material={materials['Material.101']} position={[-3.22, 0.66, 8.45]} scale={[0.7, 1, 0.15]} onClick={() => setClickedAboutMe(true)} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}/>
      <mesh geometry={nodes.Plane065.geometry} material={materials['Material.101']} position={[-3.22, 0.66, 8.07]} scale={[0.7, 1, 0.15]} onClick={() => setClickedProjects(true)} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}/>
      <mesh geometry={nodes.Plane066.geometry} material={materials['Material.101']} position={[-3.22, 0.66, 7.67]} scale={[0.7, 1, 0.15]} onClick={() => setClickedFire(true)} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}/> */}


      {/* Guitar */}
      <group position={[0.07, 0.82, 2.24]} rotation={[-0.73, -0.01, 1.56]} scale={0.12}>
        <mesh geometry={nodes.Cube_0.geometry} material={materials['Material.131']} />
        <mesh geometry={nodes.Cube_0001_1.geometry} material={materials['Material.130']} />
        <lineSegments geometry={nodes.Cube_0001_2.geometry} material={materials['Material.130']} />
      </group>
      <mesh geometry={nodes.Cylinder124.geometry} material={materials['Material.132']} position={[0.04, 0.93, 2.24]} rotation={[0, 1.56, -0.73]} scale={[0, 0.46, 0]} />
      <mesh geometry={nodes.Cylinder125.geometry} material={materials['Material.132']} position={[0.05, 0.93, 2.24]} rotation={[0, 1.56, -0.73]} scale={[0, 0.46, 0]} />
      <mesh geometry={nodes.Cylinder126.geometry} material={materials['Material.132']} position={[0.07, 0.93, 2.24]} rotation={[0, 1.56, -0.73]} scale={[0, 0.46, 0]} />
      <mesh geometry={nodes.Cylinder127.geometry} material={materials['Material.132']} position={[0.08, 0.93, 2.24]} rotation={[0, 1.56, -0.73]} scale={[0, 0.46, 0]} />
      <mesh geometry={nodes.Cylinder128.geometry} material={materials['Material.132']} position={[0.09, 0.93, 2.24]} rotation={[0, 1.56, -0.73]} scale={[0, 0.46, 0]} />
      <mesh geometry={nodes.Cylinder129.geometry} material={materials['Material.132']} position={[0.1, 0.93, 2.24]} rotation={[0, 1.56, -0.73]} scale={[0, 0.46, 0]} />
      <mesh geometry={nodes.Cube024.geometry} material={materials['Material.133']} position={[0.07, 1.24, 1.93]} rotation={[0, 1.56, 0.84]} scale={[0.01, 0.01, 0.08]} />
      <mesh geometry={nodes.Cube025.geometry} material={materials['Material.133']} position={[0.07, 1.21, 1.95]} rotation={[0, 1.56, 0.84]} scale={[0.01, 0.01, 0.08]} />
      <mesh geometry={nodes.Cube026.geometry} material={materials['Material.133']} position={[0.07, 1.26, 1.9]} rotation={[0, 1.56, 0.84]} scale={[0.01, 0.01, 0.08]} />

      <mesh geometry={nodes.Cube027.geometry} material={materials['Material.134']} position={[5.63, 0.6, 0.8]} rotation={[0, 1.51, 0]} scale={[1.24, 0.04, 0.22]} />
      
      <group position={[4.78, 1.08, 0.74]} scale={[0.11, 0.02, 0.11]}>
        <mesh geometry={nodes.Cylinder133.geometry} material={materials['Material.136']} />
        <mesh geometry={nodes.Cylinder133_1.geometry} material={materials['Material.137']} />
        <mesh geometry={nodes.Cylinder133_2.geometry} material={materials['Material.138']} />
      </group>

      <group position={[-3.83, 0.65, -2.13]} rotation={[Math.PI / 2, 1.48, -Math.PI / 2]} scale={[0.26, 0.09, 0.26]}>
        <mesh geometry={nodes.Cube045.geometry} material={materials['Material.142']} />
        <mesh geometry={nodes.Cube045_1.geometry} material={materials['Material.139']} />
        <mesh geometry={nodes.Cube045_2.geometry} material={materials['Material.143']} />
        <mesh geometry={nodes.Cube045_3.geometry} material={materials['Material.074']} />
      </group>

      <mesh geometry={nodes.picnicmat.geometry} material={materials['Material.144']} position={[-3.1, 0.47, -2.18]} scale={-1.65} />

      {/* <mesh geometry={nodes.Cylinder130.geometry} material={materials['Material.139']} position={[-7.49, 2.45, -0.38]} scale={[0.04, 1.55, 0.04]} />
      <mesh geometry={nodes.Cylinder131.geometry} material={materials['Material.139']} position={[-7.65, 0.69, -0.58]} rotation={[0.46, -0.25, -0.46]} scale={[0.04, 0.38, 0.04]} />
      <mesh geometry={nodes.Cylinder133.geometry} material={materials['Material.139']} position={[-7.57, 0.69, -0.14]} rotation={[2.03, 1.07, -2.68]} scale={[0.04, 0.38, 0.04]} />
      <mesh geometry={nodes.Cylinder134.geometry} material={materials['Material.139']} position={[-7.24, 0.69, -0.42]} rotation={[2.55, -0.68, 2.16]} scale={[0.04, 0.38, 0.04]} />
      <mesh geometry={nodes.Cylinder135.geometry} material={materials['Material.139']} position={[-7.49, 2.45, -4.03]} scale={[0.04, 1.55, 0.04]} />
      <mesh geometry={nodes.Cylinder136.geometry} material={materials['Material.139']} position={[-7.65, 0.69, -4.22]} rotation={[0.46, -0.25, -0.46]} scale={[0.04, 0.38, 0.04]} />
      <mesh geometry={nodes.Cylinder137.geometry} material={materials['Material.139']} position={[-7.57, 0.69, -3.78]} rotation={[2.03, 1.07, -2.68]} scale={[0.04, 0.38, 0.04]} />
      <mesh geometry={nodes.Cylinder138.geometry} material={materials['Material.139']} position={[-7.24, 0.69, -4.06]} rotation={[2.55, -0.68, 2.16]} scale={[0.04, 0.38, 0.04]} />
      <mesh geometry={nodes.Cylinder139.geometry} material={materials['Material.141']} position={[-7.49, 3.99, -2.19]} rotation={[Math.PI / 2, 0, 0]} scale={[0.17, 1.83, 0.17]} />
      <mesh geometry={nodes.Plane067.geometry} material={materials['Material.141']} position={[-7.37, 2.67, -2.2]} rotation={[0, 0, -Math.PI / 2]} scale={[1.19, 1.19, 1.82]} />
      <mesh geometry={nodes.Cylinder140.geometry} material={materials['Material.139']} position={[-7.37, 1.38, -2.3]} scale={[0.01, 0.13, 0.01]} />
      <mesh geometry={nodes.Cylinder141.geometry} material={materials['Material.139']} position={[-7.37, 1.17, -2.39]} rotation={[Math.PI / 4, 0, 0]} scale={[0.01, 0.13, 0.01]} />
      <mesh geometry={nodes.Cylinder142.geometry} material={materials['Material.139']} position={[-7.37, 1.17, -2.21]} rotation={[-Math.PI / 4, 0, -Math.PI]} scale={[-0.01, -0.13, -0.01]} />
      <mesh geometry={nodes.Cylinder143.geometry} material={materials['Material.139']} position={[-7.37, 1.08, -2.3]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[-0.01, -0.18, -0.01]} /> */}

      <mesh geometry={nodes.Cylinder130.geometry} material={materials['Material.139']} position={[-7.48, 1.82, -0.7]} scale={[0.03, 1.28, 0.03]} />
      <mesh geometry={nodes.Cylinder131.geometry} material={materials['Material.139']} position={[-7.61, 0.37, -0.87]} rotation={[0.46, -0.25, -0.46]} scale={[0.03, 0.31, 0.03]} />
      <mesh geometry={nodes.Cylinder133.geometry} material={materials['Material.139']} position={[-7.55, 0.37, -0.51]} rotation={[2.03, 1.07, -2.68]} scale={[0.03, 0.31, 0.03]} />
      <mesh geometry={nodes.Cylinder134.geometry} material={materials['Material.139']} position={[-7.28, 0.37, -0.73]} rotation={[2.55, -0.68, 2.16]} scale={[0.03, 0.31, 0.03]} />
      <mesh geometry={nodes.Cylinder135.geometry} material={materials['Material.139']} position={[-7.48, 1.82, -3.71]} scale={[0.03, 1.28, 0.03]} />
      <mesh geometry={nodes.Cylinder136.geometry} material={materials['Material.139']} position={[-7.61, 0.37, -3.87]} rotation={[0.46, -0.25, -0.46]} scale={[0.03, 0.31, 0.03]} />
      <mesh geometry={nodes.Cylinder137.geometry} material={materials['Material.139']} position={[-7.55, 0.37, -3.51]} rotation={[2.03, 1.07, -2.68]} scale={[0.03, 0.31, 0.03]} />
      <mesh geometry={nodes.Cylinder138.geometry} material={materials['Material.139']} position={[-7.28, 0.37, -3.74]} rotation={[2.55, -0.68, 2.16]} scale={[0.03, 0.31, 0.03]} />
      <mesh geometry={nodes.Cylinder139.geometry} material={materials['Material.141']} position={[-7.48, 3.09, -2.2]} rotation={[Math.PI / 2, 0, 0]} scale={[0.14, 1.51, 0.14]} />
      
      <group position={[-7.38, 2, -2.2]} rotation={[0, 0, -Math.PI / 2]} scale={[0.98, 0.98, 1.5]}>
        <mesh geometry={nodes.Plane067.geometry} material={materials['Material.141']} >
          {clickedProjects && (<Html className="content2" padding="0px" rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} transform>
            <Box className="wrapper3" padding="0px">
              <Text className="texthead7">PROJECTS</Text>
              <Link href="https://www.reservative.co/"><Text className="texthead8">reservative.co</Text></Link>
              <Text className="text2">An all-in-one platform for your health and wellness businesses to grow and maximize profitability</Text>
              <Link href="https://www.floralfix.co/"><Text className="texthead8">floralfix.co</Text></Link>
              <Text className="text2">FloralFix helps you discover the best flowers for your space and delivers them periodically straight to your door, so that you can constantly spruce your space up with a fresh new look.</Text>
              <Text className="texthead9">back to campsite</Text>
              <div className="container2" onClick={() => { setClickedProjects(false); setHovered(false) }} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
                <FiCornerDownLeft className="texthead9"/>
              </div>  
            </Box>  
          </Html>)}

          {!clickedProjects && !clickedFire && !clickedAboutMe && (<Html rotation-x={-Math.PI / 2} rotation-z={Math.PI / 2} position={[-0.5, -1, 0]} >
            <Box className="boxProjects" onClick={() => { setClickedProjects(true)}} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
              <Text className="textheadProjects">Projects</Text>
            </Box>          
          </Html>
          )}
        </mesh>
      </group>

      <mesh geometry={nodes.Cylinder140.geometry} material={materials['Material.139']} position={[-7.38, 0.93, -2.29]} scale={[0.01, 0.1, 0.01]} />
      <mesh geometry={nodes.Cylinder141.geometry} material={materials['Material.139']} position={[-7.38, 0.75, -2.36]} rotation={[Math.PI / 4, 0, 0]} scale={[0.01, 0.1, 0.01]} />
      <mesh geometry={nodes.Cylinder142.geometry} material={materials['Material.139']} position={[-7.38, 0.75, -2.22]} rotation={[-Math.PI / 4, 0, -Math.PI]} scale={[-0.01, -0.1, -0.01]} />
      <mesh geometry={nodes.Cylinder143.geometry} material={materials['Material.139']} position={[-7.38, 0.68, -2.29]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[-0.01, -0.14, -0.01]} />
    

    </group>
  )
}

useGLTF.preload('/camping4.glb')

import React, { Component } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "../hook/useWindowDimensions";
import { useMediaQuery } from "react-responsive";

const HeatMap = () => {
    const is2016x863 = useMediaQuery({
        minWidth: 2015.1,
      });
      const is1112x767 = useMediaQuery({
        maxWidth: 2015,
        minWidth: 1111,
      });
    
      const is1024x768 = useMediaQuery({
        maxWidth: 1111,
        minWidth: 1023,
      });
    
      const is800x600 = useMediaQuery({
        maxWidth: 1023,
        minWidth: 799,
      });
    
      const isIphone8Hor = useMediaQuery({
        maxWidth: 799,
        minWidth: 735,
      });
    
      const isIphone8SmallHor = useMediaQuery({
        maxWidth: 735,
        minWidth: 666,
      });
    
      const isIphoneSEHor = useMediaQuery({
        maxWidth: 666,
        minWidth: 567,
      });
    
      const isIphone8Vert = useMediaQuery({
        maxWidth: 567,
        minWidth: 413,
      });
    
      const isIphone8SmallVert = useMediaQuery({
        maxWidth: 413,
        minWidth: 374,
      });
    
      const isIphoneSEVert = useMediaQuery({
        maxWidth: 374,
        minWidth: 319,
      });

    const state = {
        options: {
            chart: {
                id: "basic-bar"
            },
            dataLabels: {
                enabled: false
            },
            colors: ["#008FFB"],
            xaxis: {
                categories: [
                    "w1", "w2", "w3", "w4", "w5", "w6", "w7", "w8","w9","w10","w11","w12","w13","w14","w15","w16","w17","w18","w19","w20","w21","w22","w23","w24","w25","w26","w27","w28",
                    "w29", "w30", "w31", "w32", "w33", "w34", "w35", "w36","w37","w38","w39","w40","w41","w42","w43","w44","w45","w46","w47","w48","w49","w50","w51","w52"    
                    ]
            }
            },
            series: [
            {
                name: "Sunday",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10,0,0, 0, 0, 0, 0, 0, 0, 0,0,0,0,0,0,0,0,99,0, 0, 0, 0, 0, 0, 0, 0,0,0,0,0,0, 0, 0, 0, 10, 0, 0, 0,0,0,0,0]
            },
            {
                name: "Saturday",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,0,0, 0, 0, 12, 0, 0, 0, 0,0,0,14,0,0,0,0,0,0, 0, 0, 0, 0, 0, 0, 0,0,0,0,0,20, 0, 0, 0, 0, 0, 0, 10,0,0,0,0]
            },
            {
                name: "Friday",
                data: [30, 60, 70, 50, 49, 60, 70, 89,86,82,45, 50, 49, 60, 70, 50, 49, 60, 70, 60, 70,89,53,77,82,89,70, 50, 49, 40, 45, 50, 49, 60, 70, 91,54,89,23,27,30, 40, 45, 50, 49, 60, 70, 91,52,89,23,10]
            },
            {
                name: "Thursday",
                data: [60, 70, 45, 50, 49, 60, 70, 95,80,89,45, 50, 49, 60, 70, 50, 49, 60, 70, 91,60, 70,83,57,72,89,70, 50, 49, 40, 45, 50, 49, 60, 70, 91,67,89,40, 45,30, 40, 45, 50, 49, 60, 70, 91,82,89,23,12]
            },
            {
                name: "Wednesday",
                data: [30, 40, 45, 60, 70, 60, 70, 90,86,93,45, 50, 60, 70, 70, 50, 49, 60, 70, 91,60, 70,53,47,62,89,70, 50, 49, 40, 45, 50, 49, 60, 70, 91,48,89,63,40, 45, 40, 45, 50, 49, 60, 70, 91,12,89,23,9]
            },
            {
                name: "Tuesday",
                data: [30, 40, 60, 70,80, 60, 70, 86,89,88,45, 50, 49, 60, 70, 60, 70, 60, 70, 91,67,89,50, 49,76,89,70, 50, 49, 40, 45, 50, 49, 60, 70, 91,69,89,40, 45,30, 40, 45, 50, 49, 60, 70, 91,42,89,23,15]
            },
            {
                name: "Monday",
                data: [30, 40, 45, 50, 49, 60, 70, 91,87,89,45, 50, 49, 60, 70, 50, 49, 60, 70, 91,87,89,67,50, 49,89,70, 50, 49, 40, 45, 50, 49, 60, 70, 91,71,89,23,27,30, 40, 45, 50, 49, 60, 70, 91,72,89,23,20]
            }
            ]
    };

    const { height, width } = useWindowDimensions();

    
    return (
        <>
        {is2016x863 && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="heatmap"
                width={width*0.75}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}
      {is1112x767 && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="heatmap"
                width={width*0.75}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}
            {is800x600 && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="heatmap"
                width={width*0.75}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}

      {isIphone8Hor && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="heatmap"
                width={width*0.6}
                height={height*0.5}            
            />
          </div>
        </div>
      </div>
      )}

{isIphone8SmallHor && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="heatmap"
                width={width*0.6}
                height={height*0.5}            
            />
          </div>
        </div>
      </div>
      )}

{isIphoneSEHor && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="heatmap"
                width={width*0.6}
                height={height*0.5}            
            />
          </div>
        </div>
      </div>
      )}

{(isIphone8Vert || isIphone8SmallVert || isIphoneSEVert) && (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
                options={state.options}
                series={state.series}
                type="heatmap"
                width={width*0.4}
                height={height*0.3}            
            />
          </div>
        </div>
      </div>
      )}
      </>
    );
  }


export default HeatMap;